<template>
	<div class="root">
		<van-nav-bar fixed :style="{ top: openAppHeight }" :title="$t('About_Us')"  @click-left="$router.go(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<h3>RABBIT SELECTION</h3>
		<div>
			Rabbit Selection is a lifestyle gadget retailer offering customers well-designed products, good quality at reasonable prices. Our mission is to
			select and offer smart gadgets to support and enhance every customers lifestyle.
		</div>
		<h3>WE SELECT FOR YOU</h3>
		<div>
			Strictly selected from leading manufacturers, we would like to ensure high quality of products together with cutting-edge technology just to make
			sure that consumers can feel that having “Good life is not expensive”.
		</div>
		<h3>SHOP &amp; EXPERIENCE ANYWHERE</h3>
		<div>
			We set out to make online and offline shopping experience seamlessly with great understanding of people’s lifestyle and enablement of digital
			technology that is providing the great experience ever together with a variety of product selection allowing you to purchase at your fingertips or
			simply browse for new ideas. Now you can visit Rabbit Selection whenever and wherever you want.
		</div>
		<h3>EXCLUSIVE OFFER FOR YOU</h3>
		<div>
			Receive an update on our new arrivals curated for you. Download our app and get priority access to an exclusive offer.
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.root {
		padding: 15px;
		padding-top: 41px;
	}
	div {
		max-width: 100%;
		margin: 0;
		word-break: normal;
		word-wrap: break-word;
		/* white-space: div-wrap; */
		line-height: 1.5;
	}
</style>
