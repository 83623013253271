<template>
	<div :style="{ paddingTop: openAppHeight }">
		<topbar />
		<!-- <div class="openAppBox" v-if="topShow && TotalShow">
      <div class="openAppBox_content">
          <img class="openAppBox_logo" src="../../../static/images/Rabbit_logo.png">
          
          <a class="openAppBox_btn" id="open" @click="gotoShopping()">{{openAppTitle}}</a>
          <img class="openAppBox_close" src="../../../static/images/del.png" @click="close()">
      </div>
    </div>  -->
		<!-- 新的OpenApp框 -->
		<div class="openAppBox" v-if="topShow" style="">
			<div class="openAppBox_content" style="height:initial;background-color:white !important;">
				<img class="van-action-download-app" style="width:100%;" src="../../../static/images/openAppBar.png" ref="openAppImg" />
				<!-- 点击区域,如果要整体点击效果可以删去 -->
				<div style="position:absolute;top:33.93%;left:75.2%;width:20.83%;height:47.2%;" @click="gotoShopping()"></div>
			</div>
		</div>
		<div class="bar-img">
			<van-loading type="spinner" v-if="loadingType" />
			<van-swipe :autoplay="3000" indicator-color="#f78e1e" style="border-radius:8px;" v-else>
				<van-swipe-item v-for="(image, index) in indexObj.carousels" :key="index">
					<img class="block" @click="jumpHandle(image.jump)" :src="image.image" />
				</van-swipe-item>
			</van-swipe>
		</div>

		<div class="tags" :style="tagSlider.tagsStatic" ref="tags">
			<div class="tags-inner" :style="tagSlider.tagsInnerStatic" ref="tagsInner">
				<img :src="item.pictureUrl" @click="jumpHandle(item.jump)" v-for="(item, index) in indexObj.tags" :key="index" />
			</div>
		</div>
		<div class="tags-sroll-rail" v-if="tagSlider.isMobile && tagSlider.showSlider">
			<div class="rail" :style="{ width: tagSlider.railLenth_VW + 'vw' }">
				<div class="slider" :style="{ width: tagSlider.sliderLenth_PX + 'px', marginLeft: tagSlider.sliderOffset_PX + 'px' }"></div>
			</div>
		</div>

		<div class="idx_data">
			<!-- <van-list
				v-model="loading"
				:finished="finished"
				:finished-text="$t('home.finishedText')"
				:loading-text="$t('home.loadingText')"
				:immediate-check="false"
				@load="onLoad"
			> -->
			<van-list v-model="loading" :finished="finished" :loading-text="$t('home.loadingText')" :immediate-check="false" @load="onLoad">
				<van-row class="allType">
					<!-- SQUARE  -->
					<template v-for="item in indexObj.data">
						<van-col class="px1" span="24" v-if="item.ui && item.ui.uiType == 'SQUARE'" :key="item.id">
							<div style="margin-bottom:10px;" @click="jumpHandle(item.jump)">
								<van-image width="100%" radius="5" :src="item.ui.param4.bigPictureUrl" />
							</div>
						</van-col>

						<!-- PRODUCT -->
						<van-col span="12" v-else-if="item.ui && item.ui.uiType == 'PRODUCT'" :key="item.id">
							<product-ui-half :product="item"></product-ui-half>
						</van-col>

						<!-- FLASH_SALE_HALF_RECTANGLE -->
						<van-col span="12" v-else-if="item.ui && item.ui.uiType == 'FLASH_SALE_HALF_RECTANGLE'" :key="item.id">
							<img @click="jumpHandle(item.jump)" :src="item.ui.param3.basePictureUrl" style="width:100%;height:100%;border-radius:6px;" />
						</van-col>

						<!-- FLASH_SALE_RECTANGLE -->
						<van-col class="my2 px1" span="24" v-else-if="item.ui && item.ui.uiType == 'FLASH_SALE_RECTANGLE'" :key="item.id">
							<flash-wrapper
								:meta="item"
								:img="item.ui.activity.backgroundPictureUrl"
								:textColor="item.ui.activity.countdownColor.textColor"
								:textBk="item.ui.activity.countdownColor.backgroundColor"
								:splitColor="item.ui.activity.countdownColor.frameColor"
								more
							>
								<horz-scroll-wrapper>
									<template #default="{wrapper}">
										<product-ui-flash class="f-noshrink" :products="item.jump.productItemList" :wrapper="wrapper"></product-ui-flash>
									</template>
								</horz-scroll-wrapper>
							</flash-wrapper>
						</van-col>
					</template>
				</van-row>
			</van-list>
		</div>

		<div class="footer">
			<div class="icons">
				<a href="https://www.facebook.com/rabbitselection" style="padding:5px;"><img src="@/assets/fb.png"/></a>
				<a href="https://lin.ee/uD8P83r"><img src="@/assets/line.png"/></a>
				<a href="https://www.instagram.com/rabbitselection/"><img src="@/assets/instagram.png"/></a>
			</div>
			<div class="copyright">Copyright㉢2023 Rabbit Selection</div>
			<div class="link">
				<a href="/policy">Privacy Policy</a>
				<span>|</span>
				<a href="/terms">T &amp; Cs</a>
				<span>|</span>
				<a href="/about">About Us</a>
				<span>|</span>
				<a href="/contact">Contact Us</a>
			</div>
		</div>
		<div style="height:50px;"></div>
		<tabbar />
	</div>
</template>

<script>
	import flashWrapper from '@/components/flashWrapper.vue';
	import horzScrollWrapper from '@/components/horzScrollWrapper.vue';
	import Topbar from '@/components/Topbar.vue';
	import Tabbar from '@/components/Tabbar.vue';
	import productUiHalf from '@/components/product-ui-half';
	import productUiFlash from '@/components/product-ui-flash';
	import { cookieGet, cookieSet } from '@/common/cookie';
	import { productService, homepageService, ocdev_api } from '@/common/api';
	import { CalculatAmount } from '@/common/calculation';
	import mixinJumpModule from '@/mixins/jump-module';

	export default {
		name: 'homepage',
		mixins: [mixinJumpModule],
		components: {
			Topbar,
			Tabbar,
			productUiHalf,
			productUiFlash,
			flashWrapper,
			horzScrollWrapper,
		},
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount);
			},
		},
		data() {
			return {
				// tag滑动相关的变量
				tagSlider: {
					// 下面是配置项
					/**以vw计的滑轨长度 */
					railLenth_VW: 6,

					// 下面是动态计算值,初始值不重要
					gridSize: 5,
					/**元素总长度(包括不可视区域)与滑轨长度的比例 */
					shrinkRatio: 0,
					/**以px计的滑块长度 */
					sliderLenth_PX: 0,
					/**以px计的滑块相对滑轨的左偏移 */
					sliderOffset_PX: 0,
					/**是否需要显示滚动条 */
					showSlider: false,
					/**PC端不做滚动样式处理 */
					isMobile: false,
					tagsStatic: null,
					tagsInnerStatic: null,
				},
				// 各种Observer实例,组件退出时需释放
				observer: {
					Resize_openAppImage: null,
					Resize_tags: null,
				},
				tableDataList: [],
				pos: 0,
				dataForm: {
					keywords: 1,
				},
				swipeImg: [],
				googdList: [],
				beginPage: 1,
				rowSize: 10,
				count: 0,
				isUpLoading: false,
				upFinished: false,
				isDownLoading: false,
				maxPage: 1,
				language: '', //语言
				loadingType: true,
				indexObj: {
					carousels: [],
					tags: [],
					data: [],
				},
				loading: false,
				finished: false,
				schemesUrl: 'akuya://fanslink.vip/buyfree',
				appstoreUrl: process.env.config.VUE_APP_IOS_URL,
				googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
				localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
				topShow: true,
				openAppTitle: 'Open APP',
				scrollTop: 0,
				TotalShow: true,
				openAppHeight: '', //openApp图片的实际高度
			};
		},
		created() {
			this.language = cookieGet('language');

			// this.productquerybrandid()
		},
		mounted() {
			// 兼容ssr,依赖环境的值放到client only的代码里运行
			this.tagSlider.isMobile = /(iPhone|iPad|iPod|iOS|Android|adr|Windows Phone|SymbianOS)/gi.test(navigator.userAgent);
			this.getIndexList();

			//针对顶部图片优化,使高度跟随宽度调整
			const obs = new ResizeObserver((entrys) => {
				if (this.$refs.openAppImg) {
					this.openAppHeight = this.$refs.openAppImg.getBoundingClientRect().height.toFixed(2) + 'px';
				}
			});

			obs.observe(this.$refs.openAppImg);
			this.observer.Resize_openAppImage = obs;
		},
		beforeDestroy() {
			for (let obs of Object.values(this.observer)) {
				if (obs !== null && typeof obs.disconnect === 'function') {
					obs.disconnect();
				}
			}
		},
		methods: {
			close() {
				this.TotalShow = false;
			},
			gotoShopping() {
				this.jumpToAppOrStore();
			},
			goodsDetaies(id) {
				this.$router.push({ path: '/singleDetails', query: { productItemId: id } });
			},
			getIndexList() {
				homepageService
					.getList()
					.then((res) => {
						if (res.code == '0') {
							this.loading = true;
							this.loadingType = false;
							res.list.forEach((item) => {
								if (Object.keys(item).length > 0) {
									if (Object.keys(item)[0] == 'carousels') {
										this.indexObj['carousels'] = item['carousels'];
									} else if (Object.keys(item)[0] == 'tags') {
										this.indexObj.tags = item['tags'];
									} else if (Object.keys(item)[0] == 'data') {
										this.indexObj.data = item['data'];
									}
								}
							});
							this.onLoad();
						}
					})
					.then(this.scrollarify)
					.catch((e) => {});
			},
			onLoad() {
				this.loading = false;
				this.finished = true;
			},
			onLoadList() {
				if (this.isDownLoading) {
					this.googdList = [];
					this.isDownLoading = false;
				}
				this.productquerybrandid();
			},
			onDownRefresh() {
				this.rowSize = 10;
				this.upFinished = false; // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
				this.isUpLoading = true;
				this.onLoadList();
			},
			//初始化列表
			productquerybrandid() {
				this.$axios({
					method: 'get',
					url: ocdev_api + '/v1/home/activity/list',
					params: {
						beginPage: this.beginPage,
						rowSize: this.rowSize,
					},
				})
					.then((res) => {
						if (res.code == 0) {
							this.isUpLoading = true;
							this.googdList = res.list;
							this.count = res.list.length;
							if (this.rowSize >= this.count) {
								this.upFinished = true;
							}
							if (this.rowSize < this.count) {
								this.rowSize = this.rowSize + 10;
								this.isUpLoading = false;
							}
						}
					})
					.catch(() => {
						this.isDownLoading = false;
						this.isUpLoading = false;
					})
					.finally(() => {
						this.isDownLoading = false;
						this.isUpLoading = false;
					});
			},
			// 注意scrollarify函数的调用时机,不可令其在ssr期间调用
			scrollarify() {
				const sliderUpdate = function() {
					const slider = this.tagSlider;
					slider.shrinkRatio = (document.body.clientWidth * slider.railLenth_VW) / 100 / this.$refs.tagsInner.clientWidth;

					slider.sliderLenth_PX = this.$refs.tags.clientWidth * slider.shrinkRatio;
					slider.sliderOffset_PX = this.$refs.tags.scrollLeft * slider.shrinkRatio;
				}.bind(this);
				this.$refs.tags.addEventListener('scroll', sliderUpdate);

				const obs = new ResizeObserver((entrys) => {
					this.setTagsStatic();
					this.setTagsInnerStatic();
					this.tagSlider.showSlider = this.$refs.tags.clientWidth < this.$refs.tagsInner.clientWidth;
					sliderUpdate();
				});

				obs.observe(this.$refs.tags);
				this.observer.Resize_tags = obs;
			},
			// 计算标签的动态属性
			setTagsStatic() {
				const LengthUnit = Math.min(205, document.body.clientWidth * 0.38);

				this.tagSlider.tagsStatic = {
					height: `${LengthUnit + 4}px`,
				};
			},
			// 计算标签的动态属性
			setTagsInnerStatic() {
				const column = (this.indexObj.tags.length + 1) >> 1;

				this.tagSlider.tagsInnerStatic = {
					gridTemplateColumns: `repeat(${column}, 1fr)`,
					width: null,
				};

				// 列数量小于5,用百分比宽度以优化显示
				if (column <= 5) {
					this.tagSlider.tagsInnerStatic.width = `${column * 20}%`;
				} else {
					const LengthUnit = Math.min(102, Math.floor(document.body.clientWidth * 0.19));

					this.tagSlider.tagsInnerStatic.width = `${LengthUnit * column}px`;
				}
			},
			/**
			 * @deprecated
			 * 将先上后下,先左后右的排序方式映射为先左后右,先上后下(行上限为2,由此计算出列上限为C),从而无需改动for循环就能达到前者的效果
			 * 以(0, 0)为原点,设此映射为(x,y) => (f(x), f(y))
			 * 前者的索引权重为w_x = 1 , w_y = C, 即索引I满足 I = x + Cy
			 * 使用f(x),f(y)做变换得 I' = f(x) + Cf(y)
			 * 联立 x + Cy = 2f(x) + f(y) ; f(y) = I MOD 2
			 * 得 I' = [I + (2C - 1)(I MOD 2)] / 2
			 * I'即是新的索引
			 */
			tagsRearrange(arr) {
				const Double_C = ((arr.length + 1) >> 1) << 1;
				const rearranged = [];
				arr.forEach((v, i) => {
					rearranged[(i + (Double_C - 1) * (i % 2)) >> 1] = v;
				});
				return rearranged;
			},
		},
	};
</script>

<style>
	.van-image > img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
	.allType .goods-list:nth-child(odd) {
		width: 48%;
		float: right;
		overflow-x: hidden;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.allType .goods-list:nth-child(even) {
		width: 48%;
		float: left;
		overflow-x: hidden;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}
</style>
<style scoped>
	.openAppBox {
		position: fixed;
		/* position: absolute; */
		max-width: 540px;
		width: 100%;
		/* bottom:40px !important; */
		top: 0 !important;
		/* left: 0; */
		z-index: 10;
		/* display: none; */
	}
	.openAppBox_content {
		position: relative;
		margin: 0 auto;
		overflow: hidden;
		height: 60px;
		background: rgba(242, 242, 242, 0.7) !important;
		/* background: #F2F2F2; */
		filter: Alpha(opacity=70);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.openAppBox_content .openAppBox_logo {
		margin-left: 5%;
		max-width: 50%;
		height: auto;
		max-height: 80%;
	}
	.openAppBox_content .openAppBox_btn {
		width: 75px;
		height: 35px;
		font-size: 14px;
		line-height: 35px;
		border-radius: 15px;
		background-color: #f78e1e !important;
		color: #ffffff !important;
		text-decoration: none;
		text-align: center;
		margin-left: 50%;
	}
	.openAppBox_content .openAppBox_close {
		/* max-width: 20%; */
		margin-right: 2%;
		height: auto;
		width: 30px;
	}
	.bar-img {
		width: 95%;
		height: auto;
		margin: 0 auto;
		padding-top: 10px;
		text-align: center;
	}
	.tags {
		width: 95%;
		margin: 10px auto 0;
		background-color: white;
		padding-bottom: 10px;
		text-align: center;
		border-radius: 5px;
		overflow-x: auto;
		overflow-y: hidden;
	}
	.tags-sroll-rail {
		position: relative;
		top: -10px;
		width: 100%;
		background-color: white;
		height: 10px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.tags-sroll-rail .rail {
		background-color: #dedede;
		height: 4px;
		border-radius: 2px;
	}
	.tags-sroll-rail .rail .slider {
		background-color: #f78e1e;
		/* 滑块宽度应是动态的 */
		/* width: 0vw; */
		height: 4px;
		border-radius: 2px;
	}
	.tags-inner {
		background-color: white;
		margin: 0 auto;
		display: grid;
		grid-auto-flow: column;
		grid-template-rows: 1fr 1fr;
		flex-wrap: wrap;
	}
	.tags-inner > img {
		max-width: 102px;
		max-height: 102px;
		width: 19vw;
		height: 19vw;
	}
	.idx_data {
		width: 95%;
		margin: 0 auto;
		height: auto;
		padding-top: 10px;
	}
	.van-col--12 {
		padding-left: 5px;
		padding-right: 5px;
	}
	.goods-list__block__img > img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.footer {
		text-align: center;
	}
	.footer .icons {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 5px;
	}
	.footer .icons > a {
		margin: 0 22px;
	}
	.footer .copyright {
		background-color: white;
		padding: 10px;
		text-align: center;
		color: #a6a5a2;
	}
	.footer .link {
		padding: 5px 0 10px;
	}
	.footer .link a {
		color: #f78e1e;
	}
	.footer .link span {
		margin: 0 5px;
		color: #c4c3c2;
	}
</style>
