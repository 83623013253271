<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Mine_My_Order')"
    />
    <div v-if="isLogin == false" class="login-block">
			<div style="width: 57px; height: 59px; margin-right: 20px;border: 1px solid #E2E2E2;border-radius: 50%;">
				<img src="../../../static/images/md-person2.svg" style="width: 100%; height: 100%;border-radius: 50%;" />
			</div>
			<div style="font-size: 14px;text-decoration:underline">
				<span size="small" @click="login()">{{$t('Common_Login')}}</span>
			</div>
		</div>

    <div v-if="isLogin == true" style="padding-top:50px;padding-bottom:50px;">

      <van-list
        v-model="loading"
        :finished="finished"
        :loading-text="$t('home.loadingText')"
        :finished-text="$t('home.finishedText')"
        @load="onLoad"
      >

        <div v-for="item in originList" :key="item.id" :title="item.createTime" >
          <div @click="details(item.id)">
            <van-cell style="border-bottom:1px;">
              <template #title>
                <span style="color:#999"> {{item.createTime}} </span>
              </template>

              <template #default>
                <span style="color:#F78E1E" v-if="item.status == 'WP'"> {{$t('Mine_Order_Payment')}}</span>
                <span style="color:#F78E1E" v-if="item.status == 'WR'"> {{$t('Mine_Order_Shipping')}}</span>
                <span style="color:#F78E1E" v-if="item.status == 'FINISH'"> {{$t('Mine_Order_Complete')}}</span>
                <span style="color:#F78E1E" v-if="item.status == 'CANCEL'"> {{$t('Mine_Order_Cancel')}}</span>
                <span style="color:#F78E1E" v-if="item.status == 'REFUNDING'"> {{$t('Mine_Order_REFUNDING')}}</span>
                <span style="color:#F78E1E" v-if="item.status == 'REFUNDED'"> {{$t('Mine_Order_REFUNDED')}}</span>
              </template>
            </van-cell>

            <van-row gutter="5" style="text-align:center;background: #fff;border-bottom:1px solid #ebedf0;">
              <van-col span="6" v-for="p in item.products" :key="p.id" >
                <img style="width:70px" :src="p.image" />
              </van-col>
            </van-row>

            <van-cell style="margin-bottom:10px;">
              <template #default>
                <span style="float:right" > {{$t('Order_Total_Amount_1')}} {{item.productCount}} {{$t('Order_Total_Amount_2')}} ฿{{item.amount | Calculat}}</span>
              </template>
            </van-cell>
          </div>
        </div>

      </van-list>
    </div>

    <Tabbar/>
  </div>
</template>

<script>

import Tabbar from '@/components/Tabbar.vue'
import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'

export default {
  name: 'orderList',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
    Tabbar
  },
  computed: {
  },
  data () {
    return {
      chosenAddressId: '',
      originList:[],
      preorderInfo: {},
      isLogin: false,
      loading: false,
      finished: false,
      page:0,
      size:10,
      maxPage: 1
    }
  },
  methods: {
    
    login(){
      this.$router.push({path: '/login'})
    },

    details(id){
      this.$router.push({path: '/myOrderDetails', query:{ orderId: id}})
    },

    onLoad() {
      if(this.maxPage > this.page){
        //这个接口的分页不是正常的,比较坑
        orderService.getList({beginrow: this.page * this.size, rowsize: this.size}).then(res => {
          if(res.code == '0'){
            res.list.forEach( item => {
              this.originList.push(item)
            })
            //向上取整
            this.maxPage = Math.ceil(res.count/this.size)
            this.page +=1
            this.loading = false;
          }
        }).catch(e => {
      
        })
      }else{
        this.finished = true;
      }
      
    },
    
  },
  created () {

    let userDataStr = cookieGet("token")
    if(userDataStr){
      this.isLogin = true
    } else{
      this.isLogin = false
    }
  },
}
</script>
<style scoped>
	.login-block {
		margin-top: 50px;
		background: #FFFFFF;
		height: 135px;
		display: flex;
		align-items: center;
		padding: 0 30px;
	}
</style>
