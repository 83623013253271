<template>
  <div>
    <van-nav-bar
      :left-arrow="false"
      @click-right="onClickRight"
    > 
      <template #left>
        <div @click="userMobile == null ? login() : logout()">
          <div v-if="userMobile == null" >
            <div style="display: inline;">
              <van-icon color="#F78E1E" name="user-o" size="17px"/>
            </div>
            <div style="display: inline;">
            {{$t('Mine_Login')}}
            </div>
          </div>
          <div v-if="userMobile != null">
            <van-icon color="#F78E1E" name="user-o" size="20px"/> 
            {{userMobile}}
          </div>
        </div>
      </template>
      <template #right>
        <van-search v-model="value" :placeholder="$t('public.Searchproducts')" shape="round" style="padding: 0;width: 160px;" />
      </template>
      <!--<template #right>
        <van-dropdown-menu active-color="#F78E1E">
          <van-dropdown-item @change="changeLang"  v-model="defalutLang" :options="languagesList" />
        </van-dropdown-menu>
      </template>-->
    </van-nav-bar>
    
  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
export default {
  data () {
    return {
      defalutLang: this.$i18n.locale,
      languagesList: [],
      userMobile: null,
      value:''
    }
  },
  methods: {
    login(){
      this.$router.push({path: '/login'})
    },
    logout(){
      this.$Dialog.confirm({
        confirmButtonText: this.$t('Common_Confirm'),
        cancelButtonText: this.$t('Common_Cancel'),
        confirmButtonColor: 'red',
        message: this.$t('Common_Logout')
      }).then(() => {
        //退出删除cookie并重新刷新页面
        cookieRemove('token')
        cookieRemove("user")
        this.$router.go(0)
      }).catch(() => {
      });
    },
    changeLang(){
      // console.log(this.defalutLang)
      this.$i18n.locale = this.defalutLang
    },
    onClickRight(){
    	this.$router.push('/searchgoods')
    },
  },
  mounted() {
    let userDataStr = cookieGet("user")
    if(userDataStr){
      let userInfo = JSON.parse(userDataStr)
      this.userMobile = userInfo.country + " " + userInfo.username
    }
    console.log(this.languagesList)
    console.log(this.$i18n.locale)

  },
  created () {
    this.$languages.filter( t => {
      this.languagesList.push({text:t.label,value:t.value})
    })
  },
}
</script>
<style>
	.van-nav-bar__right{
		padding: 0 !important;
	}
	.van-field__control{
		font-size: 10px !important;
	}
</style>
