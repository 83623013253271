<template>
	<div class="voucher-style">
		<van-nav-bar fixed
	      :title="$t('Voucher')"
		  @click-left="$router.go(-1)">
	      <template #left>
			<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  	</template>
	    </van-nav-bar>
		<div style="margin-top: 50px;">
			<van-tabs v-model="active" sticky line-width="50%" color="#F78E1E" title-active-color="#F78E1E">
			  <van-tab :title="$t('personalCenter.Canusecoupon')+'('+usable.length+')'" name="Valid">
			  	<div style="margin-bottom: 100px;">
				  	<div class="usable-card" v-for="(item,index) in usable" :key="index" @click.stop="clickcoupon(index)">
				  		<div class="usable-card_top">
				  			<div class="usable-card_top_left">{{item.typeValue}}</div>
				  			<van-checkbox v-model="item.checked" checked-color="#ddb86a"></van-checkbox>
				  		</div>
				  		<div class="usable-card_bottom">
				  			<div class="usable-card_top_top">{{item.keyValue}}</div>
				  			<div class="usable-card_top_bottom">
				  				<div style="width: 50%;">{{item.beginDate}}~{{item.endDate}}</div>
				  				<div @click.stop="descDetail(index)" style="display: flex;align-items: center;">
				  					{{$t('personalCenter.moreinfo')}}
				  					<van-icon v-if="item.desctype" name="arrow-down" />
				  					<van-icon v-else name="arrow" />
				  				</div>
				  			</div>
				  			<div v-if="item.desctype" style="color: #716765;font-size: 16px;margin-top: 10px;">{{item.desc}}</div>
				  		</div>
				  	</div>
			  	</div>
			  </van-tab>
			  <van-tab :title="$t('personalCenter.Canbeusecoupon')+'('+unusable.length+')'" name="Used">
			  	<div style="margin-bottom: 100px;">
				  	<div class="usable-card" v-for="(item,index) in unusable" :key="index">
				  		<div class="usable-card_top" style="background: #EEEEEE;">
				  			<div class="usable-card_top_left" style="color:#BBBBBB;">{{item.typeValue}}</div>
				  			<!--<van-checkbox v-model="checked" checked-color="#ddb86a"></van-checkbox>-->
				  		</div>
				  		<div class="usable-card_bottom" style="background: #EEEEEE;">
				  			<div class="usable-card_top_top" style="color: #BBBBBB;">{{item.keyValue}}</div>
				  			<div class="usable-card_top_bottom">
				  				<div style="color: #BBBBBB;">{{item.beginDate}}~{{item.endDate}}</div>
				  				<div style="display: flex;align-items: center;color: #BBBBBB;">{{$t('personalCenter.moreinfo')}}<van-icon name="arrow-down" /></div>
				  			</div>
				  			<div style="color: #BBBBBB;font-size: 16px;margin-top: 10px;">{{item.desc}}</div>
				  		</div>
				  	</div>
			  	</div>
			  </van-tab>
			</van-tabs>
		</div>
		<div style="position: fixed;bottom: 0;transform: translate(-50%, -50%);left: 50%;">
			<van-button type="danger" color="#dab96a" style="width: 300px;" @click="ordercounp()">OK</van-button>
		</div>
	</div>
</template>

<script>
	import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'
	import { orderService, commission_api,preorderService } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	import updateTdk from "../../utils/tdk.js"
	export default {
		name: 'details',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		data() {
			return {
				usable:[],
				unusable:[],
				active:'Valid',
				checked:true,
				preorderInfo:{},
			}
		},
		methods: {
			ordercounp(){
				let id=null
				let counponidprice=null
				this.usable.forEach((res,index)=>{
					if(res.checked){
						id = res.id
						counponidprice=res.keyValue
					}
				})
				let orderInfo = getBigCache("preorderInfo")
				if(orderInfo){
			      this.preorderInfo = JSON.parse(orderInfo)
			    }
				this.preorderInfo.counponid=id
				this.preorderInfo.counponidprice=counponidprice
				setBigCache("preorderInfo",JSON.stringify(this.preorderInfo))
				this.$router.push({
					path:'/order'
				})
			},
			descDetail(num){
				this.usable.forEach((res,index)=>{
					if(num==index){
						res.desctype=!res.desctype
					}
				})
			},
			clickcoupon(num){
				this.usable.forEach((res,index)=>{
					if(num==index){
						res.checked=true
					}else{
						res.checked=false
					}
				})
			},
			preordercouponlist(){
				preorderService.preordercouponlist({
					preorderid:this.$route.query.preorderid
				})
				.then(res => {
					console.log(res)
					this.usable=[]
					res.data.usable.forEach(a=>{
						a.checked=false
						a.desctype=false
						this.usable.push(a)
					})
					
					this.unusable=res.data.unusable
				}).catch(() => {})
			},
		},
		
		created() {
			this.preordercouponlist()
		},
	}
</script>
<style scoped="scoped">
		.usable-card{
			width: 100%;
			margin: 5px auto;
			background: #FFFFFF;
		}
		.usable-card_top{
			width:95%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 10px 20px;
			box-sizing: border-box;
			background:radial-gradient(circle at right bottom,#fff,#fff 10px,transparent 11px),
			radial-gradient(circle at left bottom,#fff,#fff 10px,transparent 11px);
			background-color:#ffefe7;
		}
		.usable-card_top_left{
			color: #F78E1E;
		}
		.usable-card_bottom{
			width:95%;
			margin: 0 auto;
			padding: 10px 20px;
			box-sizing: border-box;
			background:radial-gradient(circle at right top,#fff,#fff 10px,transparent 11px),
			radial-gradient(circle at left top,#fff,#fff 10px,transparent 11px);
			background-color:#ffefe7;
		}
		.usable-card_top_top{
			font-size: 48px;
			color: #F78E1E;
		}
		.usable-card_top_bottom{
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			color: #716765;
		}
</style>
<style>
	.voucher-style .van-tabs__nav{
		padding-top: 10px !important;
		padding-bottom: 15px !important;
	}
	.voucher-style .van-tabs__line{
		bottom: 0 !important;
	}
	/*.voucher-style.van-checkbox__icon .van-icon{
		background: #FFFFFF !important;
	}*/
</style>