<template>
  <div>
    <topbar/>
    
    <div style="margin-bottom: 60px; margin-top: 20px; margin-left:30px; margin-right:30px;">
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=url&param1=http://www.baidu.com&param2=&param3=')">系统外部H5页面</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=productItem&param1=67d9e6e1b35741eda3627c94162efbe5&param2=&param3=')">单品详情</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=LOGIN&param1=&param2=&param3=')">登录</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=WALLET&param1=&param2=&param3=')">钱包</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=BUYFREE&param1=&param2=&param3=')">BuyFree活动列表</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=BUYFREEDETAIL&param1=0e42f20e05584242906f332c46ad892c&param2=&param3=')">BuyFree活动详情</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=ACTIVITY&param1=9c358f4955604d029e34988712b1b5ea&param2=&param3=')">品牌专场</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=SHARE&param1=&param2=&param3=')">分享</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=MONOPOLY&param1=146641f4096749f09b6d327c727a8866&param2=&param3=')">活动专场</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=MEMBER&param1=&param2=&param3=')">会员界面</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=MERCHANT&param1=0bd51753bf93431b88de24ce68a70288&param2=&param3=')">店铺</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=BRAND&param1=031ab41ec7bc48ee9de7f8957a9637e4&param2=&param3=')">品牌</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=ALL_PRODUCT&param1=0bd51753bf93431b88de24ce68a70288&param2=ALL PRODUCT&param3=')">全部商品列表</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=CATALOG_PRODUCT&param1=007994442fe040ffaa2198ed9bfadc15&param2= CATALOG PRODUCT&param3=')">目录商品列表</van-button>
		<van-button block type="default" @click="jump('flpando://fanslink.vip?jumpType=SEARCH_PRODUCT&param1=ADIDA SARKYN&param2=SEARCH_PRODUCT&param3=')">搜索商品列表</van-button>
    </div>

    <tabbar/>
  </div>
  
</template>

<script>

import Topbar from '@/components/Topbar.vue'
import Tabbar from '@/components/Tabbar.vue'
import { cookieGet, cookieSet } from '@/common/cookie'
import { productService,ocdev_api} from '@/common/api'
export default {
  name: 'jumpTest',
  components: {
	Topbar,
	Tabbar
  },
  computed: {
  },
  data () {
    return {
      tableDataList:[],
			pos:0,
			appstoreUrl: process.env.config.VUE_APP_IOS_URL,
      googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
      localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
    }
  },
  created () {

  },
  methods:{
	_isMobile() {
		let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
		return flag
	},
	jump(params){
		let self = this

		let ua = navigator.userAgent.toLowerCase();
		if (self._isMobile()){
			if (isWeixinBrowser() || isQQBrowser()) {
				self.$Toast('请使用外部浏览器打开!');
			} else {
				if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {		
					//Ios
					window.location.href = params;
					//延迟1秒弹框 弹框无论点击确定取消都会复制	
					window.setTimeout(function() {
						self.$Dialog.confirm({
							title: '提示',
							message: '系统检测到您当前没有下载APP,是否跳转到应用市场?'
						}).then(() => {
							self.$copyText(params).then(function (e) {
								console.log(e)
								window.location.href = self.appstoreUrl
							}, function (e) {
								console.log(e)
								window.location.href = self.appstoreUrl
							})
						}).catch(() => {
							self.$copyText(params).then(function (e) {
								console.log(e)
							}, function (e) {
								console.log(e)
							})
						});
					}, 1000)    

				} else if (/(Android)/i.test(navigator.userAgent)) {
					//Android终端
					window.location.href = params;
					setTimeout(() => {	
						self.$Dialog.confirm({
							title: '提示',
							message: '系统检测到您当前没有下载APP,是否跳转到应用市场?'
						}).then(() => {
							self.$copyText(params).then(function (e) {
								console.log(e)
								window.location.href = self.googlepalyUrl
							}, function (e) {
								console.log(e)
								window.location.href = self.googlepalyUrl
							})
						}).catch(() => {
							self.$copyText(params).then(function (e) {
								console.log(e)
							}, function (e) {
								console.log(e)
							})
						});
					}, 1000)
				}
			}
		} else {
			self.$Toast('请使用手机浏览器打开!');
		}

		function isWeixinBrowser() {
			return (/micromessenger/.test(ua)) ? true : false
		}

		function isQQBrowser() {
			return !!ua.match(/ qq\//i);
		}
	}
  }
}
</script>
<style scoped>
	.van-button{
		margin-top: 10px;
	}
</style>