<template>
  <div>
    <topbar/>
    <div class="bar-img">
    	<van-loading type="spinner" v-if="loadingType"/>
    	<van-swipe :autoplay="3000" v-else>
			  <van-swipe-item v-for="(image, index) in swipeImg" :key="index">
			    <img :src="image.image" style="width: 100%; height: 150px;"/>
			  </van-swipe-item>
			</van-swipe>
    </div>
    
    <div style="margin-bottom: 60px; margin-top: 20px;">
      <van-pull-refresh v-model="isDownLoading" @refresh="onDownRefresh" :pulling-text="$t('home.loosingText')" :loosing-text="$t('home.loosingText')" :loading-text="$t('home.loadingText')" :success-text="$t('home.successText')">
        <van-list 
          v-model="isUpLoading"
          :finished="upFinished"
          :finished-text="$t('home.finishedText')"
          :loading-text="$t('home.loadingText')"
          @load="onLoadList"
        >
        	<div class="goods-list">
	          <div class="goods-list__block" v-for="(item,index) in googdList" :key="index" @click="goodsDetaies(item.productItemId)">
	            <div class="goods-list__block__img">
	              <img :src="item.imageobjcet.imageurl" />
	            </div>
	            <div class="goods-list__block__details">
	              <div class="goods-list__block__details__name">
	                <span v-if="language=='en-US'">[{{((1-item.price/item.price_origin)*100).toFixed(0)}}% OFF]</span>
	                <span v-if="language=='th-THA'">[ลดสูงสุด {{((1-item.price/item.price_origin)*100).toFixed(0)}}%]</span>
	                {{item.productName}}
	              </div>
	              <div class="goods-list__block__details__price">
	                ฿{{item.price | Calculat}}
	                <span>฿{{item.price_origin |Calculat}}</span>
	              </div>
	            </div>
	          </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <tabbar/>
  </div>
  
</template>

<script>

import Topbar from '@/components/Topbar.vue'
import Tabbar from '@/components/Tabbar.vue'
import { cookieGet, cookieSet } from '@/common/cookie'
import { productService,ocdev_api} from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
export default {
  name: 'h5home',
  components: {
    Topbar,
    Tabbar
  },
  filters: {
		    Calculat(amount){
		      return CalculatAmount(amount)
		    }
		  },
  computed: {
  },
  data () {
    return {
      tableDataList:[],
      pos:0,
      dataForm: {
        keywords: 1,
      },
      swipeImg: [],
      googdList:[],
      beginPage:1,
      rowSize:10,
      count:0,
      isUpLoading: false,
      upFinished:false,
      isDownLoading:false,
      maxPage: 1,
      language:'',//语言
      loadingType:true
    }
  },
  created () {
  	this.language=cookieGet('language');
  	
  	// this.productquerybrandid()
  },
  mounted(){
	this.swipeList()
  },
  methods:{
  	goodsDetaies(id){
  		this.$router.push({path: '/singleDetails',query: { productItemId: id }})
  	},
  	swipeList(){
  		this.$axios({
						method: 'get',
						url: ocdev_api + '/v1/home/carousel/list',
						params: {}
					})
					.then(res => {
						console.log(res)
						if(res.code==0){
							this.swipeImg=res.list
							this.loadingType=false
						}
					}).catch(() => {})
  	},
  	onLoadList() {
      if (this.isDownLoading) {
          this.googdList = [];
          this.isDownLoading = false;
       }
      this.productquerybrandid()
      
    },
    onDownRefresh() {
      this.rowSize = 10
      this.upFinished = false // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      this.isUpLoading = true;
      this.onLoadList()
    },
    //初始化列表
  	productquerybrandid(){
  		this.$axios({
						method: 'get',
						url: ocdev_api + '/v1/home/activity/list',
						params: {
							beginPage:this.beginPage,
							rowSize:this.rowSize
						}
					})
					.then(res => {
						if(res.code==0){
							this.isUpLoading = true;
              this.googdList=res.list      
							this.count=res.list.length
							if(this.rowSize>=this.count){
								this.upFinished=true
							}
							if(this.rowSize<this.count){
									this.rowSize =this.rowSize+10
              		this.isUpLoading = false;
							}
						}
					})
					.catch(() => {
						this.isDownLoading = false
         		this.isUpLoading = false
					})
					.finally(() => {
         		this.isDownLoading = false
         		this.isUpLoading = false
      		})
  	},
  }
}
</script>
<style scoped>
	.bar-img{
		width: 95%;
		height: auto;
		margin: 0 auto;
    padding-top: 20px;
    text-align: center;
	}
	.van-swipe{
		border-radius: 5px;
	}
	.goods-list{
		width: 95%;
		overflow-x: hidden;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.goods-list__block{
		width: 48%;
		background: #FFFFFF;
		margin-bottom: 15px;
	}
	.goods-list__block__img{
		width: 100%;
		padding-top: 100%;
		position: relative;
	}
	.goods-list__block__img img{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.goods-list__block__details{
		width: 100%;
		padding: 10px;
	}
	.goods-list__block__details__name{
		font-size: 16px;
		color: #000000;
		overflow:hidden; 
		text-overflow:ellipsis;
		display:-webkit-box; 
		-webkit-box-orient:vertical;
		-webkit-line-clamp:2; 
		min-height: 30px;
	}
	.goods-list__block__details__name span{
		color: #F78E1E;
	}
	.goods-list__block__details__price{
		font-size: 16px;
		color: #F78E1E;
	}
	.goods-list__block__details__price span{
		color: #CCCCCC;
		font-size: 14px;
		margin-left: 5px;
		text-decoration:line-through;
	}
</style>