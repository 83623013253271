<template>
	<div class="root">
		<van-nav-bar fixed :title="$t('Terms & Conditions')" @click-left="$router.GlobalComponents(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<iframe src="/static/trans.html?terms"></iframe>
		<!-- <div class="WordSection1" style="margin-top:20px;layout-grid:15.6pt">
			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<b
					><span
						lang="EN-US"
						style="font-size:10.5pt;
font-family:Calibri;
"
						>&nbsp;</span
					></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin: 12pt 10.5pt;text-align:left;"
			>
				<b
					><span
						lang="EN-US"
						style="font-family:Calibri;"
						>Terms of Use</span
					></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;">&nbsp;<o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>These terms of and use (the "Terms") are between you and Rabbit Selection and govern your use of Rabbit Selection applications (together
					with any updates and enhancements to it, each an "Application") for mobile devices including Apple iPhone, iPad, Android, Microsoft Windows
					mobile device (each a "Device").<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Acceptance of these Terms</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>By downloading or using an Application, you agree to these Terms. The Application will allow you to access and use Rabbit Selection. Each
					time you use the Application, you reaffirm your acceptance of the then current Terms. If you do not wish to be bound by these Terms, you
					shall discontinue using the Application.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Modifications to the Terms</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Rabbit Selection may change these Terms at any time and in its sole discretion. The modified Terms will be effective immediately upon
					posting within the Application or on a Site, and you agree to the new posted Terms by continuing your use of the Application. If you do not
					agree with the modified Terms, you shall discontinue using the Application.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Limited Use of Application</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Rabbit Selection grants you a personal, non-exclusive, non-transferable right to install and use the Application in object code form only
					on your Device. You may not charge others to use or access the Application without first obtaining written permission from Rabbit Selection.
					You may not sell, assign, grant a security interest in or otherwise transfer any right in the Application or incorporate it (or any portion
					of it) into another product. You may not copy the Application. You may not translate, reverse-engineer or reverse-compile or decompile,
					disassemble, make derivative works from, or otherwise attempt to discover any source code in the Application. You may not modify the
					Application or use it in any way not expressly authorized by these Terms. You agree that you will not access the Application through
					unauthorized means, such as unlicensed software clients.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Content You May Submit</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>You may be permitted to submit or post any content to Rabbit Selection through the Application such as digital images, text, photographs,
					graphics, videos, sound, software ("Content"). You may only submit&nbsp;Content&nbsp;for which you hold all intellectual property rights or
					for which you have obtained the authorization from the person holding all the intellectual property rights. You may not submit or post any
					Content that is unlawful, obscene, discriminatory, defamatory,&nbsp;<a name="OLE_LINK2"></a><a name="OLE_LINK1"></a
					><span style="mso-bookmark:OLE_LINK2">libelous</span>, threatening or harassing, sexually explicit or harmful to minors or that violates
					these Terms. By posting or submitting any Content through the Application, you represent and warrant that (<span class="SpellE">i</span>)
					you own all the rights to the Content or are authorized to use and distribute the Content through the Application; (ii) the Content does not
					and will not infringe any copyright or any other third-party right nor violate any applicable law or regulation; (iii) the Content does not
					contain any virus or other computer code or programs that may interrupt, destroy or limit the operation of the Application, other network or
					equipment; (iv) the Content complies with applicable laws and regulations.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>You or the owner of the Content retains ownership of all right, title and interest in the Content that you submit or post through the
					Application. By submitting or posting Content through the Application, you grant Rabbit Selection the royalty-free, irrevocable, perpetual,
					non-exclusive, transferable right to (<span class="SpellE">i</span>) reproduce, copy, store, display, publicly perform, distribute, or
					transmit any part or all of the Content in any medium known now or in the future, (ii) adapt, translate, modify and create any derivative
					works from any part or all of the Content in any manner and in any medium known now or in the future; all for any purposes as Alibaba.com
					considers appropriate.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Rabbit Selection's Right to Remove Content</span></b
				><span lang="EN-US" style="mso-bidi-font-size:10.5pt;font-family:Calibri;mso-bidi-font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Rabbit Selection is not liable for Content that is provided by others. Rabbit Selection reserves the right to remove Content that, in its
					sole judgment, does not comply with these Terms or applicable laws or regulations, but Rabbit Selection is not responsible for any failure
					or delay in removing such material.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">You May Use Application for Lawful Purposes Only</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Without prejudice to the generality of the foregoing provisions, you may use the Application for lawful purposes only. When using the
					Application or conducting any activities through the use of the Application, you will not (1) violates or infringes in any way upon the
					rights of others, (2) engage in or encourage conduct that would constitute a criminal offense or gives rise to civil liability, (3)
					otherwise violates any law. You also may not undertake any conduct that, in Rabbit Selection 's judgment, restricts or inhibits any other
					user from using or enjoying the Application.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Changes to the Application</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Rabbit Selection has the right at any time to change, modify, upgrade, add to or suspend, discontinue or retire any aspect or feature of
					the Application. Rabbit Selection has no obligation to provide you with notice of any such changes and will not be liable to you should we
					exercise these rights.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal" align="left" style="margin-top:12.0pt;margin-right:10.5pt;margin-bottom:12.0pt;margin-left:10.5pt;text-align:left">
				<b><span lang="EN-US" style="font-family:Calibri;">Disclaimer of Warranty; Limitation of Liability</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Disclaimer of warranties. Your use of the application is at your sole risk. To the maximum extent permitted by law, the application
					<span class="GramE">are</span> provided "as is," "with all faults" and "as available" for your use, without warranties of any kind, either
					express or implied, unless such warranties are legally incapable of exclusion. Specifically, Rabbit Selection disclaims implied warranties
					that the application <span class="GramE">are</span> merchantable, of satisfactory quality, accurate, fit for a particular purpose or need,
					or non-infringing. Rabbit Selection does not warrant that the functions contained in the application will meet your requirements or that the
					operation of the application will be uninterrupted or error-free, or that defects in the application will be corrected. Rabbit Selection
					does not warrant or make any representations regarding the use or the results of the use of the application or related documentation in
					terms of their correctness, accuracy, reliability or otherwise. Rabbit Selection does not guarantee that you will be able to access or use
					the application at times or locations of you choosing, or that Rabbit Selection will have adequate capacity for the application as a
					whole.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Limitation of liability. To the maximum extent permitted by law, your exclusive remedy with respect to any dispute with Rabbit Selection
					(including without limitation your use of the application) is to discontinue your use of the application. Rabbit Selection shall not be
					liable for any indirect, special, incidental, consequential or exemplary damage arising from your use of the application or for any other
					claim related in any way to your use of the application. These exclusions for indirect, special, incidental, consequential or exemplary
					damages include, without limitation, damages for lost profits, lost data, loss of goodwill, work stoppage, computer failure or malfunction,
					or any other commercial damages or losses, even if Rabbit Selection had been advised of the possibility thereof and regardless of the legal
					or equitable theory upon which the claim is based. In any event your aggregate liability shall not exceed $100. In case jurisdictions do not
					allow the exclusion or the limitation of liability for indirect, consequential or incidental damages, Rabbit Selection's aggregate liability
					in such jurisdiction shall be limited to the extent permitted by law.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span lang="EN-US" style="font-family:Calibri;color:black;"
					>Exclusion of liability for transactions. Rabbit Selection does not endorse, warrant or guarantee any product or service offered through the
					application will not be a party in any way be responsible for monitoring any transaction between you and third-party providers or purchaser
					as appropriate of products or services.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Indemnification</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<a name="OLE_LINK4"></a><a name="OLE_LINK3"></a
				><span style="mso-bookmark:OLE_LINK4"
					><span
						lang="EN-US"
						style="
font-family:Calibri;
color:black;"
						>You agree to defend, indemnify and hold harmless Rabbit Selection and our directors, officers, employees and agents from and against
						all claims and expenses, including attorneys' fees, arising out of your use of the Application. Rabbit Selection reserves the right, at
						its own expense and in its sole discretion, to assume the exclusive defense and control of any matter otherwise subject to
						indemnification by you.</span
					></span
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Support for Application</span></b
				><span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					><o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					>You understand that your use of the Application is at your own risk and that Rabbit Selection is under our sole discretion to provide you
					with any error corrections, updates, upgrades, bug fixes, enhancements and/or other maintenance or support of the Application. No third
					party will be responsible for providing maintenance or support services for the Application.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Third-Party Services</span></b
				><span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					><o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					>When you use the Application, you may also use services of one or more third parties. Your use of such third party services will be at your
					own risk and subject to the terms and conditions of those third parties. Rabbit Selection does not make any representation or warranty in
					whatever aspect on those third party services.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Termination</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					>Rabbit Selection has the right to terminate your right to use the Application any time for any reason and shall be under no obligation to
					provide you a warning prior to termination of your use of the Application. You may terminate these Terms at any time by uninstalling or
					destroying all copies of the Application that are in your possession or control. In case of termination, you must cease all use and destroy
					all copies of the Application.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Notices</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					>All notices or demands to or upon Rabbit Selection shall be effective if in writing and shall be duly made when sent to Rabbit Selection in
					the following manner: to FANSLINK COMMUNICATION CO., LTD., Thailand.<o:p></o:p
				></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:12.0pt;margin-right:10.5pt;
margin-bottom:12.0pt;margin-left:10.5pt;text-align:left"
			>
				<b><span lang="EN-US" style="font-family:Calibri;">Miscellaneous</span></b
				><span lang="EN-US" style="font-family:Calibri;color:black;"><o:p></o:p></span>
			</p>

			<p
				class="MsoNormal"
				align="left"
				style="margin-top:0cm;margin-right:10.5pt;
margin-bottom:0cm;margin-left:10.5pt;margin-bottom:.0001pt;text-align:left;"
			>
				<span
					lang="EN-US"
					style="
font-family:Calibri;
color:black;"
					>The provisions of these Terms addressing disclaimers of representations and warranties, limitation of liability, indemnity obligations,
					intellectual property, and governing law shall survive the termination of these Terms.<o:p></o:p
				></span>
			</p>

			<p class="MsoNormal">
				<span lang="EN-US"><o:p>&nbsp;</o:p></span>
			</p>
		</div> -->
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.root {
		padding: 15px;
		padding-top: 41px;
		height: 100%;
		box-sizing: border-box;
	}

	iframe {
		width: 100%;
		height: 98%;
		margin-top: 2%;
		border: none;
	}
</style>
