<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { cookieSet } from '@/common/cookie'
import { messages } from '@/i18n'
export default {
  name: 'app',
  metaInfo: {
    title: process.env.config.VUE_APP_TITLE
  },
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  mounted(){
    // let val,oldVal = this.$i18n.locale
    // // cookieSet('language', val)
    // // document.querySelector('html').setAttribute('lang', val)
    // // document.title = messages[val].brand.lg
    // // // 切换语言刷新页面
    // // if (oldVal) {
    // //   window.location.reload()
    // // }
    // cookieSet('language', val)
    // document.querySelector('html').setAttribute('lang', val)
    // // document.title = messages[val].brand.lg
    // // 切换语言刷新页面
    // console.log(val)
    // console.log(oldVal)
    // // if (oldVal) {
    // //   window.location.reload()
    // // }
  },

  created () {
    this.i18nHandle(this.$i18n.locale)
  },
  methods: {
    i18nHandle (val, oldVal) {
      cookieSet('language', val)
      // document.querySelector('html').setAttribute('lang', val)
      // document.title = messages[val].brand.lg
      // 切换语言刷新页面
      if (oldVal) {
        window.location.reload()
      }
    }
  }
}
</script>


<style lang="less">

html,body{
  max-width: 540px; 
  margin: 0 auto;
  background-color: #f7f8fa;
  width: 100%;
  height: 100%;
}
#app {
  font-family: "Microsoft YaHei",Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.error_tip{
	width: 80% !important;
}
.error_tip .van-toast__text{
	font-size: 18px;
}
.van-icon-search {
    color: #f78e1e !important;
}
</style>
