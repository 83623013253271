<template>
	<div class="p2 scroll">
		<van-swipe class="swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item v-for="item of swipe" :key="item.id">
				<img :src="item.image" />
			</van-swipe-item>
		</van-swipe>
		<div v-for="(v, k) in brands" :key="k">
			<p class="my1" :id="k">{{ k }}</p>
			<van-cell-group>
				<van-cell v-for="item in v" :key="item.id" @click="$router.push({ path: '/merchantCatalog', query: { merchantId: item.id } })">
					<template #title>
						<div class="fx-list" style="height:36px;">
							<img class="circle" style="border: 2px solid rgb(240, 240, 240);" :src="item.image" height="36px" width="36px" />
							<span class="mx4e">{{ item.name }}</span>
						</div>
					</template>
				</van-cell>
			</van-cell-group>
		</div>
		<div class="alphabet fy">
			<a
				class="btnlike fx-box my1e"
				@click="currentAlpha = k"
				:class="{ btnHighlight: currentAlpha === k }"
				v-for="(v, k) in brands"
				:key="k"
				:href="'#' + k"
				>{{ k }}</a
			>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				/**
				 * 前端的品牌结构为一Object,其键值为字母A-Z,值为一数组,代表属于当前字母的所有品牌结构
				 */
				brands: {},
				/**顶部轮播 */
				swipe: [],
				/**当前选中品牌 */
				currentAlpha: '',
			};
		},
		created() {
			this.$axios.get('/v2/newcategory/brand/more').then((res) => {
				for (let item of res.data.list) {
					this.$set(this.brands, item.key, item.array);
				}
				this.swipe = res.data.carouselList;
				console.log('arr', this.brands);
			});
		},
	};
</script>

<style scoped>
	.circle {
		border-radius: 50%;
	}
	.scroll {
		overflow: auto;
	}
	.swipe {
		border-radius: 5px;
	}
	.alphabet {
		position: fixed;
		top: calc(50%);
		transform: translateY(-50%);
		right: 10px;
		z-index: 100;
	}
	.btnlike {
		color: #a2a2a2;
		width: 0.8em;
		height: 0.8em;
		padding: 0.2em;
		border-radius: 50%;
	}
	.btnHighlight {
		background-color: rgb(247, 142, 30);
		color: white;
	}
</style>
