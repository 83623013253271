<template>
	<div class="commissionrecord">
		<van-nav-bar fixed :title="$t('personalCenter.Commissionrecord')" @click-left="$router.back(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  	</template>
		</van-nav-bar>
		<div style="margin-top: 50px;">
			<van-tabs v-model="active" title-active-color="#F78E1E" title-inactive-color="#000000" color="#F78E1E" @change="switchTab">
			  <van-tab v-for="(i,index) in barList" :key="index">
			    <template #title>
			      <div style="font-size: 14px;text-align: center;">
			      	{{i.name}}
			      </div>
			      <div style="padding: 15px 0;text-align: center;">
			      	<!--<span style="font-size: 14px;">฿</span>-->
			      	<span style="font-size: 20px;">{{i.price | Calculat}}</span>
			      </div>
			    </template>
			    <template #default>
			   		<div v-if='index==0'>
				    	<div v-for="(item,index) in finishList" :key="index"  style="background: #FFFFFF; padding: 15px 20px;margin-top: 5px;">
							      <div style="display: flex; align-items: center;justify-content: space-between; margin-bottom: 10px;">
							      	<div style="color: #101010;font-size: 14px;">
							      		<span>{{item.country}}</span>
							      		<span style="margin: 0 5px;">{{item.username}}</span>
							      		<span style="color: #C9C9C9;margin-left: 5px;">{{$t('personalCenter.rebate')}}</span>
							      	</div>
							      	<div style="color: #F78E1E;">
							      		<span style="font-size: 14px;margin-right: 10px;">฿</span>
							      		<span style="font-size: 20px;">{{item.amount | Calculat}}</span>
							      	</div>
							      </div>
							      <div style="display: flex;align-items: center;justify-content: space-between;font-size: 11px;color: #C9C9C9;">
							      	<div></div>
							      	<div>{{item.settledDate}}</div>
							      </div>
					      </div>
					      <div v-if="finishList.length==0">
				      		<div style="margin:0 auto; width: 100%; text-align: center;">
				      			<img src="../../../static/images/no-list.png" width="40%" height="auto"/>
				      			<p style="font-size: 14px; color:#9F9F9F;">{{$t('public.noRecording')}}</p>
				      		</div>
				      </div>
				      </div>
			    	 <div v-if='index==1'>
			    	 	<div v-for="(item,index) in initList" :key="index" style="background: #FFFFFF; padding: 15px 20px;margin-top: 5px;">
						      <div style="display: flex; align-items: center;justify-content: space-between; margin-bottom: 10px;">
						      	<div style="color: #101010;font-size: 14px;">
						      		<span>{{item.country}}</span>
						      		<span style="margin: 0 5px;">{{item.username}}</span>
						      		<span style="color: #C9C9C9;margin-left: 5px;">{{$t('personalCenter.torebate')}}</span>
						      	</div>
						      	<div style="color: #F78E1E;">
						      		<span style="font-size: 14px;margin-right: 10px;">฿</span>
						      		<span style="font-size: 20px;">{{item.amount | Calculat}}</span>
						      	</div>
						      </div>
						      <div style="display: flex;align-items: center;justify-content: space-between;font-size: 11px;color: #C9C9C9;">
						      	<div>{{$t('personalCenter.orderafterTipe')}}</div>
						      	<div></div>
						      </div>
						</div>
						<div v-if="initList.length==0">
				      		<div style="margin:0 auto; width: 100%; text-align: center;">
				      			<img src="../../../static/images/no-list.png" width="40%" height="auto"/>
				      			<p style="font-size: 14px; color:#9F9F9F;">{{$t('public.noRecording')}}</p>
				      		</div>
				      </div>
				      </div>
				    <div v-if='index==2'>
				    <div v-for="(item,index) in failureList" :key="index" style="background: #FFFFFF; padding: 15px 20px;margin-top: 5px;">
						      <div style="display: flex; align-items: center;justify-content: space-between; margin-bottom: 10px;">
						      	<div style="color: #101010;font-size: 14px;">
						      		<span>{{item.country}}</span>
						      		<span style="margin: 0 5px;">{{item.username}}</span>
						      		<span style="color: #C9C9C9;margin-left: 5px;">{{$t('personalCenter.failedRebate')}}</span>
						      	</div>
						      	<div style="color: #F78E1E;">
						      		<span style="font-size: 14px;margin-right: 10px;">฿</span>
						      		<span style="font-size: 20px;">{{item.amount | Calculat}}</span>
						      	</div>
						      </div>
						      <div style="display: flex;align-items: center;justify-content: space-between;font-size: 11px;color: #C9C9C9;">
						      	<div>{{$t('personalCenter.refunded')}}</div>
						      	<div>{{item.settledDate}}</div>
						      </div>
				      </div>
				      <div v-if="failureList.length==0">
				      		<div style="margin:0 auto; width: 100%; text-align: center;">
				      			<img src="../../../static/images/no-list.png" width="40%" height="auto"/>
				      			<p style="font-size: 14px; color:#9F9F9F;">{{$t('public.noRecording')}}</p>
				      		</div>
				      </div>
				    </div>
			    </template>
			  </van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService,commission_api } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {
		},
		computed: {},
		data() {
			return {
				active:0,
				barList:[{
					price:0,
					name:this.$t('personalCenter.gained')
				},{
					price:0,
					name:this.$t('personalCenter.ontheway')
				},{
					price:0,
					name:this.$t('personalCenter.failed')
				}],
				initList:[],
				failureList:[], //失败
				finishList:[], //成功
			}
		},
		methods: {
			switchTab(name){
				console.log(this.active)
				if(this.active==0){
					this.finishcommissiongList()
				}
				if(this.active==1){
					this.commissiongList()
				}
				if(this.active==2){
					this.failurecommissiongList()
				}
			},
			commissiongAmout(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/commission/amount',
						params: {}
					})
					.then(res => {
						if(res.code == 0) {
							this.barList[2].price=res.data.failureAmount
							this.barList[0].price=res.data.finishAmount
							this.barList[1].price=res.data.initAmount
						}
					}).catch(() => {})
			},
			commissiongList(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/init/commission/order/list',
						params: {
							beginPage:1,
							rowSize:999
						}
					})
					.then(res => {
						if(res.code == 0) {
							this.initList=res.list
						}
					}).catch(() => {})
			},
			finishcommissiongList(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/finish/commission/order/list',
						params: {
							beginPage:1,
							rowSize:999
						}
					})
					.then(res => {
						console.log(res)
						if(res.code == 0) {
							this.finishList=res.list
						}
					}).catch(() => {})
			},
			failurecommissiongList(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/failure/commission/order/list',
						params: {
							beginPage:1,
							rowSize:999
						}
					})
					.then(res => {
						console.log(res)
						if(res.code == 0) {
							this.failureList=res.list
						}
					}).catch(() => {})
			},
		},
		created() {
			this.commissiongAmout()
			this.finishcommissiongList()
		},
	}
</script>

<style>
	.van-tab{
		line-height: normal !important;
	}
	.van-tabs__wrap{
		height: auto !important;
	}
	.van-tabs__nav{
		padding-bottom: 25px !important;
	}
	.commissionrecord .van-tabs__line{
		width: 100px;
	}
</style>