<template>
  <div id="home-container" style="width: 100vw;overflow: hidden;">
    <div class="header-bgimg">
      <div class="header-bgimg-float">
        <div id="scroll-header" class="home-header flex flex-align-center flex-pack-justify" style="">
          <div class="flex flex-align-center" style="width: 130px;">
            <img src="../../assets/new_logo.png" alt="" style="width: 50px;">
          </div>
          <!--<div class="homeheader">
            <div :class="isScroll == true ? 'home-menu-scroll' : 'home-menu'">HOME</div>
            <div :class="isScroll == true ? 'home-menu-scroll' : 'home-menu'">MALL</div>
            <div :class="isScroll == true ? 'home-menu-scroll' : 'home-menu'">CONTACT</div>
            <div 
              :class="isScroll == true ? 'home-menu-scroll' : 'home-menu'" 
              style="font-weight: 500;">
              <i 
                class="iconfont iconguoji" 
                :style="isScroll == true ? 'color:#000;' : 'color:#FFFFFF;'+'font-size:25PX;'"/>
            </div>
          </div>-->
          <div class="homeheader1 flex-align-center" style="width:30px ;">
          	<p v-if="!isScroll" style="color: #FFFFFF;">Rabbit</p>
          	<p v-else>Rabbit</p>
            <!--<img src="../../assets/caidan.png" alt="" style="height:30PX;width:30PX;"  class="homelogo">-->
          </div>
        </div>
        <div style="height:65PX"/>
        <div class="home-banner  " >
          <div class="home-banner-float">
            <div class="flex flex-pack-center trusted" style="font-size: 25px;"><span>Rabbit SELECTION - We select the best for you</span></div>
            <div class="flex flex-pack-center " style="display: flex;justify-content: center;">
              <div 
                class="home-download fy-list" 
                @click="gotoShopping">
                <span class="fx-list"> <img src="../../assets/download.png" style="width:20px;height:20px;margin-right:5px;">
                  Download Now</span>
              </div>
            </div>
                  

            <div class="flex flex-pack-center check-row" >
              <div 
                class="check-download flex  flex-align-center flex-pack-center" 
                style="text-align: center;" > 
                <img 
                  src="../../assets/bonner.png" 
                  alt="" class="bonner">
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="features">
      <h1 
        style="text-align: center;" 
        class="features-title" >RABBIT Selection
        <div class="features-bottom" style="font-weight:bold;line-height:0px;top:115px;">—————</div>
      </h1>
        
      <div class="flex flex-pack-center">
        <div 
          style="text-align: center;width:98%;margin: 0 auto;" 
          class="bann-p">
          <p>We deliver well-designed products, good quality at absolutely reasonable prices from the world best manufacturers and directly serve to you. Our mission is to make consumers’ live smarter and better at reasonable spending.</p>
        </div>
      </div>     
    
    </div>
    <!--<div class="home-banner-2">
      <div class="home-banner-2-float">
        <h1 
          style="text-align: center;color:#eb6100;" 
          class="features-title">OUR CULTURE
          <div 
          class="features-bottom" 
          style="color:#eb6100;font-weight:bold;line-height:0px;top:115px;">————</div>
        </h1>
        <div 
          class="flex flex-around-justify flex-warp-justify culture" 
          style="text-align: center;margin: 0 auto;width: 100%;display: flex;align-items: center;flex-wrap: wrap;justify-content: space-around;" >
          <div v-for="(item,index) in culture"  class="culture-block" :key="index">
            <div><img 
              :src="item.img" 
              alt=""></div>
            <div class="culture-title">{{ item.title }}</div>
            <div class="culture-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>-->
    <div class="features" style="background: #FFFFFF;">
      <h1 
        style="text-align: center;" 
        class="features-title">OUR PARTNERS
        <div class="features-bottom  flex   flex-pack-center" >
          <div style="width:200PX;height:4PX;background:rgba(255,255,255,1);"/>
        </div>
        <div class="features-bottom" style="font-weight:bold;line-height:0px;top:115px;">————</div>
      </h1>
      <div class="features-body ">
        <div class="partners  flex  flex-align-center">
          <img src="../../assets/b31523ab7c7d426111383f518259040.png" width="100%" style="height: auto;"/>
         </div>
      </div>
    </div>
    <div class="home-footer flex flex-pack-center">
      <div class="home-footer-div" style="width: 95%;margin: 0 auto;">
        <!--<img 
          src="../../assets/footer.png"  class="home-footer-img"
          alt="">-->
        <div style="display: flex;justify-content:space-between;width: 40%;margin: 0 auto;">
        	<img src="../../assets/76c95f072460c4e87805ced1eb54983.png" width="40px" height="40px" @click="openPath('https://www.facebook.com/rabbitselection')" style="background: #FFFFFF;border-radius: 50%;"/>
        	<img src="../../assets/850f904f19f20f25502e5bb9850002c.png" width="40px" height="40px" @click="openPath('https://www.instagram.com/rabbitselection/')" style="background: #FFFFFF;border-radius: 50%;"/>
        </div>  
        <p>Rabbit Selection</p>
        <p>Email Address : rabbitselection1@gmail.com</p>
      </div>
            <img src="../../assets/top.png" class="checktop" alt="" @click="goTop">
    </div>
    <div v-if="wechatNoShow" style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index:99999999999 !important">
	      <div style="float:right;position: relative;top: 20px;right: 30px;">
	        <img style="width:110px;height:110px;" src="../../../static/images/wechat_arrows.png" />
	      </div>
	      <div style="position: relative;top: 50px;color:#FFF;text-align:center;font-size:20px;font-weight:bold;width:100%;height:40px;clear: both;">
	        Please select "Open in browser"
	      </div>
	    </div>
      <noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1080020109405696&ev=PageView&noscript=1" /></noscript>
    </div>
</template>
<script>
// import Index from '~/pages/_lang/index'
// export default Index
import mixinJumpModule from '@/mixins/jump-module'
export default {
  name: "App",
  mixins: [ mixinJumpModule ],
  components: {},
  data() {
    return {
      culture: [
        {
          img: require("../../assets/sfen.png"),
          title: "Vision",
          content:
            "To be the most trusted e-commerce platform where customers can discover anything."
        },
        {
          img: require("../../assets/semfkl.png"),
          title: "Mission",
          content:
            "We strive to offer our customers the lowest possible prices, the best available selection, and the utmost convenience."
        },
        {
          img: require("../../assets/sgneflm.png"),
          title: "Core Value",
          content: "Ownership, Inspiration,Innovation, Collaboration."
        },
        {
          img: require("../../assets/spajf.png"),
          title: "Our Style",
          content: "Respond Well,Act immediately."
        }
      ],
      isScroll: false,
      schemesUrl: 'akuya://fanslink.vip/buyfree',
      appstoreUrl: process.env.config.VUE_APP_IOS_URL,
      googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
      localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
      wechatNoShow:false,
    };
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    
    !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1080020109405696');
fbq('track', 'PageView');

  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
  	openPath(path){
  		console.log('1')
  		window.location.href=path
  	},
    gotoShopping(){
      this.jumpToAppOrStore()
    },
    goDetails() {
      this.$router.push({ path: "/details" });
    },
    doOpen(url) {
      window.open(url);
    },
    doShare() {},
    doDownLoad() {},
    goTop(){
      window.scrollTo(0,0);  
    },
    handleScroll () {
      let self = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      console.log(scrollTop)
      let obj = document.getElementById("scroll-header")
      if (scrollTop >= 80) {
        obj.style.cssText = "background-color:#ffffff;"
        self.isScroll = true
      } else {
        obj.removeAttribute("style");
        self.isScroll = false
      }
    },
  },
  created(){
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/mixinmap";
@import "../../variable.scss";
.homelogo {
  @include handleWidth($homelogo);
}
.homeheader {
  @include handleMaxDisplay($homeheader);
}
.homeheader1 {
  @include handleMaxDisplay($homeheader1);
}
.home-header {
  display: flex;
  align-items:center;
  z-index: 10;
  height: 80PX;
  font-size: 22PX;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // background-color: #fff;
  font-weight: 600;
  color: #505050;
  box-sizing: border-box;
  @include handlePadding($homepadding);
  :hover {
    color: #e46229;
  }
}
.bonner {
  @include handleWidth($bonner);
}
.home-menu {
  cursor: pointer;
  padding: 0 20PX;
  height: 65PX;
  line-height: 65PX;
  font-size: 22PX;
  color: #FFFFFF;
}
.home-menu-scroll {
  cursor: pointer;
  padding: 0 20PX;
  height: 65PX;
  line-height: 65PX;
  font-size: 22PX;
  color: #000;
}
.header-bgimg{
  background-image: url("../../assets/377047547ef464ff12cce3d9f3304d1.jpg");
  // background-image: url("../../assets/Shape8.png");
  background-position: 50% 0PX;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.header-bgimg-float{
  background-image: url("../../assets/Shape8-1.png");
}
.home-banner {
  // height: 450PX;
  // background-image: url("../../assets/377047547ef464ff12cce3d9f3304d1.jpg");
  // background-position: 50% 32.5PX;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
}
.home-banner-float {
  padding-top: 12vw;

  // background-image: url("../../assets/Shape8.png");
}

.home-banner-2 {
  background-image: url("../../assets/oc_bgi.jpg");
  background-position: 50% 32.5PX;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.home-banner-2-float {
  // background-image: url("../../assets/falsj.png");
  background-image: url("../../assets/Shape8-2.png");
  padding-bottom: 72PX;
}
.culture {
  @include handleWidth($culture);
}
.culture-block {
  padding: 35PX 5PX;
  width: 45%;
  @include handleWidth($culture_block_width);
  background-image: url("../../assets/nlwdlam-1.png");
  text-align: center;
  height: 300PX;
  margin: 5px 0;
  img {
    margin: 10PX 0;
    height: 30PX;
    @include handleHeight($culture_img_height);
  }
}
.culture-content {
  font-size: 15PX;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // color: rgba(15, 48, 104, 1);
  color: #FFFFFF;
  line-height: 25PX;
}
.parters-left {
  color: #eb6100;
}
.culture-title {
  margin-bottom: 20PX;
  color: #eb6100;
  font-size: 22PX;
  font-weight: 600;
}
.features-body {
  width: -webkit-fill-available;
  @include handlePadding($features_body_padding);
}
.partners {
  width: 100%;

  // height: 400PX;
  background-color: #fff;
  &-left {
    width: 300PX;
    text-align: center;
    @include handleWidth($partners_left_width);

    p {
      @include handleFontSize($partners_left_font_size);
      color: #000;
      font-weight: bold;
    }
  }
  &-right {
    flex: 2;
    height: 100%;
    padding: 0 30PX;
  }
}
.partners-img {
  @include handleHeight($partners_img_height);
}
.partners2-img {
  @include handleHeight($partners2_img_height);
}
.partners2 {
  margin-top: 40PX;
}
.partners2 .partners-left {
  background-size: 15PX 100%;
}
.partners2 .partners-right {
  text-align: center;
  // background: url("../../assets/logo.png") no-repeat center center;
  height: 100%;
}
.bann-p {
  width: 600PX;
  @include handleLineHeight($bannp_lineheight);
  font-size: 18PX;
  color: #000;
  margin-bottom: 20PX;
  p {
    color: #000;
    @include handleFontSize($home_footer_font_size);
    margin: 0;
  }
}
.down-img {
  cursor: pointer;
  box-shadow: 0PX 0PX 8PX 0PX rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 15PX;
  border-bottom-left-radius: 15PX;
}
.banner-top {
  padding-top: 60PX;
}
.banner-title {
  font-size: 70PX;
  color: rgb(242, 99, 36);
}
.trusted {
  text-align: center;
  margin-bottom: 50PX;
  span {
    color: #fff;
    font-weight: bold;
    @include handleFontSize($trusted_font_size);
  }
}

.home-download {
  background-color: rgb(218, 185, 107);
  width: 210PX;
  height: 63PX;
  line-height: 63PX;
  margin: 10PX 0 10PX 0;
  text-align: center;
  text-shadow: 0 1PX 1PX rgba(21, 21, 22, 0.25);
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  span {
    color: #fff;
    font-size: 20PX;
  }
}
.home-footer-img {
  @include handleWidth($home_footer_img_width);
}

.home-into {
  color: #fff;
  padding: 20PX;
  @include handleWidth($home_into_width);
  span {
    font-size: 17PX;
  }
}
.check-row {
  padding-top: 56PX;
  padding-bottom: 86PX;
}
.check-download {
  span {
    font-size: 20PX;
  }
}
.features {
  // background-image: url("../../assets/pattern_bg.png");
  padding-bottom: 80PX;
  // color: #000;
  // background-color: #0f439c;
  &-row {
    color: #fff;
    font-size: 18PX;
    line-height: 45PX;
    padding: 15PX;
  }
  &-body {
  }
  &-item {
    padding: 60PX 10PX 30PX 10PX;
    text-align: center;
    border-bottom: 20PX solid #cecece;
    border-top: 20PX solid #cecece;
    border-right: 10PX solid #cecece;
    border-left: 10PX solid #cecece;
    width: 285PX;
    margin-top: 40PX;
    margin-bottom: 40PX;
    background-color: #fff;
  }
  &-smalltitle {
    color: rgb(242, 99, 36);
    font-size: 24PX;
    margin-top: 20PX;
    margin-bottom: 10PX;
    height: 48PX;
    line-height: 48PX;
  }
  &-content {
    line-height: 36PX;
    font-size: 18PX;
  }
  &-form {
    &-icon-list {
      padding: 40PX 20PX;
      div {
        border-radius: 50%;
        height: 40PX;
        width: 40PX;
        cursor: pointer;
      }
    }
    &-item {
      margin-bottom: 10PX;
      padding: 0 18PX;
      border-bottom: 3PX solid #fff !important;
      &-sumbit {
        background-color: #275267;
        width: 50%;
        border-bottom: 3PX solid #fff;
        -webkit-appearance: button;
        cursor: pointer;
        font-size: 14PX;
      }
      input {
        background: transparent;
        border-radius: 0PX;
        border: hidden !important;
        color: #fff;
        -webkit-writing-mode: horizontal-tb !important;
        outline: none;
        height: 63PX;
        line-height: 63PX;
        font-size: 18PX;
        width: 100%;
      }
      textarea {
        background: transparent;
        border-radius: 0PX;
        border: hidden !important;
        color: #fff;
        -webkit-writing-mode: horizontal-tb !important;
        outline: none;
        line-height: 63PX;
        font-size: 18PX;
        width: 100%;
      }
    }
  }
}

.features-title {
  position: relative;
  font-size: 39PX;
  font-weight: bold;
  color: #000;
  font: 500 40PX/150PX "Fira Sans", sans-serif;
  @include handleFontSize($features_title_font_size);
  margin: 0;
}
.features-bottom {
  position: absolute;
  width: 100%;
  bottom: 45PX;
  color: #000;
  font: 200 35PX/150PX "Fira Sans", sans-serif;
  line-height: 10PX;
}
.checktop {
  position: absolute;
  bottom: 0;
  cursor: pointer;
  left: 50%;
  transform: translate(-50%,-0);
}
.home-footer {
  position: relative;
  padding: 40PX 0;
  background: #333333;
  &-div {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      margin-bottom: 20PX;
    }
    p {
      color: #fff;
      @include handleFontSize($home_footer_font_size);

      margin-bottom: 10PX;
    }
  }
}
</style>