<template>
	<div style="background: #FFFFFF;height: 100vh;">
		<van-nav-bar fixed @click-left="$router.back(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  </template>
		</van-nav-bar>
		<div class="content">
			<div style="padding:40px 0 20px 0;text-align: center;font-size: 20px;">{{$t('personalCenter.Changepassword')}}</div>
			<div style="display: flex;justify-content: space-around; align-items: center;">
				<div style="font-size: 12px;">{{$t('PCLMoblieInputVC_InputFieldPlaceholder')}}：{{country+' '+mobile}}</div>
				<van-button size="small" plain hairline :disabled="smsBtnDisabled" @click="sendSms()" color="#F78E1E">{{ btntxt }}</van-button>
			</div>
			
			<van-form @submit="customersetpay()" :show-error="false">
			<div style="margin: 20px 10px;">
					<van-field 
						v-model="verifycode"
						:placeholder="$t('personalCenter.pleaseUsername')" 
						:rules="[{ required: true, message: $t('personalCenter.pleaseUsername') }]" 
						border 
						/>
					<van-field 
						v-model="passwd" 
						type="password" 
						:placeholder="$t('personalCenter.pleasePayword')" 
						maxlength="6" 
						:rules="[{ required: true, message: $t('personalCenter.pleasePayword')},{ pattern, message: $t('personalCenter.pleasePayword')}]" 
						:formatter="formatter" 
						border 
						style="margin: 10px 0;" 
						/>
					<van-field 
						v-model="passwdCope" 
						type="password" 
						:placeholder="$t('personalCenter.pleasePaywordagain')" 
						maxlength="6" 
						:rules="[{ required: true, message: $t('personalCenter.pleasePaywordagain')},{ pattern, message: $t('personalCenter.pleasePaywordagain')}]" 
						:formatter="formatter" 
						border 
						/>
				
			</div>
			<div style="width: 80%;margin: 20px auto;">
				<van-button size="small" color="#F78E1E" style="width: 100%;" native-type="submit">{{$t('Common_Confirm')}}</van-button>
			</div>
			</van-form>
		</div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api,personalCenter,appService } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				country:'',
				mobile:'',
				btntxt: this.$t('Get_Code'),
				smsBtnDisabled: false,
				time:0,
				verifycode:'',
				passwd:'',
				passwdCope:'',
				pattern: /\d{6}/,
			}
		},
		methods: {
			customersetpay(values){
				if(this.passwdCope!=this.passwd){
				 this.$Toast(this.$t('personalCenter.differentText'))
				 return
				}
				personalCenter.customersetpaypasswd({
					passwd:this.passwd,
					verifycode:this.verifycode
				})
				.then(res => {
						if(res.code == 0) {
							 this.$router.push('/withdraw')
						}
					}).catch(() => {})
			},
			formatter(value){
				return value.replace(/\D/g, '');
			},
			sendSms(){
		      appService.sendSms({country: this.country, mobile: this.mobile}).then(res => {
		        if(res.code == '0'){
		          this.time = 60
		          this.timer()
		        }
		      }).catch(e => {
		    
		      })
		    },
			//验证码的倒计时
		    timer() {
		      if (this.time > 0) {
		        this.time--;
		        this.btntxt = this.$t('ResendSms') +" ("+ this.time + " s)";
		        
		        setTimeout(this.timer, 1000);
		      } else {
		        this.time = 0;
		        this.btntxt = this.$t('Get_Code');
		      }
		    },
		},
		created() {
			let userDataStr = cookieGet("user")
			if(userDataStr){
			      let userInfo = JSON.parse(userDataStr)
			      this.country = userInfo.country
			      this.mobile=userInfo.username
			    }
		},
	}
</script>

<style scoped>
	.content{
		margin-top: 40px;
	}
	.van-field--error .van-field__control, .van-field--error .van-field__control::placeholder{
		color: #323233 !important;
	}
</style>