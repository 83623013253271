<template>
	<div class="loadingImage" :style="{ backgroundImage: isload ? null : `url('${src}')` }">
		<img v-if="isload" style="display:none;" @load="endLoad" :src="src" />
	</div>
</template>

<script>
	export default {
		props: ['src'],
		data() {
			return {
				isload: true,
			};
		},
		methods: {
			endLoad() {
				this.isload = false;
			},
		},
	};
</script>

<style>
	.loadingImage {
		overflow: hidden;
		background-image: url('../../static/images/loadimg.jpg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
</style>
