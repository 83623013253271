<template>
	<div>
		<div class="share_top">
			<img src="../../assets/share_bg.jpg" width="100%" />
			<div class="share_top_info">
				<div class="share_top_info_user">{{user.usernamecode}}<span style="margin-left: 10px;">{{$t('personalCenter.gavetip')}}</span></div>
				<div style="margin-top: 10px;margin-left: 15%;">{{productInfo.productName}}</div>
				<div class="share_top_info_coupon">
					<span style="font-size: 35px;font-weight: 800;">฿{{productInfo.amount |Calculat}}</span> {{$t('personalCenter.Coupon')}}
				</div>
				<div class="share_top_info_buttom" @click="getCoupon()">
					Get It Now
				</div>
				<div class="share_top_info_time">{{productInfo.startDate}} - {{productInfo.endDate}}</div>
				<van-card @click="$router.push({path:'/details',query:{productItemId:productInfo.productItemId}})" currency="฿" style="background: #FFFFFF; height: auto;width: 90%;margin:0 auto;margin-top: 15%;">
					<template #thumb>
						<img v-if="productInfo.imageobjcet" :src="productInfo.imageobjcet.imageurl" width="100%" />
					</template>
					<template #title>
						<div style="font-size: 14px;">{{productInfo.productName}}</div>
						<span style="font-size: 12px;width: auto;padding: 2px;">{{productInfo.productItemName}}</span>
					</template>
					<template #tag>
							<div class="pic-top-left-tag">
								<div class="pic-top-left-tag-text">{{$t('personalCenter.Coupon')}}</div>
								<div class="pic-top-left-tag-Amount">฿{{productInfo.amount |Calculat}}</div>
								<img src="../../assets/Couponlabel.png" />
							</div>
						</template>
					<template #price>
						<div style="color: #F78E1E; font-size: 14px; margin: 5px 0;">{{$t('personalCenter.Discountprice')}}：฿{{productInfo.price-productInfo.amount |Calculat}}</div>
						<div style="color: #F78E1E; font-size: 14px;">฿{{productInfo.price|Calculat}}<span style="color:#BABABA;font-size: 14px;text-decoration:line-through;margin-left: 10px;">฿{{productInfo.price_origin|Calculat}}</span></div>
					</template>
				</van-card>
				<van-divider
				  :style="{ color: '#FFFFFF', borderColor: '#FFFFFF', padding: '0 16px' }"
				>
				  MORE
				</van-divider>
				<p style="text-align: center;color: #FFFFFF;font-size: 12px;">Rabbit Thailand-good life not expensive</p>
				<div style="background: #FFFFFF; height: auto;width:100%;margin:10% auto 0;background: #d61011;padding-bottom: 50px;">
				<div v-for="(item,index) in productInfo.recommend" @click="gotoDetails(item.productItemId)" :key="index"  style="width: 90%;margin: 0 auto;">
					<van-card currency="฿" style="background: #FFFFFF;margin-top: 10px; height: auto;">
						<template #thumb>
							<img :src="item.imageobjcet.imageurl" width="95px" height="95px" />
						</template>
						<template #tag>
							<div class="pic-top-left-tag">
								<div class="pic-top-left-tag-text">{{$t('personalCenter.Coupon')}}</div>
								<div class="pic-top-left-tag-Amount">฿{{item.amount}}</div>
								<img src="../../assets/Couponlabel.png" />
							</div>
						</template>
						<template #title>
							<div style="font-size: 14px;">{{item.productName}}</div>
							<span style="font-size: 12px;width: auto;padding: 2px;margin: 5px;">{{item.productItemName}}</span>
						</template>
						<template #price>
							<div style="color: #F78E1E; font-size: 14px; margin: 5px 0;">{{$t('personalCenter.Discountprice')}}：฿{{item.price-item.amount |Calculat}}</div>
							<div style="color: #F78E1E; font-size: 14px;">
								฿{{item.price|Calculat}}
								<span style="color:#BABABA;font-size: 14px;text-decoration:line-through;margin-left:px;">
									฿{{item.price_origin|Calculat}}
								</span>
								
							</div>
							<div class="share_top_info_buttomcope" @click.stop="recommendgetCoupon(item)">
								Get It Now
							</div>
						</template>
					</van-card>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, commission_api, personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	import updateTdk from "../../utils/tdk.js"
	import Vue from 'vue';
	import { Dialog, Toast } from 'vant'
	import mixinJumpModule from '@/mixins/jump-module'

	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		mixins: [ mixinJumpModule ],
		components: {},
		computed: {},
		data() {
			return {
				userPic: '',
				user: {},
				productInfo: {},
				schemesUrl: 'akuya://fanslink.vip/buyfree',
				appstoreUrl: process.env.config.VUE_APP_IOS_URL,
				googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
				localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
				currenturl: '',
			}
		},
		methods: {
			gotoShopping(productItemId) {
				if (!productItemId) {
					productItemId = this.productInfo.productItemId
				}
				let params = '?jumptype=productitem&param1=' + productItemId
				if ('ios' == this.parseDeviceType()) {
					params = '?jumptype=PRODUCTITEM&param1=' + productItemId
				}

				this.jumpToAppOrStore(params)
			},
			openShopping() {
				let params = '?jumptype=URLWITHLOGIN&param1=' + this.currenturl
				this.jumpToAppOrStore(params, true)

				let self = this
				window.setTimeout(function() {
					self.productItem()
				}, 1000)
			},
			appgetCoupon() {
				this.$axios({
						method: 'post',
						url: commission_api + '/v1/commission/productItem/getCoupon',
						data: {
							code: this.$route.query.code,
							couponTemplateId: this.productInfo.couponTemplateId,
							productItemId: this.productInfo.productItemId,
							token: this.$route.query.trace
						}
					})
					.then(res => {
						if(res.code == 0) {
							Toast({
								message:this.$t('personalCenter.receivedSuccessfuily'),
								duration:5000
							})
							this.gotoShopping()
						}
					}).catch(() => {})
			},
			recommendgetCoupon(item){
				if(this.$route.query.trace) {
					this.$axios({
						method: 'post',
						url: commission_api + '/v1/commission/productItem/getCoupon',
						data: {
							code: this.$route.query.code,
							couponTemplateId: item.couponTemplateId,
							productItemId: item.productItemId,
							token: this.$route.query.trace
						}
					})
					.then(res => {
						if(res.code == 0) {
							Toast(this.$t('personalCenter.receivedSuccessfuily'))
						}
					}).catch(() => {})
				} else {
					if(cookieGet("token")) {
						this.$axios({
						method: 'post',
						url: commission_api + '/v1/commission/productItem/getCoupon',
						data: {
							code: this.$route.query.code,
							couponTemplateId: item.couponTemplateId,
							productItemId: item.productItemId,
						}
						})
						.then(res => {
							if(res.code == 0) {
								Toast(this.$t('personalCenter.receivedSuccessfuily'))
							}
						}).catch(() => {})
					} else {
						this.$router.push({
							path: '/login',
							query: {
								couponCode: this.$route.query.code
							}
						})
					}
				}
				
				
			},
			h5getCoupon() {
				this.$axios({
						method: 'post',
						url: commission_api + '/v1/commission/productItem/getCoupon',
						data: {
							code: this.$route.query.code,
							couponTemplateId: this.productInfo.couponTemplateId,
							productItemId: this.productInfo.productItemId,
						}
					})
					.then(res => {
						if(res.code == 0) {
							Dialog.alert({
								message: this.$t('personalCenter.receivedSuccessfuily'),
								showCancelButton: true,
								confirmButtonText: this.$t('Open_app'),
								cancelButtonText: this.$t('Good_Buy_Now')
							}).then(() => {
								this.gotoShopping()
							}).catch(() => {
								this.$router.push({
									path: '/details',
									query: {
										productItemId: this.productInfo.productItemId
									}
								})
							})
						}
					}).catch(() => {})
			},
			gotoDetails(id){
				if(this.$route.query.trace) {
					this.gotoShopping(id)
				} else {
					this.$router.push({
						path: '/details',
						query: {
							productItemId: id
						}
					})
				}
			},
			getCoupon() {
				if(this.$route.query.trace) {
					this.appgetCoupon()
				} else {
					if(cookieGet("token")) {
						this.h5getCoupon()
					} else {
						this.$router.push({
							path: '/login',
							query: {
								couponCode: this.$route.query.code
							}
						})
					}
				}
			},
			productItem() {
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/commission/shareCode/query',
						params: {
							code: this.$route.query.code,
						}
					})
					.then(res => {
						if(res.code == 0) {
							this.productInfo = res.data
						}
					}).catch(() => {})
			},
		},
		created() {
			if(this.$route.query.path == 'APP') {
				//生产：https://m.rabbitselection.com/    测试：https://h5-training.rabbitselection.com
				this.currenturl = 'https://m.rabbitselection.com/receiveAward?code=' + this.$route.query.code
				console.log('currenturl', this.currenturl)
				this.openShopping()
			} else {
				this.productItem()
			}
			let userDataStr = cookieGet("token")
			if(userDataStr) {
				this.userPic = cookieGet("userpic")
				this.user = JSON.parse(cookieGet("user"))
				let a = this.user.username
				this.user.usernamecode = a.replace(/^(\d{4})\d{4}(\d{4})$/, '$1****$2')
			}
		},
	}
</script>

<style scoped="scoped">
	.share_top {
		position: relative;
	}
	
	.share_top_info {
		position: absolute;
		top: 22%;
		width: 100%;
	}
	
	.share_top_info_user {
		color: #101010;
		font-size: 10px;
		margin-left: 15%;
	}
	
	.share_top_info_time {
		font-size: 12px;
		text-align: center;
	}
	
	.share_top_info_coupon {
		color: #F78E1E;
		font-size: 23px;
		margin: 10px 0;
		text-align: center;
	}
	
	.share_top_info_buttom {
		text-align: center;
		margin: 0 auto;
		font-size: 14px;
		color: #FFFFFF;
		background: red;
		width: 200px;
		padding: 5px 0;
		border-radius: 20px;
		margin-bottom: 15px;
	}
	.share_top_info_buttomcope{
		text-align: center;
		margin: 0 auto;
		font-size:2px;
		color: #FFFFFF;
		background: red;
		width: auto;
		padding: 2px 5px;
		border-radius: 20px;
	}
	.pic-top-left-tag {
		position: relative;
		color: #FFFFFF;
		font-size:6px;
	}
	
	.pic-top-left-tag img {
		width: 45px;
	}
	
	.pic-top-left-tag .pic-top-left-tag-text {
		position: absolute;
		left: 2px;
		top: 2px;
	}
	
	.pic-top-left-tag .pic-top-left-tag-Amount {
		position: absolute;
		left: 2px;
		top: 15px;
	}
	
</style>
<style>
	 .van-card__thumb img{
		border-radius: initial !important;
	}
	.van-card__tag{
		left: -10px !important;
		top: 0px !important;
	}
</style>