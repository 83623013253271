<!-- 此页面必须在url传支付类型payType -->

<template>
  <div>
    <van-nav-bar fixed
      title="Fail payment"
    />
    <div style="padding:70px 10px 0 10px;">
      <div style="height:300px;background-color: #ffffff;border-radius:10px;">

        <div v-if="confirm == true">
          <div style="padding-top:30px;text-align:center;">
            <img style="width:76px;" src="../../../static/images/pay_false.png" />
          </div>
          <div style="text-align:center;padding-top:15px;color:#F78E1E">
            {{ successText }}
          </div>

          <div style="text-align:center;padding-top:15px;" v-if="orderId != ''">
            Order Number: {{ orderId }}
          </div>

          <div style="text-align:center;padding-top:15px;" v-if="traceNo != ''">
            Trace Number: {{ traceNo }}
          </div>
        </div>

        <div style="padding-top:30px;">
          <div style="width:50%;float: left;display: inline;text-align: center;">
            <van-button size="small" round type="danger" @click="goMyOrderList()">
              {{$t('PayCompleteVC_btnTitle_ViewOrder')}}
            </van-button>
          </div>

          <div style="width:50%;float: right;display: inline;text-align: center;">
            <van-button size="small" round type="danger" @click="goHome()">
              {{$t('Continue_Shopping')}}
            </van-button>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService } from '@/common/api'

export default {
  name: 'checkPay',
  components: {
  },
  computed: {
  },
  data () {
    return {
      payType: null,
      successText: '',
      confirm: false,
      status: '',
      desc: '',
      orderId: '',
      traceNo: ''
    }
  },
  methods: {
    goMyOrderList(){
      this.$router.push({path: '/orderList'})
    },
    goHome(){
      this.$router.push({path: '/'})
    },
  },
  created () {
  },
  mounted(){
    //从url获取支付方式,cod和online显示的文字不一样
    this.payType = this.$route.query.payType

    this.status = this.$route.query.status
    this.desc = this.$route.query.desc
    this.orderId = this.$route.query.orderId
    this.traceNo = this.$route.query.traceNo

    if(this.payType){
      if(this.payType == "COD"){
        this.confirm = true
        this.successText = this.$t('PayCompleteVC_OrderSuccess_COD')
      }else if(this.payType = "ONLINE"){
        this.confirm = true
        // this.successText = this.$t('PayCompleteVC_OrderSuccess')
        this.successText = this.desc
      }
    }
  }
}
</script>
