<template>
	<van-card currency="฿" style="background: #FFFFFF;margin-bottom: 10px; height: auto;width: 100%;" @click="jumpHandler">
		<template #thumb>
			<div class="goods-list__block__img">
				<img :src="parsedProduct.img" />
				<div v-if="parsedProduct.discount < 1" class="goods-list__block__badge">{{ parsedProduct.discount | discountTran }}%</div>
			</div>
		</template>
		<template #title>
			<span style="font-size: 14px;">{{ parsedProduct.name }}</span>
		</template>
		<template #price-top>
			<div style="color:#CCCCCC;font-size: 12px;">[{{ parsedProduct.name }}]</div>
		</template>
		<template #price>
			<div class="goods-list__block__details__price">
				<span>฿{{ parsedProduct.price }}</span>
				<span v-if="parsedProduct.price != parsedProduct.orgPrice">฿{{ parsedProduct.orgPrice | Calculat }}</span>
			</div>
		</template>
	</van-card>
</template>

<script>
	import mixinJumpModule from '@/mixins/jump-module';
	import { cookieGet } from '@/common/cookie';
	import { CalculatAmount } from '@/common/calculation';

	/**
	 * 兼容flash接口
	 */
	function compatFlash(untyped) {
		if (typeof untyped.productid !== 'string') {
			return null;
		}

		return {
			img: untyped.imageobjcet.imageurl,
			discount: untyped.price / untyped.price_origin,
			name: untyped.itemname,
			price: untyped.price,
			orgPrice: untyped.price_origin,
			jump: untyped.productid,
			handle: 'productDetail',
		};
	}

	/**
	 * 兼容/homepage
	 */
	function compatHomepage(untyped) {
		if (!(untyped.jump && untyped.ui)) {
			return null;
		}

		return {
			img: untyped.ui.param1.publishPictureUrl,
			discount: untyped.ui.param1.discountRate,
			name: untyped.ui.param1.name,
			price: untyped.ui.param1.price,
			orgPrice: untyped.ui.param1.originalPrice,
			jump: untyped.jump,
			handle: 'jumpHandle',
		};
	}

	/**
	 * 兼容/searchproductList & /catalogProduct
	 */
	function compatSearchproduct(untyped) {
		if (typeof untyped.productItemId !== 'string') {
			return null;
		}

		return {
			img: untyped.imageobjcet.imageurl,
			discount: untyped.price / untyped.price_origin,
			name: untyped.productName,
			price: untyped.price,
			orgPrice: untyped.price_origin,
			jump: untyped.productItemId,
			handle: 'productDetail',
		};
	}

	/**本组件统一了需要显示同一UI的不同组件,这里为兼容补丁,意图将不同的数据结构做区分并统一转换 */
	const capability = [compatHomepage, compatSearchproduct, compatFlash];

	export default {
		mixins: [mixinJumpModule],
		props: {
			product: {
				type: Object,
				default: Object,
			},
		},
		data() {
			return {
				/**转换过的product结构,为一层对象 */
				parsedProduct: {},
				language: '',
			};
		},
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount);
			},
			discountTran(discount) {
				return -((1 - discount) * 100).toFixed(0);
			},
		},
		created() {
			for (let fn of capability) {
				const temp = fn(this.product);

				if (temp) {
					this.parsedProduct = temp;
					break;
				}
			}

			this.language = cookieGet('language');

			// this.productquerybrandid()
		},
		methods: {
			jumpHandler() {
				this[this.parsedProduct.handle](this.parsedProduct.jump);
			},
			productDetail(productItemId) {
				this.$router.push({ path: '/details', query: { productItemId: productItemId } });
			},
		},
	};
</script>

<style scoped>
	::v-deep .van-card__thumb {
		flex-shrink: 0;
        width: unset;
        height: unset;
	}
	.goods-list__block__img img {
		width: 114px;
		height: 114px;
		left: 0;
		top: 0;
	}
	.goods-list__block__badge {
		position: absolute;
		right: 1em;
		top: 1em;
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		font-size: 12px;
		text-align: center;
		padding: 0.1em;
		border-radius: 50%;
		background-color: #f78e1e;
		color: white;
		text-align: center;
	}
	.goods-list__block__details__price span:nth-child(1) {
		font-size: 14px;
		padding: 10px 10px 5px 0;
		color: #f78e1e;
	}
	.goods-list__block__details__price span:nth-child(2) {
		color: #cccccc;
		font-size: 12px;
		margin-left: 5px;
		text-decoration: line-through;
	}
</style>
