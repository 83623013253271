<template>
  <div>
    <van-nav-bar fixed
      :title="$t('PCLMoblieInputVC_Title')"
    />

    <div>
      <div style="padding-top:70px;text-align:center; background: #FFFFFF;">
        <img width="150px" src="../../../static/images/Rabbit_logo.png" />

      </div>

      <div style="padding-top:30px;width:100%;">
        <van-field
          readonly
          label-width="150px"
          name="picker"
          :value="chooseValue"
          :label="$t('Country')"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
          	:confirm-button-text="$t('confirm')"
            :cancel-button-text="$t('cancel')"
            show-toolbar
            :columns="columns"
            :default-index="1"
            value-key="dasdas"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </div>

      <div style="padding-top:10px;width:100%;">
        <van-field label-width="150px" :autofocus="true" clearable v-model="mobile" :placeholder="$t('PCLMoblieInputVC_desc')" :label="$t('PCLMoblieInputVC_InputFieldPlaceholder')">
        </van-field>
      </div>

    </div>

    <div style="padding-top:25px;text-align:center;">
      <van-button  slot="button" :disabled="smsBtnDisabled" @click="sendSms"  color="#F78E1E">{{btntxt}}</van-button>
    </div>


  </div>
  
</template>

<script>

import { productService, appService} from '@/common/api'
import thailandImg from '../../assets/thailand.png'
import chinaImg from '../../assets/china.png'
import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'
export default {
  name: 'login',
  components: {
  },
  computed: {
  },
  data() {
    return {
      chooseValue: '+66',
      columns: [
        '<img style="width:15px" src="'+thailandImg+'"/> +66',
        '<img style="width:15px" src="'+chinaImg+'"/> +86'
      ],
      showPicker: false,

      showLoading: true,

      mobile: "",
			btntxt: this.$t('PCLMoblieInputVC_sendCodeBtnTitle'),
			smsBtnDisabled: false,
      time: 0,
      submitOrder: 'n'
    };
  },
  methods: {
    //发送短信,目前写死中国号码规则,后面需要更换泰国号码规则
    sendSms(){
      var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      // if (this.mobile == '') {
      //   this.$Toast.fail("手机号不能为空")
      // } else if (!reg.test(this.mobile)) {
      //   this.$Toast.fail("手机号格式不正确")
      // } else {

        //测试环境 默认验证码0527
        // this.time = 60
        // this.smsBtnDisabled = true
        // // // this.timer()
        // this.$router.push({path: '/smscode', query:{ country:this.chooseValue, username:this.mobile, productItemId: this.$route.query.productItemId, total: this.$route.query.total}})

        //正式环境
        appService.sendSms({country: this.chooseValue, mobile: this.mobile}).then(res => {
          if(res.code == '0'){
            this.time = 60
            this.smsBtnDisabled = true
            this.timer()
            this.$router.push({path: '/smscode', query:{ country:this.chooseValue, username:this.mobile, productItemId: this.$route.query.productItemId, total: this.$route.query.total, submitOrder: this.submitOrder,couponCode:this.$route.query.couponCode}})
          }

        }).catch(e => {
      
        })

      // }
    },

    //处理国旗字符串问题
    onConfirm(value, index) {
      if(index == 0){
        this.chooseValue = '+66';
      }else if(index == 1){
        this.chooseValue = '+86';
      }
      this.showPicker = false;
    },
    
    //验证码的倒计时(这边应该用不上已经移到smscode)
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.$t('ResendSms') +"("+ this.time + " s)";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = this.$t('Get_Code');
        this.smsBtnDisabled = false;
      }
    },
  },
  created () {
  },
  mounted() {
    let submitOrderStatus = this.$route.query.submitOrder
    let code = this.$route.query.code
    if(code){
      console.log(code) 
      cookieSet("code", code)
    }
    if(submitOrderStatus){
      this.submitOrder = submitOrderStatus
    }
  }
}
</script>


<style scoped>
.van-popup--bottom{
  left: initial !important;
}

.van-popup {
  max-width: 540px;
}

.van-number-keyboard{
  z-index:3000
}
/* .van-password-input__security{
  padding-top: 100px;
} */
</style>
