import request from '@/plugin/axios'
import { cookieGet } from '@/common/cookie'



export const upload_pictureofsystem =  process.env.VUE_APP_API+'/v1/upload/pictureofsystem'

export const upload_videoofsystem =  process.env.VUE_APP_API+'/v1/upload/videoofsystem'

export const upload_apk = process.env.VUE_APP_API+'/v1/upload/apk'

export const ocdev_api = 'https://operation.rabbitselection.com:7777'   //开发地址 https://ocdev.akuya.vip:7777  生产地址 https://operation.rabbitselection.com:7777
export const commission_api = 'http://161.117.87.2:7777'   //开发地址 http://161.117.87.2:7777  生产地址 https://operation.rabbitselection.com

/**
 * @description [ product ] 商品管理
 */
export const productService = {
  /**
   * 获取单品详情
   */
  getProductItemInfo (params) {
    return request({
      url: '/v1/product/query',
      params
    })
  },
   /**
   * 根据品牌id查询单品
   */
  productquerybrandid (params) {
    return request({
      url: '/v1/product/query/brandid',
      params
    })
  },
  /**
   * 获取单前单品所有sku
   */
  getProductItemSkuInfo (params) {
    return request({
      url: '/v1/product/queryproductitemsinproduct',
      params
    })
  },
   /**
   * 搜索单品(返回最便宜的单品信息
   */
  productsearchnew (params) {
    return request({
      url: '/v2/product/search/new',
      params
    })
  },
   /**
   * 搜索热词
   */
  productsearchkeywords (params) {
    return request({
      url: '/v1/product/searchkeywords',
      params
    })
  },
}

/**
 * @description [ app ] app接口
 */
export const appService = {
  
  /**
   * POST /v1/customer/sendsms 注册/登录获取注册短信验证码
   */
  sendSms (data) {
    return request({
      url: '/v1/customer/sendsms',
      method: 'post',
      data
    })
  },
  /**
   * POST /v1/customer/sendsms 注册/登录重新获取注册短信验证码
   */
  sendsmsagain (data) {
    return request({
      url: '/v1/customer/sendsmsagain',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/customer/registandlogin 用户注册/登录(包含第三方登录)
   */
  registAndLogin (data) {
    return request({
      url: '/v1/customer/registandlogin',
      method: 'post',
      data
    })
  },
  /**
   * POST 查询用户logo的url。没有设置不返回image字段
   */
  customerquerylogo (data) {
    return request({
      url: '/v1/customer/querylogo',
      method: 'post',
      data
    })
  },
   /**
   * GET /v1/address/querycity 检测用户是否注册
   */
  isuserexist (params) {
    return request({
      url: '/v1/customer/isuserexist',
      params
    })
  },
}

/**
 * @description [ preorder ] preorder接口
 */
export const preorderService = {
  
  /**
   * POST /v1/preorder/createdirect 直接创建预订单。不返回地址、发票、配送、支付，只返回单品和价格
   */
  createdirect (data) {
    return request({
      url: '/v1/preorder/createdirect',
      method: 'post',
      data
    })
  },
  
  /**
   * POST /v1/preorder/create 从购物车创建预订单。不返回地址、发票、配送、支付，只返回单品和价格
   */
  create (data) {
    return request({
      url: '/v1/preorder/create',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/preorder/modify 修改预订单。物流方式/支付方式/地址变化了需要调用。替代save接口
   */
  modify (data) {
    return request({
      url: '/v1/preorder/modify',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/preorder/finish 完成预订单。同时创建订单，并返回订单信息
   */
  finish (data) {
    return request({
      url: '/v1/preorder/finish',
      method: 'post',
      data
    })
  },


  /**
   * GET /v1/preorder/couponcode预订单验证优惠码
   */
  checkCouponCode (params) {
    return request({
      url: '/v1/preorder/couponcode',
      params
    })
  },
   /**
   * GET 预订单可用/不可用优惠券
   */
  preordercouponlist (params) {
    return request({
      url: '/v1/preorder/couponlist',
      params
    })
  },
  /**
   * GET 返回用户可用的优惠券数量
   */
  couponqueryusablecount (params) {
    return request({
      url: '/v1/coupon/queryusablecount',
      params
    })
  },
  /**
   * GET 返回用户可用的优惠券列表.有排序逻辑
   */
  couponqueryusable (params) {
    return request({
      url: '/v1/coupon/queryusable',
      params
    })
  },
   /**
   * 返回用户历史的优惠券列表
   */
  couponqueryused (params) {
    return request({
      url: '/v1/coupon/queryused',
      params
    })
  },
}

/**
 * @description [ address ] address接口
 */
export const addressService = {

  /**
   * POST /v1/address/addaddress 新增地址。如果是第一个新增加的地址，设为默认地址
   */
  add (data) {
    return request({
      url: '/v1/address/addaddress',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/address/modifyaddress 修改地址信息
   */
  modify (data) {
    return request({
      url: '/v1/address/modifyaddress',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/address/removeaddress 删除地址。如果是默认地址，则剩余的第一个地址作为默认地址
   */
  remove (data) {
    return request({
      url: '/v1/address/removeaddress',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/address/setdefaultaddress 设为默认地址。取消原来的默认地址
   */
  setDefault (data) {
    return request({
      url: '/v1/address/setdefaultaddress',
      method: 'post',
      data
    })
  },
  
  /**
   * GET /v1/address/queryaddress 查询用户的地址列表
   */
  getAddressList () {
    return request({
      url: '/v1/address/queryaddress',
    })
  },

  /**
   * GET /v1/address/querycity 查询城市列表
   */
  getCityList (params) {
    return request({
      url: '/v1/address/querycity',
      params
    })
  },

  /**
   * GET /v1/address/queryprovince 查询省份列表
   */
  getProvinceList () {
    return request({
      url: '/v1/address/queryprovince',
    })
  },

  /**
   * GET /v1/address/queryregion 查询区域列表
   */
  getRegionList (params) {
    return request({
      url: '/v1/address/queryregion',
      params
    })
  },
}
/**
 * @订单支付接口
 */
export const orderPay = {
  /**
   * GET 支付方式logo列表
   */
  paylogolist (params) {
    return request({
      url: '/v1/cashier/paylogolist',
      params
    })
  },
  /**
   * GET 刷新收银台
   */
  cashierRefresh (params) {
    return request({
      url: '/v1/cashier/refresh',
      params
    })
  },
  /**
   * 订单支付
   */
  orderPay(data) {
    return request({
      url: '/v1/order/pay',
      method: 'post',
      data
    })
  },

}

/**
 * @description [ order ] 订单管理
 */
export const orderService = {
  /**
   * 获取所有订单(分页) GET /v1/order/queryall查询所有订单列表。按创建时间逆序排列
   */
  getList (params) {
    return request({
      url: '/v1/order/queryall',
      params
    })
  },

  /**
   * GET /v1/order/detail 查询订单详情
   */
  getDetail (params) {
    return request({
      url: '/v1/order/detail',
      params
    })
  },
}
/**
 * 个人中心
 */
export const personalCenter = {
  /**
   * 账户查询
   */
  walletqueryacount (params) {
    return request({
      url: '/v1/wallet/queryacount',
      params
    })
  },
   /**
   * 提现金额限制
   */
  walletlimitwithdrawal (params) {
    return request({
      url: '/v1/wallet/limitwithdrawal',
      params
    })
  },
   /**
   *GET /v1/withdrawal/query/card 查询用户提现银行卡信息
   */
  querycard (params) {
    return request({
      url: '/v1/withdrawal/query/card',
      params
    })
  },
  /**
   * 添加提现银行卡账户
   */
  addcard (params) {
    return request({
      url: '/v1/withdrawal/card/add',
      params
    })
  },
  /**
   * 申请提现
   */
  requestwithdrawal(data) {
    return request({
      url: '/v1/wallet/requestwithdrawal',
      method: 'post',
      data
    })
  },
  /**
   * 设置支付密码
   */
  customersetpaypasswd(data) {
    return request({
      url: '/v1/customer/setpaypasswd',
      method: 'post',
      data
    })
  },
  /**
   * 检查是否设置支付密码
   */
  checkpaypwdexist (params) {
    return request({
      url: '/v1/wallet/checkpaypwdexist',
      params
    })
  },
  /**
   * 查询提现记录
   */
  querywithdrawal (params) {
    return request({
      url: '/v1/wallet/querywithdrawal',
      params
    })
  },

  /**
   * 校验支付密码
   */
  checkpasswd(data) {
    return request({
      url: '/v1/customer/checkpasswd',
      method: 'post',
      data
    })
  },
   /**
   * 查询用户已提现金额
   */
  walletfinishamount(params) {
    return request({
      url: '/v1/wallet/finish/amount',
      params
    })
  },
   /**
   * 删除提现银行卡
   */
  withdrawacardlremove(data) {
    return request({
      url: '/v1/withdrawal/card/remove',
      method: 'post',
      data
    })
  },
   /**
   * 查询提现银行卡列表
   */
  withdrawalbanklist(params) {
    return request({
      url: '/v1/withdrawal/bank/list',
      params
    })
  },
}

/**
 * @description [ merchant ] 店铺服务
 */
export const merchantService = {
  /**
   * 查询店铺详细信息 GET /v1/merchant/detail
   */
  getMerchantInfo (params) {
    return request({
      url: '/v1/merchant/detail',
      params
    })
  },
   /**
   * 查询店铺商品分类，只有一级分类，不返回分类图标 GET /v1/merchant/querycatalog
   */
  getMerchantCatalog (params) {
    return request({
      url: '/v1/merchant/querycatalog',
      params
    })
  },
  /**
   * 查询店铺商品分类下的商品列表 GET /v1/merchant/catalog/product/query
   */
  getMerchantCatalogProduct (params) {
    return request({
      url: '/v1/merchant/catalog/product/query',
      params
    })
  },
}

/**
 * @description [ homepage ] 首页服务
 */
export const homepageService = {
  /**
   * 首页所有列表 GET /v4/home/list
   */
  getList (params) {
    return request({
      url: '/v4/home/list',
      params
    })
  },
   /**
   * 查询店铺商品分类，只有一级分类，不返回分类图标 GET /v1/merchant/querycatalog
   */
  getMerchantCatalog (params) {
    return request({
      url: '/v1/merchant/querycatalog',
      params
    })
  },
  /**
   * 查询店铺商品分类下的商品列表 GET /v1/merchant/catalog/product/query
   */
  getMerchantCatalogProduct (params) {
    return request({
      url: '/v1/merchant/catalog/product/query',
      params
    })
  },

  /**
   * 查询所有商品列表 GET /v1/product/all/query
   */
  getAllProduct(params){
    return request({
      url: '/v1/product/all/query',
      params
    })
  },

  /**
   * 查询分类所有商品列表 GET /v1/product/catalog/query
   */
  getCatalogProduct(params){
    return request({
      url: '/v1/product/catalog/query',
      params
    })
  }
}

/**
 * @description [ shopcart ] 购物车服务
 */
export const shopcartService = {

  /**
   * POST /v1/shoppingcarttmp/add 向购物车增加商品，适用于非购物车页面操作。(未登录)
   */
  shoppingcarttmpAdd (data) {
    return request({
      url: '/v1/shoppingcarttmp/add',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcarttmp/choose 选择/取消选择商品(未登录)
   */
  shoppingcarttmpChoose (data) {
    return request({
      url: '/v1/shoppingcarttmp/choose',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcarttmp/chooseall 选择/取消选择所有商品(未登录)
   */
  shoppingcarttmpChooseAll (data) {
    return request({
      url: '/v1/shoppingcarttmp/chooseall',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcarttmp/countminus1 购物车商品数量-1，适用于购物车页面操作。(未登录)
   */
  shoppingcarttmpCountminus1 (data) {
    return request({
      url: '/v1/shoppingcarttmp/countminus1',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcarttmp/countplus1 购物车商品数量+1，适用于购物车页面操作。(未登录)
   */
  shoppingcarttmpCountplus1 (data) {
    return request({
      url: '/v1/shoppingcarttmp/countplus1',
      method: 'post',
      data
    })
  },

  /**
   * GET /v1/shoppingcarttmp/query 购物车查询(未登录)
   */
  shoppingcarttmpQuery (params) {
    return request({
      url: '/v1/shoppingcarttmp/query',
      params
    })
  },
  
  /**
   * POST /v1/shoppingcarttmp/remove 移除购物车商品(未登录)
   */
  shoppingcarttmpRemove (data) {
    return request({
      url: '/v1/shoppingcarttmp/remove',
      method: 'post',
      data
    })
  }, 
  
  /**
  * POST /v1/shoppingcarttmp/removeall 清空购物车商品(未登录)
  */
 shoppingcarttmpRemoveAll (data) {
    return request({
      url: '/v1/shoppingcarttmp/removeall',
      method: 'post',
      data
    })
  },
  
  /**
  * POST /v1/shoppingcarttmp/removelist 移除多个购物车商品列表(未登录)
  */
 shoppingcarttmpRemoveList (data) {
    return request({
      url: '/v1/shoppingcarttmp/removelist',
      method: 'post',
      data
    })
  },


  /**
   * POST /v1/shoppingcart/add 向购物车增加商品，适用于非购物车页面操作。(已经登录)
   */
  shoppingcartAdd (data) {
    return request({
      url: '/v1/shoppingcart/add',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcart/choose 选择/取消选择商品(已经登录)
   */
  shoppingcartChoose (data) {
    return request({
      url: '/v1/shoppingcart/choose',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcart/chooseall 选择/取消选择所有商品(已经登录)
   */
  shoppingcartChooseAll (data) {
    return request({
      url: '/v1/shoppingcart/chooseall',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcart/countminus1 购物车商品数量-1，适用于购物车页面操作。(已经登录)
   */
  shoppingcartCountminus1 (data) {
    return request({
      url: '/v1/shoppingcart/countminus1',
      method: 'post',
      data
    })
  },

  /**
   * POST /v1/shoppingcart/countplus1 购物车商品数量+1，适用于购物车页面操作。(已经登录)
   */
  shoppingcartCountplus1 (data) {
    return request({
      url: '/v1/shoppingcart/countplus1',
      method: 'post',
      data
    })
  },

  /**
   * GET /v1/shoppingcart/query 购物车查询(已经登录)
   */
  shoppingcartQuery () {
    return request({
      url: '/v1/shoppingcart/query'
    })
  },
  
  /**
   * POST /v1/shoppingcart/remove 移除购物车商品(已经登录)
   */
  shoppingcartRemove (data) {
    return request({
      url: '/v1/shoppingcart/remove',
      method: 'post',
      data
    })
  }, 
  
  /**
  * POST /v1/shoppingcart/removeall 清空购物车商品(已经登录)
  */
 shoppingcartRemoveAll (data) {
    return request({
      url: '/v1/shoppingcart/removeall',
      method: 'post',
      data
    })
  },
  
  /**
  * POST /v1/shoppingcart/removelist 移除多个购物车商品列表(已经登录)
  */
 shoppingcartRemoveList (data) {
    return request({
      url: '/v1/shoppingcart/removelist',
      method: 'post',
      data
    })
  },
  
  /**
  * POST /v1/shoppingcart/merge 将临时购物车商品合并到用户购物车(登录后执行)
  */
 shoppingcartMerge (data) {
    return request({
      url: '/v1/shoppingcart/merge',
      method: 'post',
      data
    })
 },
}
/**
 * 直播LIVE
 */
export const liveManage = {

  livedetail (params) {
    return request({
      url: '/v1/live/detail',
      params
    })
  },
}