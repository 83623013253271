<template>
	<div>
		<div v-if="wechatNoShow" style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index:99999999999 !important">
	      <div style="float:right;position: relative;top: 20px;right: 30px;">
	        <img style="width:110px;height:110px;" src="../../../static/images/wechat_arrows.png" />
	      </div>
	      <div style="position: relative;top: 50px;color:#FFF;text-align:center;font-size:20px;font-weight:bold;width:100%;height:40px;clear: both;">
	        Please select "Open in browser"
	      </div>
	    </div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, commission_api, personalCenter } from '@/common/api'
	import updateTdk from "../../utils/tdk.js"
	import Vue from 'vue';
	import { Dialog } from 'vant';
	import mixinJumpModule from '@/mixins/jump-module'

	export default {
		name: 'Rabbitehome',
		mixins: [ mixinJumpModule ],
		components: {},
		computed: {},
		data() {
			return {
				schemesUrl: 'akuya://fanslink.vip/buyfree',
      			appstoreUrl:'https://apps.apple.com/th/app/pando-smart-home/id1487413430',
      			googlepalyUrl: 'https://play.google.com/store/apps/details?id=com.pandoandroidpack.smart',
      			localDownUrl: 'https://play.google.com/store/apps/details?id=com.pandoandroidpack.smart' + new Date(),
      			wechatNoShow:true
			}
		},
		methods: {
			gotoShopping(){
				this.jumpToAppOrStore('')
			},
		},
		created() {
			this.gotoShopping()
		},
	}
</script>
<style>
</style>