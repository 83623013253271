//千位符分割
export const ToThousandsNoZero = function(number){
  return number ? ( (isNaN(parseFloat(number.toString().replace(/,/g, ""))) ? 1 : parseFloat(number.toString().replace(/,/g, "")))).toFixed(2).toString().replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ","
  }) : ""
}

//自动拼接.00
export const CalculatAmount = function(number){
  if(isNaN(parseFloat(number)) || parseFloat(number) == 0){
    return "0.00"
  }else{
    return ToThousandsNoZero(number)
  }
}

