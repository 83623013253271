const t = {}

t.loading = 'Loading...'

t.brand = {}
t.brand.lg = 'Rabbit'
t.brand.mini = 'Rabbit'

t.Chat = "Chat"
t.Good_Buy_Now = "Buy now"
t.Add_To_Cart = "Add to Cart";
t.Open_app = "Open App"
t.Good_Temporarily_Out = "Out of stock"
t.Good_Item_Detail = "Product Details";
t.Blog = 'Blog'
t.About_Us = "About Us";
t.Contact_Us = "Contact Us";

t.Mine_Login = "Please login";
t.Common_Login = "Log in / Register";
t.Out_Login = "Sign Out";

t.Common_Logout = "Are you sure you want to log out?";
t.Common_Confirm = "Confirm";
t.Common_Cancel = "Cancel";

t.PCLMoblieInputVC_Title = "Login";

t.PCLMoblieInputVC_desc = "Please enter your phone number";
t.PCLMoblieInputVC_InputFieldPlaceholder = "Phone number";
t.PCLMoblieInputVC_sendCodeBtnTitle = "Request SMS code";

t.PCLSMSInputVC_againBtnNoGetSMSTitle = "I didn't get the code";

t.Order_Buyer_Comments = "Buyer note:";
t.Order_Optional = "Optional";
t.Order_Confirm_Order = "Confirm order";
t.Order_inputMessage = "Leave additional message to Seller/Rabbit";
t.Order_Out_Of_Stock = "（Out of stock）";
t.Order_In_Stock = "In stock {0} items";

t.Mine_My_Address = "My Address";
t.Common_Default_address = "Default address";
t.Order_Add_Address = "Add address or Please create a delivery address";
t.Mine_Address_New = "Add new address";
t.Mine_Save_Address = "Save address";
t.Mine_Edit_Address =  "Edit shipping address";
t.Mine_Shipping_Address = "Add shipping address";

t.Mine_Address_Reciever =  "Reciever";
t.Mine_Address_Phone =  "Phone";
t.Mine_Address_Area =  "Area";
t.Mine_Address_Detail =  "Detailed Address";
t.Mine_Address_Option =  "-Please Select-";
t.Mine_Address_Input_Reciever =  "Please input the reciever name";
t.Root_Login_Phone_Hint_Cut = "Please input phone";
t.Mine_Address_Input_Address =  "Please input the address detail";
t.Mine_Address_Input_PostCode =  "Please enter a zip Code";

t.Common_Province = "Province";
t.Common_City = "District";
t.Common_PostCode = "Postal Code";

t.Order_Choose_Province =  "Please select a province";
t.Order_Choose_City =  "Please select a district";


t.Order_Submit = "Submit order";

t.Mine_Order_Payment = "To Pay";
t.Mine_Order_Shipping = "To Ship";
t.Mine_Order_Complete = "Completed";
t.Mine_Order_Cancel = "Cancelled";
t.Mine_Order_REFUNDING = "Refunding";
t.Mine_Order_REFUNDED = "Refunded";


//地址页面
t.AddAddressVC_WarmPrompt_Tips = "Tips:";
t.AddAddressVC_WarmPrompt_content = "The delivery man will deliver the goods according to the provided address information.\nTo facilitate and increase efficiency of delivering your products, please be sure to provide an accurate address, I wish you a happy shopping!";
t.AddAddressVC_setupDefaultAddress = "Set as Default address";
t.AddAddressVC_invalidPhone = 'Invalid Phone!'

t.Order_Add_Addresses = "Select delivery address";

t.Order_Total_goodNum_1 = "Total";
t.Order_Total_goodNum_2 = "commodities";
t.Order_Total_TotalTitle = "Total :";

t.Order_Total_Amount_1 = "Total ";
t.Order_Total_Amount_2 = "Item,Items total price: ";

t.Order_Detail = "Order Detail";


t.Mine_My_Order = "My Orders";

t.Root_Cart = "Cart";
t.Root_Cart_Navi = "Shopping Cart";
t.Cart_Empty = "Your cart is empty.";
t.Cart_Go = "Go Shopping";
t.Cart_Add = "Add to cart";
t.Cart_Settlement = "Check Out";
t.Good_Select_All = "All";
t.Good_Maximum = "Sorry, This product does not have enough stock.";
t.Common_Complete = "Complete";
t.Common_Delete = "Delete";
t.Good_Delete_Item = "Do you want to remove this product?";








t.PayCompleteVC_NaviTilte = "Successful payment";
t.PayCompleteVC_OrderSuccess = "Order paid successfully!";
t.PayCompleteVC_OrderSuccess_COD = "Order submission successful!";
t.PayCompleteVC_btnTitle_ViewOrder = "View Order";
t.PayCompleteVC_btnTitle_Keepwalking = "Keep walking";

t.Order_Detail_paymentTime = "remaining payment time:";
t.Order_Payment = "Payment";
t.Order_Pay_At_The_Store_Amount = "Pay at the store: ";
t.Order_Pay_OnDelivery_Amount = "Total Payable:";// "Amount：";
t.Order_Number = "Order number:";
t.Order_Time_Day = "Order time:";
t.Order_Payment_Method = "Payment method:";
t.Order_Pay_Online = "Pay online";
t.Order_Pay_At_The_Store = "Pay at the store";
t.Order_Pay_Pay_After_GetGood = "Cash on delivery";
t.Order_Ship_Method = "Ship method:";
t.Order_logistics_ID = "Logistics ID:";
t.Order_Real_Payment = "Real payment:";
t.Order_Pickup_At_Store = "Pick up at store";
t.Order_Fast_Delivery = "Delivery";
t.Order_Amout_Payment = "Amout Payment";
t.Order_Select_Payment_Method = "Select payment method";


t.Check_order_payment_status = "Check order payment status"
t.Checking_order_payment_status = "Checking the order payment status, please do not close this window"
t.Continue_Shopping = 'Continue Shopping'
t.Index_home = 'Home'
t.Catagories = 'Categories'
t.My_Orders = 'My Orders'
t.Profile = 'Profile'
t.Country = 'Country'
t.Get_Code = 'Get Code'
t.Verification_Code = "Verification Code"
t.ResendSms = "Resend"
t.Coupon_Code ="Coupon Code"
t.Commission_Goods = 'Referral'
t.Voucher ="Coupon"

t.add = 'Add'
t.delete = 'Delete'
t.deleteBatch = 'Delete'
t.update = 'Edit'
t.query = 'Query'
t.export = 'Export'
t.handle = 'Action'
t.confirm = 'Confirm'
t.cancel = 'Cancel'
t.logout = 'Sign Out'


/**共用**/
t.public={}
t.public.set = 'Set'
t.public.edit = 'Edit'
t.public.add = 'Add'
t.public.delete = 'Delete'
t.public.update = 'Update'
t.public.modify = 'Modify'
t.public.success = 'Success'
t.public.fail = 'Fail'
t.public.error = 'Error'
t.public.sort = 'Sort'
t.public.tips = 'Tips'
t.public.operation = 'Operation'
t.public.yes = 'Yes'
t.public.no = 'No'
t.public.submit = 'Submit'
t.public.reset = 'Reset'
t.public.confirm = 'Confirm'
t.public.cancel = 'Cancel'
t.public.back = 'Back'
t.public.title = 'Title'
t.public.content = 'Content'
t.public.picture = 'Picture'
t.public.search = 'Search'
t.public.query = 'Query'
t.public.output = 'Output'
t.public.icon = 'Icon'
t.public.selectedIcon = 'Selected Icon'
t.public.askDelete = 'This operation will be permanently deleted. Do you want to continue?'
t.public.chinese = 'Chinese'
t.public.english = 'English'
t.public.khmer = 'Khmer'
t.public.createTime = 'Create Time'
t.public.finishTime = 'Finish Time'
t.public.status = 'Status'
t.public.valid = 'Valid'
t.public.Used = 'Used'
t.public.invalid = 'Invalid'
t.public.iSee = 'I See'
t.public.close = 'Close'
t.public.remark = 'Remark'
t.public.value = 'Value'
t.public.baseInformation = 'Base Information'
t.public.all = 'All'
t.public.name = 'Name'
t.public.numberSort = 'Please enter the bigger the number, the bigger the front row'
t.public.advert = 'Advert'
t.public.platform = 'Platform'
t.public.noData = 'No Data'
t.public.operName = 'Oper Name'
t.public.createDate = 'Create Date'
t.public.updateDate = 'Update Date'
t.public.price = 'Price'
t.public.resetSearchKeyword = 'Reset Search Keyword'
t.public.pleMobile = 'Please enter your mobile number'
t.public.pleCode = 'Please enter the verification code'
t.public.noRecording = 'No record yet'
t.public.Searchproducts = 'Search for products'
t.public.Searchhistory = 'Search history'
t.public.Popularsearch = 'Popular search'
t.public.Nosearchrecord = 'No search record'
/**支付页面**/
t.payOrder={}
t.payOrder.barName = 'Payable balance'
t.payOrder.payableBalance= 'Payable balance'
t.payOrder.balance= 'Balance'
t.payOrder.paymentMethod= 'Select Payment Method'
t.payOrder.creditCard='Credit Card'
t.payOrder.creditDdbitCard='Credit/Ddbit Card'
t.payOrder.mobileMode='Mobile Banking Recommended(No fees)'
t.payOrder.payNow='Pay Now'

/**landingPage**/
t.landingPage={}

/**首页**/
t.home={}
t.home.finishedText="No more"
t.home.loosingText="Pull down to refresh"
t.home.loadingText="Loading..."
t.home.successText="download finished"
/**佣金商品**/
t.personalCenter={}
t.personalCenter.promoCode="Coupon Code"
t.personalCenter.commission="Rebate"
t.personalCenter.copySuccess="Copy successful"
t.personalCenter.copyError="Copy failed"
t.personalCenter.copyButton="click to copy"
t.personalCenter.whetheroutLogin="Log out or not?"
t.personalCenter.Promoter="Promoter"
t.personalCenter.Discountprice="Discounted Price"
t.personalCenter.Commissionrecord="Your Earning"
t.personalCenter.rebate="Rebate"
t.personalCenter.torebate="To be repaid"
t.personalCenter.orderafterTipe="Rebate after 24 hours of order completion。"
t.personalCenter.failedRebate="Rebate failed"
t.personalCenter.refunded="Refunded"
t.personalCenter.gained="Earned(฿)"
t.personalCenter.ontheway="To Receive(฿)"
t.personalCenter.failed="Failed(฿)"
t.personalCenter.myWallet="My Coins"
t.personalCenter.myBalance="My Balance"
t.personalCenter.withdraw="Withdraw"
t.personalCenter.understand="OK"
t.personalCenter.giftAmount="Gift Amount"
t.personalCenter.giftText="The amount is given by the platform to users is part of the balance. Cannot be used for cash withdrawal, only in Rabbit APP. For example: prize from Shake&Win, new registered user rewards, etc."
t.personalCenter.frozenAmount="Frozen Amount"
t.personalCenter.frozenText="The commission income obtained by the user inviting others to place an order, and the amount waiting for review after the user applies for withdrawal，etc."
t.personalCenter.withdrawalsRecord="Withdrawals Record"
t.personalCenter.withdrawableCash="Withdrawable cash"
t.personalCenter.withdrawalAmount="Withdrawal Amount"
t.personalCenter.withdrawalAmountTip="No less than"
t.personalCenter.confirmWithdrawal="Confirm"
t.personalCenter.bottomTip="Tip: Before the approval is completed, the amount will be frozen"
t.personalCenter.withdrawnSuccessfully="Withdrawn Successfully"
t.personalCenter.underReview="Reviewing"
t.personalCenter.examinationPassed="Passed"
t.personalCenter.completed="Completed"
t.personalCenter.ConfirmText="Confirm Withdrawal Account"
t.personalCenter.changeAccount="Add account"
t.personalCenter.formTitle="Please fill in the withdrawal account"
t.personalCenter.bankName="Bank"
t.personalCenter.account="Account"
t.personalCenter.name="Name"
t.personalCenter.protipText="Save account for next withdrawal"
t.personalCenter.withdrawalAmountTipTwo="Withdrawable amount exceeded"
t.personalCenter.successtip="Tip: Before the approval is completed, the amount will be frozen"
t.personalCenter.successTitle="Submitted successfully!"
t.personalCenter.selectrecordingbutton="View withdrawal history"
t.personalCenter.Changepassword="Change payment password"
t.personalCenter.pleaseUsername="please enter verification code"
t.personalCenter.pleasePayword="Please input the 6-digit payment password"
t.personalCenter.pleasePaywordagain="Please input the payment password again"
t.personalCenter.tips="Tips"
t.personalCenter.payProtips="Please set a payment password first to ensure your account security."
t.personalCenter.setButton="Set now"
t.personalCenter.nextButton="Next time"
t.personalCenter.differentText="Wrong Password"
t.personalCenter.firstConfirmtitle="Welcome to Rabbit"
t.personalCenter.firstConfirmcontent="Are you sure you want to apply to become a promoter？"
t.personalCenter.firstConfirmbuttom="Confirm"
t.personalCenter.secondConfirmtitle="Congratulations on becoming a Rabbit promoter!"
t.personalCenter.secondConfirmcontent="Check out how to make money now!"
t.personalCenter.secondConfirmcode="Your exclusive Code: "
t.personalCenter.secondConfirmbuttom="Check now"
t.personalCenter.BecomePromoter="Become a promoter"
t.personalCenter.BecomePromotertoptitle="-Become a Rabbit promoter and make money with your fingers-"
t.personalCenter.BecomePromotertoptip="Come and join us"
t.personalCenter.BecomePromotertoptextone="-What is Rabbit promoter？-"
t.personalCenter.BecomePromotertoptexttwo="Rabbit users with Rabbit exclusive discount code."
t.personalCenter.BecomePromotertoptextthree="-What is a discount code？-"
t.personalCenter.BecomePromotertoptextfour="1. You can use the discount code to get exclusive discount when you place an order in Rabbit mall. 2. After other users place an order using the promotional code of the promoter, the promoter can obtain the corresponding reward."
t.personalCenter.BecomePromotertoptextFives="-How to become a promoter？-"
t.personalCenter.BecomePromotertoptextsix="Click below to become a promoter"
t.personalCenter.BecomePromotertopbottomtip="-END-"
/**翻译结束**/
t.personalCenter.pleasebankTips="Please select a bank"
t.personalCenter.PleasecardTips="Please enter the correct card number"
t.personalCenter.pleaseusernameTips="please enter your username"
t.personalCenter.paywordTitle="Pay with Wallet"
t.personalCenter.paywordTip="please enter your wallet password"
t.personalCenter.Deadline="Deadline"
t.personalCenter.ShareButtom="Share Your Coupon | Eam"
t.personalCenter.Productlist="Product List"
t.personalCenter.Coupon="Coupon"
t.personalCenter.gavetip="gave you a coupon"
t.personalCenter.YourEarne="Your Earne"
t.personalCenter.receivedSuccessfuily="Received Successfuily!"
t.personalCenter.Notyetuse="Not yet use"
t.personalCenter.Usehistory="Use history"
t.personalCenter.notcouponTip="Dear, your don't have coupon~"
t.personalCenter.moreinfo="more info"
t.personalCenter.Canusecoupon="Can use coupon"
t.personalCenter.Canbeusecoupon="Can't be use coupon"
t.personalCenter.useimmediately="Use immediately"
t.personalCenter.Sharetips="Use the browser in the upper-right corner to share"
t.personalCenter.iosSharetips="Click on the Share icon below to share this page."

export default t
