<template>
	<div class="root" ref="root">
		<div style="overflow:hidden; height: 100%;">
			<div class="tags" :style="tagSlider.tagsStatic" ref="tags">
				<div class="tags-inner" :style="tagSlider.tagsInnerStatic" ref="tagsInner">
					<slot :wrapper="root"><div></div></slot>
				</div>
			</div>
		</div>
		<div class="tags-sroll-rail" v-if="tagSlider.isMobile && tagSlider.showSlider">
			<div class="rail" :style="{ width: tagSlider.railLenth_VW + 'vw' }">
				<div class="slider" :style="{ width: tagSlider.sliderLenth_PX + 'px', marginLeft: tagSlider.sliderOffset_PX + 'px' }"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			railLength: {
				type: Number,
				default: 6,
			},
		},
		data() {
			return {
				// tag滑动相关的变量
				tagSlider: {
					// 下面是配置项
					/**以vw计的滑轨长度 */
					railLenth_VW: this.railLength,

					// 下面是动态计算值,初始值不重要
					/**元素总长度(包括不可视区域)与滑轨长度的比例 */
					shrinkRatio: 0,
					/**以px计的滑块长度 */
					sliderLenth_PX: 0,
					/**以px计的滑块相对滑轨的左偏移 */
					sliderOffset_PX: 0,
					/**是否需要显示滚动条 */
					showSlider: false,
					/**PC端不做滚动样式处理 */
					isMobile: false,
				},
				// 各种Observer实例,组件退出时需释放
				observer: {
					Resize_tags: null,
				},
				root: null,
			};
		},
		mounted() {
			// 兼容ssr,依赖环境的值放到client only的代码里运行
			this.tagSlider.isMobile = /(iPhone|iPad|iPod|iOS|Android|adr|Windows Phone|SymbianOS)/gi.test(navigator.userAgent);
			this.scrollarify();
			this.root = this.$refs.root;
		},
		methods: {
			// 注意scrollarify函数的调用时机,不可令其在ssr期间调用
			scrollarify() {
				const sliderUpdate = function() {
					const slider = this.tagSlider;
					slider.shrinkRatio = (document.body.clientWidth * slider.railLenth_VW) / 100 / this.$refs.tagsInner.firstElementChild.clientWidth;

					slider.sliderLenth_PX = this.$refs.tags.clientWidth * slider.shrinkRatio;
					slider.sliderOffset_PX = this.$refs.tags.scrollLeft * slider.shrinkRatio;
				}.bind(this);
				this.$refs.tags.addEventListener('scroll', sliderUpdate);

				const obs = new ResizeObserver((entrys) => {
					this.tagSlider.showSlider = this.$refs.tags.clientWidth < this.$refs.tagsInner.firstElementChild.clientWidth;
					this.$emit('resize', sliderUpdate);
					sliderUpdate();
				});

				obs.observe(this.$refs.tags);
				this.observer.Resize_tags = obs;
			},
		},
		beforeDestroy() {
			if (this.observer.Resize_tags !== null) {
				this.observer.Resize_tags.disconnect();
				this.observer.Resize_tags = null;
			}
		},
	};
</script>

<style scoped>
	.root {
		width: 100%;
		height: 100%;
	}

	.tags {
		width: 100%;
		height: calc(100% + 10px);
		/* margin: 10px auto 0; */
		padding-bottom: 10px;
		text-align: center;
		border-radius: 5px;
		overflow-x: auto;
		overflow-y: hidden;
		box-sizing: border-box;
	}
	.tags-inner {
		height: 100%;
	}
	.tags-sroll-rail {
		position: relative;
		top: 10px;
		width: 100%;
		background-color: transparent;
		height: 10px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
	}
	.tags-sroll-rail .rail {
		background-color: #dedede;
		height: 4px;
		border-radius: 2px;
	}
	.tags-sroll-rail .rail .slider {
		background-color: #f78e1e;
		/* 滑块宽度应是动态的 */
		/* width: 0vw; */
		height: 4px;
		border-radius: 2px;
	}
</style>
