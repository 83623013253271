<template>
	<div>
		<van-nav-bar fixed :title="$t('personalCenter.myWallet')" @click-left="$router.back(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<div class="content-style">
			<div class="content-style__top">
				<div class="content-style__top__block">
					<div class="content-style__top__block__left" style="color: #000000;">{{ $t('personalCenter.myBalance') }}</div>
					<div class="content-style__top__block__right">฿ {{ myWallet | Calculat }}</div>
				</div>
				<div class="content-style__top__block">
					<div class="content-style__top__block__left">
						<span>{{ $t('personalCenter.giftAmount') }}</span>
						<!-- <span @click="giftShow = true"><van-icon name="question-o" size="18"/></span> -->
					</div>
					<div class="content-style__top__block__right">฿ {{ giftAmount | Calculat }}</div>
				</div>
				<div class="content-style__top__block">
					<div class="content-style__top__block__left">
						<span>{{ $t('personalCenter.frozenAmount') }}</span>
						<!-- <span @click="freezeShow=true"><van-icon name="question-o" size="18"/></span> -->
					</div>
					<div class="content-style__top__block__right" style="color:#A7A7A7;">฿ {{ frozenAmount | Calculat }}</div>
				</div>
			</div>
			<!-- <div class="content-style__bottom">
				<div class="content-style__top__block" @click="$router.push('withdraw')">
					<div class="content-style__top__block__left" style="color: #000000;"><span style="margin: 0 5px 0 0;"><van-icon name="credit-pay" size="30" color="#F78E1E" /></span>{{$t('personalCenter.withdraw')}}</div>
					<div class="content-style__top__block__right"><van-icon name="arrow" size="20" color="#000000"/></div>
				</div>
			</div> -->
		</div>
		<!-- <van-popup v-model="giftShow">
			<div style="padding: 40px 20px;">
				<div style="color: #000000;font-size: 14px;text-align: center;">{{$t('personalCenter.giftAmount')}}</div>
				<div style="color: #000000;font-size: 14px;text-align: left;margin: 30px 0;">{{$t('personalCenter.giftText')}}</div>
				<div><van-button type="default" color="#F78E1E" @click="giftShow=false" style="width: 90%;display: block;margin: 0 auto;">{{$t('personalCenter.understand')}}</van-button></div>
			</div>
		</van-popup> -->
		<!-- <van-popup v-model="freezeShow">
			<div style="padding: 40px 20px;">
				<div style="color: #000000;font-size: 14px;text-align: center;">{{$t('personalCenter.frozenAmount')}}</div>
				<div style="color: #000000;font-size: 14px;text-align: left;margin: 30px 0;">{{$t('personalCenter.frozenText')}}</div>
				<div><van-button type="default" color="#F78E1E" @click="freezeShow=false" style="width: 90%;display: block;margin: 0 auto;">{{$t('personalCenter.understand')}}</van-button></div>
			</div>
		</van-popup> -->
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie';
	import { orderService, ocdev_api, personalCenter } from '@/common/api';
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation';
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount);
			},
		},
		components: {},
		computed: {},
		data() {
			return {
				giftShow: false,
				freezeShow: false,
				myWallet: '',
				giftAmount: '',
				frozenAmount: '',
			};
		},
		methods: {
			walletquery() {
				personalCenter
					.walletqueryacount({})
					.then((res) => {
						if (res.code == 0) {
							this.myWallet = res.data.total;
							this.giftAmount = res.data.present;
							this.frozenAmount = res.data.freeze;
						}
					})
					.catch(() => {});
			},
		},
		created() {
			this.walletquery();
		},
	};
</script>

<style scoped>
	.content-style {
		width: 95%;
		margin: 55px auto;
	}
	.content-style__top {
	}
	.content-style__top__block {
		background: #ffffff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2px;
		padding: 20px;
	}
	.content-style__top__block__left {
		font-size: 14px;
		color: #c9c9c9;
		display: flex;
		align-items: center;
	}
	.content-style__top__block__left span {
		margin-left: 5px;
	}
	.content-style__top__block__right {
		font-size: 20px;
		color: #f78e1e;
	}
	.content-style__bottom {
		margin-top: 15px;
	}
	.van-popup {
		width: 90% !important;
	}
</style>
