<template>
	<div style="position: relative; height: 100vh;">
		<van-nav-bar fixed :title="$t('Profile')" />
		<div v-if="isLogin == false" class="login-block">
			<div style="width: 57px; height: 59px; margin-right: 20px;border: 1px solid #E2E2E2;border-radius: 50%;">
				<img src="../../../static/images/md-person2.svg" style="width: 100%; height: 100%;border-radius: 50%;" />
			</div>
			<div style="font-size: 14px;text-decoration:underline">
				<span size="small" @click="login()">{{$t('Common_Login')}}</span>
			</div>
		</div>
		<div v-if="isLogin == true" style="margin-top:53px;padding-bottom:50px;">
			<div>
				<div style="height: 124px; background: #FFFFFF;width: 95%; margin:0 auto;">
					<van-card :centered="true" style="background: #FFFFFF;height: 100%;">
						<template #title>
							<p style="font-size: 16px;">{{user.username}}</p>
						</template>
						<!--<template #desc v-if="couponCode">
							<span @click="$router.push('/promoter')" style="color:#F78E1E;;font-size: 12px;border: 1px solid #E2E2E2;border-radius: 20px;padding:5px 15px;">{{$t('personalCenter.Promoter')}}</span>
						</template>
						<template #desc v-else>
							<span @click="$router.push('/becomePromoter')" style="color:#101010;;font-size: 12px;border: 1px solid #E2E2E2;border-radius: 20px;padding:5px 15px;">{{$t('personalCenter.BecomePromoter')}}</span>
						</template>-->
						<template #thumb>
							<img v-if="!userPic" src="../../../static/images/md-person.svg" style="width: 57px; height: 59px; margin-right: 10px;border: 1px solid #E2E2E2;border-radius: 50%;" />
							<img v-else :src="userPic" style="width: 57px; height: 59px; margin-right: 10px;border: 1px solid #E2E2E2;border-radius: 50%;" />
						</template>
					</van-card>
				</div>
				<div class="mon-style" @click="$router.push('/myWallet')">
					<div style="color: #101010;font-size: 14px;margin-left: 15px;">{{$t('personalCenter.myWallet')}}</div>
					<div style="color: #F78E1E;font-size: 18px;display: flex;align-items: center;margin-right: 15px;">฿ {{myWallet | Calculat}} <van-icon name="arrow" color="#000000"/></div>
					
				</div>
			</div>
			<div style="margin-top: 10px; width: 95%; margin:10px auto;">
				<!--<div class="menu-list" id="copy_text" :data-clipboard-text="couponCode" @click="onClick">
					<span style="font-size: 14px;">{{$t('personalCenter.promoCode')}}：{{couponCode}}</span>
					<span style="color: #C9C9C9; font-size: 12px;">{{$t('personalCenter.copyButton')}}</span>
				</div>-->
				<div class="menu-list" @click="$router.push('/coupon')">
					<div style="display: flex; align-items: center;">
						<p>{{$t('Voucher')}}</p>
						<p style="color: #F78E1E;margin-left: 15px;">({{couponNum}})</p>
					</div>
					<van-icon name="arrow" />
				</div>
<!-- 
				<div class="menu-list" @click="$router.push('/discountGoods')">
					<div style="display: flex; align-items: center;">
						<p>{{$t('Commission_Goods')}}</p>
					</div>
					<van-icon name="arrow" />
				</div>
				 -->
				<!--<div class="menu-list" @click="$router.push('/commissionRecord')">
					<div style="display: flex; align-items: center;">
						<p>{{$t('personalCenter.Commissionrecord')}}</p>
					</div>
					<van-icon name="arrow" />
				</div>-->
			</div>
		</div>
		<div v-if="isLogin == true" class="bottoom-out" @click="loginOut()">
			<!--<van-button size="small" color="#F78E1E" @click="loginOut()" style="width: 50%; ">{{$t('Out_Login')}}</van-button>-->
			<img src="../../../static/images/antOutline-logout.svg" width="24px" height="24px" />
		</div>
		<Tabbar/>
	</div>
</template>

<script>
	import Tabbar from '@/components/Tabbar.vue'
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api,personalCenter,preorderService } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {
			Tabbar
		},
		computed: {},
		data() {
			return {
				isLogin: false,
				couponCode: '',
				user: {},
				show: false,
				userPic: '',
				myWallet:'',
				couponNum:0,
			}
		},
		methods: {
			couponqueryusablecount(){
				preorderService.couponqueryusablecount()
				.then(res => {
						if(res.code == 0) {
							this.couponNum=res.data.count
						}
					}).catch(() => {})
			},
			walletquery(){
				personalCenter.walletqueryacount({})
				.then(res => {
						if(res.code == 0) {
							this.myWallet=res.data.total
						}
					}).catch(() => {})
			},
			onClick() {
				var clipboard = new this.Clipboard('#copy_text');
				let that = this
				clipboard.on('success', e => {
					that.$Toast(that.$t('personalCenter.copySuccess'))
					// 释放内存
					clipboard.destroy()
				})
				clipboard.on('error', e => {
					that.$Toast(that.$t('personalCenter.copyError’'))
					// 释放内存
					clipboard.destroy()
				})
			},
			pccustomer() {
				this.$axios({
						method: 'get',
						url: ocdev_api + '/v1/pc/customer',
						params: {
							clientId: this.user.id
						}
					})
					.then(res => {
						if(res.code == 0) {
							if(res.data.isPC == 1) {
								this.couponCode = res.data.couponCode
							} else {
								this.couponCode = ''
							}
						}
					}).catch(() => {})
			},
			login() {
				this.$router.push({
					path: '/login'
				})
			},
			loginOut() {
				this.$Dialog.confirm({
					message: this.$t('personalCenter.whetheroutLogin'),
					confirmButtonText: this.$t('confirm'),
					cancelButtonText: this.$t('cancel'),
					confirmButtonColor: '#F78E1E'
				}).then(() => {
					cookieRemove('token')
					cookieRemove('userpic')
					cookieRemove('user')
					this.$router.push('/')
				}).catch(() => {
					// on cancel
				});
			},
		},
		created() {
			let userDataStr = cookieGet("token")
			if(userDataStr) {
				this.isLogin = true
				this.userPic = cookieGet("userpic")
				this.user = JSON.parse(cookieGet("user"))
				//this.pccustomer()
				this.walletquery()
				this.couponqueryusablecount()
			} else {
				this.isLogin = false
			}
		},
	}
</script>

<style scoped>
	.mon-style {
		width: 90%;
		margin: 0 auto;
		background: #FFFFFF;
		display: flex;
		justify-content: space-between;
		padding: 20px 2.5%;
		border-top: 1px solid #F1F1F1;
		align-items: center;
	}
	
	.bottoom-out {
		width: 54px;
		height: 54px;
		background: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: space-around;
		border-radius: 50%;
		position: absolute;
		right: 20px;
		top: 50px;
	}
	
	.menu-list {
		color: #000000;
		font-size: 16px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 3px;
		background: #FFFFFF;
		padding: 0 25px;
	}
	
	.login-block {
		margin-top: 50px;
		background: #FFFFFF;
		height: 135px;
		display: flex;
		align-items: center;
		padding: 0 30px;
	}
	
	.van-card__thumb {
		display: flex;
		align-items: center;
		width: auto;
	}
	
	.van-card__header {
		height: 100%;
		align-items: center;
	}
</style>