<template>
	<div class="root">
		<van-nav-bar fixed :title="$t('Privacy Policy')" @click-left="$router.go(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<iframe src="/static/trans.html?policy"></iframe>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.root {
		padding: 15px;
		padding-top: 41px;
		height: 100%;
		box-sizing: border-box;
	}

	iframe {
		width: 100%;
		height: 98%;
		margin-top: 2%;
		border: none;
	}
</style>
