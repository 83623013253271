<template>
	<div>
		<!--<van-nav-bar fixed
	      title="My device was disconnected, how can I reset it?"
	    >
	      <template #left>
		    	<van-icon name="arrow-left" size="18" color="#000000" @click.native="$router.go(-1)"/> 
		  	</template>
	    </van-nav-bar>-->
	    <div class="content">
	    	<p class="special">Dear user, we are sorry for your inconvenience. You can reset your device in the following ways:</p>

			<p class="special">For most devices that have a reset button (please refer to the product manual to find the reset button):</p>
			
			<p>1. Smart Mode: Firstly, make sure the device is powered off for more than 10 seconds before</p>
			<p>powering on the device. Then long press the reset button on the device for about 5 seconds.
				 Wait a few moments until the lighting starts blinking rapidly. The device enters the Smart mode.
				  You can connect the network according to the Smart mode as prompted on the App.</p>
			<p>2. AP Mode: No need to power down the device, but need to keep the lighting device blinking rapidly.
				 Then long press the reset button on the device for about 5 seconds. Wait a few moments until the ; lighting starts blinking slowly.
				 The device enters the AP mode.
				  You can connect the network according to the AP mode as prompted on the App.</p>
			<p>3. Cameras and video doorbell devices need to be <span @click="$router.push('/distributionErrorApp')">removed from the App</span> before they can be reset.</p> 
			
			<p class="special">For lighting equipment such as smart bulbs :</p>
			
			<p>1. Smart Mode: Firstly, make sure the device is powered off for more than 10 seconds before powering on the device.
				 Then turn on and off three times (on-off-on-off-on) power of the device (2 to 4 seconds between each on and off).
				  Wait a few moments until the lighting starts blinking rapidly. The device enters the Smart mode.
				   You can connect the network according to the Smart mode as prompted on the App.</p>
			
			<p>2. AP Mode: No need to power down the device, but need to keep the lighting device blinking rapidly.
				 Then turn on and off three times (on-off-on-off-on) power of the device (2 to 4 seconds between each on and off).
				  Wait a few moments until the lighting starts blinking slowly. The device enters the AP mode.
				   You can connect the network according to the AP mode as prompted on the App.</p>
			
			<p class="special">For the smart lock, you need to read the manual and enter the corresponding command to make the door lock enter the network distribution mode.
				 Then you can configure the smart door lock according to the network distribution steps of the App lock prompt.
			Note: The device only supports connection to the 2.4g network.Thank you for your support and understanding.</p>
	    </div>
	</div>
</template>

<script>
export default {
  name: 'details',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  tdk() {
    return {
      title: 'My device was disconnected, how can I reset it?',
      ssrHeadAddInfo: {
        oglocale: 'en_US',
        ogtype: 'website',
        ogtitle: 'My device was disconnected, how can I reset it?',
      }
      
    };
  },
  mounted(){
  },
  data () {
    return {
    }
  },
  created () {
  },
}
</script>

<style scoped="scoped">
	.content{
		width: 95%;
		margin: 20px auto;
		font-size: 16px;
		color: #BBBBBB;
	}
	.content span{
		color: #F78E1E;
		cursor:pointe
	}
	.special{
		font-size: 17px;
		color: #000000 !important;
		font-weight: 600;
	}
</style>