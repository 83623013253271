<template>
	<div class="fx">
		<div class="fy left f-noshrink">
			<div class="fy-list p2 topcat" :class="{ active: index === rawIndex1 }" @click="rawIndex1 = index" v-for="(item, index) of raw" :key="index">
				<loading-image :src="rawIndex1 === index ? item.checkedimage : item.uncheckedimage"></loading-image>
				<span class="font-sm">{{ item.name }}</span>
			</div>
		</div>
		<div class="right f-grow p2" v-if="cr1.advert">
			<img class="mb1" :src="cr1.advert.image" />
			<template v-if="(cr1.catalogs instanceof Array)">
				<div v-for="cr2 in cr1.catalogs" :key="cr2.id" class="cr2 pt3 pb2">
					<span class="ml2 font-m">{{ cr2.name }}</span>
					<div class="cr3">
						<div
							v-for="cr3 in cr2.catalogs"
							:key="cr3.id"
							class="fy-list p1 bdbox"
							@click="$router.push({ path: '/catalogProduct', query: { catalogId: cr3.id, catalogName: cr3.name } })"
						>
							<loading-image :src="cr3.uncheckedimage"></loading-image>
							<!-- <img :src="cr3.uncheckedimage"> -->
							<span class="mt1 font-sm">{{ cr3.name }}</span>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
	import loadingImage from '@/components/LoadingImage';
	export default {
		components: {
			loadingImage,
		},
		data() {
			return {
				// 主要数据列表
				raw: [],
				// 当前显示的数据列表索引
				rawIndex1: 0,
			};
		},
		computed: {
			cr1() {
				return this.raw[this.rawIndex1] || {};
			},
		},
		mounted() {
			this.$axios.get('/v1/threelevelcategory/query').then((res) => {
				this.raw = res.list;
			});
		},
	};
</script>

<style lang="less" scoped>
	.left {
		background-color: white;
		box-sizing: border-box;
		width: 25%;
		.topcat {
			border-left: 3px solid transparent;
		}
		.active {
			background-color: rgba(#f78e1e, 0.05);
			border-color: #f78e1e;
			color: #f78e1e;
		}
		::v-deep .loadingImage {
			width: 32px;
			height: 32px;
		}
	}
	.right {
		max-height: 100%;
		overflow: auto;
		> img {
			width: 100%;
			height: auto;
			border-radius: 10px;
		}
		.cr2 {
			background-color: white;
			border-radius: 8px;
		}
		.cr3 {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			img {
				width: 50%;
			}
		}
		::v-deep .van-image__img {
			min-height: 50px;
			width: 60%;
			height: auto;
			margin: auto;
		}
		::v-deep .loadingImage {
			max-width: 100px;
			max-height: 100px;
			width: 18.6vw;
			height: 18.6vw;
		}
	}
</style>
