<template>
  <div>
    <van-nav-bar fixed @click-left="$router.push('/')">
      <template #left>
        <img style="width:30px;height:30px" src="../../../static/images/left.png" />
	  	</template>
    </van-nav-bar>

    <div style="padding-top:46px;">
      <van-cell center style="z-index: 6 !important;position: fixed;top: 46px;left: 0;width: 100%;">
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="fx" style="height:48px;max-width:540px;margin:auto;">
              <img style="height:45px;width:45px;" :src="merchantInfo.image">
            <div style="display:inline-block;height:42px;line-height:50px; padding-left:15px;float: left;">
              {{merchantInfo.name}}
            </div>
          </div>
        </template>
      </van-cell>

      <div class="fx" style="padding-top:78px;">
        <div style="">
          <van-sidebar v-model="activeKey" @change="changeActiveKey">
            <van-sidebar-item style="text-align:center;box-sizing: unset;height: 50px;" v-for="item in merchantCatalogInfo" :key="item.id" :title="item.name" />
          </van-sidebar>
        </div>
        <div :style="'width:'+ rightWidth +'px;overflow-y: auto;'">
          <van-list
          v-model="loading"
          :finished="finished"
          :loading-text="$t('home.loadingText')"
          :finished-text="$t('home.finishedText')"
          :immediate-check="false"
          @load="onLoad"
        >
          <div v-for="item in originList" :key="item.productId" >
            <!-- <div @click="details(item.productItemId)"> -->
              <product-ui-line :product="item"></product-ui-line>
              <!-- <van-card
                :origin-price="10.00"
              >
                <template #thumb>
                  <img :src="item.imageobjcet.imageurl" width="84px" height="84px"/>
                </template>
                <template #tag>
                  <van-tag v-if="item.price != item.price_origin" mark color="#f1b150">
                    {{((1-item.price/item.price_origin)*100).toFixed(0)}}% OFF
                  </van-tag>
                </template>
                <template #title>
                  <div style="font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.productName}}</div>
                </template>
                <template #desc>
                  <div v-if="Object.keys(item.sku)[0]" style="font-size: 12px;padding-left:20px;padding-top:10px;overflow: hidden;    text-overflow: ellipsis;    display: -webkit-box;    -webkit-line-clamp: 2;    -webkit-box-orient: vertical;">
                    [{{Object.keys(item.sku)[0]}}:{{item.sku[Object.keys(item.sku)[0]]}}]
                  </div>
                </template>
                <template #price>
                  <div style="color:#F78E1E; font-size: 15px;">฿{{item.price | Calculat}}</div>
                </template>
                <template #origin-price>
                  <div v-if="item.price != item.price_origin" style="color: #969799;font-size: 10px;">
                    ฿{{item.price_origin | Calculat}}
                  </div>
                </template>

              </van-card> -->
            <!-- </div> -->
          </div>

        </van-list>
        </div>
      </div>

      <div v-if="isLogin == true" style="padding-top:50px;padding-bottom:50px;">

        
      </div>
    </div>

  </div>
</template>

<script>

import Tabbar from '@/components/Tabbar.vue'
import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService,merchantService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
import productUiLine from '@/components/product-ui-line';

export default {
  name: 'merchantCatalog',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
    Tabbar,
    productUiLine
  },
  computed: {
  },
  data () {
    return {
      isLogin: false,
      loading: false,
      finished: false,
      page:0,
      size:20,
      maxPage: 1,
      originList: [],
      isMore: true,

      screenWidth: document.body.clientWidth, // 屏幕尺寸
      rightWidth:0,
      merchantId: '',
      catalogId: '',
      merchantInfo: {},
      activeKey: 0,
      activeTempKey: 0,
      merchantCatalogInfo:[],
      catalogKeyList:[]
    }
  },
  methods: {
    
    changeActiveKey(index){
      if(index != this.activeTempKey){
        this.activeTempKey = index
        for (const i in this.merchantCatalogInfo) {
          if(index == i){
            this.catalogId = this.merchantCatalogInfo[i].id
          }
        }
        this.loading = true
        this.finished = false,
        this.page = 0
        this.size = 20
        // this.maxPage = 1
        this.isMore = true
        this.originList = []
        this.onLoad()
      }

    },

    details(id){
      console.log(id)
      this.$router.push({path: '/details',query: { productItemId: id }})
    },

    onLoad() {
      console.log(this.finished)
      if(this.isMore == true){
        //这个接口的分页不是正常的,比较坑
        merchantService.getMerchantCatalogProduct({beginrow: this.page * this.size, rowsize: this.size,merchantid:this.merchantId,catalogid:this.catalogId}).then(res => {
          if(res.code == '0'){
            if(res.list.length == 0){
              this.loading = false;
              this.finished = true;
            }else{

              res.list.forEach( item => {
                this.originList.push(item)
              })

              if(res.list.length == 20){
                this.isMore = true
                this.finished = false
              }else{
                this.isMore = false
                this.finished = true
              }

              // //向上取整
              // this.maxPage = Math.ceil(res.count/this.size)
              this.page +=1
              this.loading = false;
            }
          }
        }).catch(e => {
      
        })
      }else{
        this.finished = true;
      }
      
    },
    
  },
  mounted(){

    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }

    this.rightWidth = this.screenWidth - 85

    if(this.$route.query.merchantId != undefined){
      this.merchantId = this.$route.query.merchantId
      merchantService.getMerchantInfo({id: this.merchantId}).then(res => {
        if(res.code == '0'){
          this.merchantInfo = res.data
        }
      }).catch(e => {
    
      })

      merchantService.getMerchantCatalog({merchantid: this.merchantId}).then(res => {
        if(res.code == '0'){
          this.merchantCatalogInfo = res.list
          if(this.merchantCatalogInfo.length > 0){
            this.catalogId = this.merchantCatalogInfo[0].id
            this.loading = true
            this.onLoad()
          }
        }
      }).catch(e => {
    
      })
    }
    

  },
  created () {

  },
}
</script>

<style>

  .van-sidebar-item__text{
    height: 50px !important;
    line-height: 15px;
    justify-content: center !important;
    width: 100% !important;
    display: flex !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    -webkit-box-pack: center !important;
  }
</style>
<style scoped>
	.login-block {
		margin-top: 50px;
		background: #FFFFFF;
		height: 135px;
		display: flex;
		align-items: center;
		padding: 0 30px;
	}
  .van-sidebar-item--select::before{
    background-color:#FFFFFF !important;
  }
  .van-sidebar-item{
    padding: 0px 3px !important;
  }
  .van-card {
    background-color: #ffffff !important;
  }
  .van-sidebar-item {
    font-size: 12px;
    /* line-height: 10px !important; */
  }
</style>
