<template>
	<div>
		<!--<van-nav-bar fixed
	      title="How to remove the device?"
	    >
	      <template #left>
		    	<van-icon name="arrow-left" size="18" color="#000000" @click.native="$router.go(-1)"/> 
		  	</template>
	    </van-nav-bar>-->
	    <div class="content">
	    	<p class="special"> Dear user, we are sorry for your inconvenience.
	    		 Go to the device control page and click the button on the top right corner of the page.
	    		  Then olick "Remove Device' to complete the operation.
			</p>
	    </div>
	</div>
</template>

<script>
export default {
  name: 'details',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  tdk() {
    return {
      title: 'How to remove the device?',
      ssrHeadAddInfo: {
        oglocale: 'en_US',
        ogtype: 'website',
        ogtitle: 'How to remove the device?',
      }
      
    };
  },
  mounted(){
  },
  data () {
    return {
    }
  },
  created () {
  },
}
</script>

<style scoped="scoped">
	.content{
		width: 95%;
		margin: 20px auto;
		font-size: 16px;
		color: #BBBBBB;
	}
	.content span{
		color: #F78E1E;
		cursor:pointe
	}
	.special{
		font-size: 17px;
		color: #000000 !important;
		font-weight: 600;
	}
</style>