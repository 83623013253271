<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Mine_My_Address')"
    />
    <div  style="padding-top:50px;">
      <div style="text-align:center;padding-top:100px;" v-if="list !== undefined && list.length == 0">{{$t('Order_Add_Address')}}</div>
      <van-address-list
        v-model="chosenAddressId"
        :list="list"
        :default-tag-text="$t('Common_Default_address')"
        :add-button-text="$t('Mine_Address_New')"
        @add="onAdd"
        @edit="onEdit"
        @select="onSelect"
        
      />
    </div>
  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { productService, preorderService, addressService} from '@/common/api'

export default {
  name: 'addressList',
  components: {
  },
  computed: {
  },
  data () {
    return {
      chosenAddressId: '',
      list: [],
      originList:[],
      preorderInfo: {}
    }
  },
  methods: {
    //新增地址
    onAdd() {
      this.$router.push({path: '/addressEdit'})
    },
    //修改地址
    onEdit(item, index) {
      let data = {}
      this.originList.forEach( d => {
        if(d.id == item.id){
          data = d
        }
      })
      this.$router.push({name: 'addressEdit',params:{ dataform: data}})   
    },

    //地址变更需要调用预订单修改接口并且重新设置预订单缓存
    onSelect(item, index){
      let changeForm = {}

      //判断是否有优惠码,有就需要重新set进去请求
      if(this.preorderInfo.couponCodeModel && this.preorderInfo.couponCodeModel.enable == '1'){
        changeForm = {
          ...this.preorderInfo,
          preorderid: this.preorderInfo.id,
          addressid: item.id,
          couponCode: this.preorderInfo.couponCodeModel.couponCode
        }
      }else{
        changeForm = {
          ...this.preorderInfo,
          preorderid: this.preorderInfo.id,
          addressid: item.id
        }
      }
      
      preorderService.modify(changeForm).then(res => {
        if(res.code == '0'){
          removeBigCache("preorderInfo") //清除旧的预订单
          setBigCache("preorderInfo",JSON.stringify(res.data)) //新的预订单放缓存
          this.$router.push({path: '/order'})
        }
      }).catch(e => {
    
      })
    }
  },
  created () {
    let orderInfo = getBigCache("preorderInfo")
    if(orderInfo){
      this.preorderInfo = JSON.parse(orderInfo)
    }

    //把后台返回的格式处理成vant需要的格式
    this.list = []
    addressService.getAddressList().then(res => {
      if(res.code == '0'){
        this.originList = res.list
        res.list.forEach( item => {
          let tempObj = {}
          tempObj['id'] = item.id
          tempObj['name'] = item.contactName
          tempObj['tel'] = item.contactPhone
          tempObj['address'] = item.province +" "+ item.city +" "+ item.street
          tempObj['isDefault'] = item.default == "Y" ? true : false
          this.list.push(tempObj)

          if(item.default == "Y"){
            this.chosenAddressId = this.preorderInfo.address.id
          }
          
        })
      }
      console.log(this.list)
    }).catch(e => {
  
    })
  },
}
</script>
<style scoped>
	.van-button--danger{
		background-color:#F78E1E !important;
		border: 1px solid #F78E1E !important;
	}
</style>