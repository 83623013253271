<template>
	<div class="root">
		<van-nav-bar fixed :style="{ top: openAppHeight }" :title="$t('Contact_Us')" @click-left="$router.go(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<h3>Rabbit Selection</h3>
		<div>
			Address: 968 U Chu Liang Building, 35TH Floor, Rama lV Road, Silom, Bang Rak, Bangkok 10500
			<br />
			Open 9.00 AM - 6.00 PM
			<br />
			For further assistance, please call our Customer Service team at 02- 236-8755
			<br />
			You can text us at :
			<a href="http://m.me/rabbitselection">http://m.me/rabbitselection</a>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.root {
		padding: 15px;
		padding-top: 41px;
	}
	div {
		max-width: 100%;
		margin: 0;
		word-break: keep-all;
		white-space: div-wrap;
		line-height: 1.5;
	}
</style>
