<template>
  <div style="padding-top:100px">

    <!-- 密码输入框 -->
    <van-password-input
      :value="code"
      :mask="false"
      :length="4"
      :gutter="15"
      :info="$t('Verification_Code')"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
    />

    <div style="padding-top:20px;font-size:14px;text-align:center;">
      <div style="padding-bottom:10px;">
        {{country}} {{mobile}}
      </div>
      {{$t('PCLSMSInputVC_againBtnNoGetSMSTitle')}}？<van-button size="small" :disabled="smsBtnDisabled" @click="sendSms()" color="#F78E1E">{{ btntxt }}</van-button>
    </div>
    
      
    <!-- 数字键盘 -->
    <van-number-keyboard
      :show="showKeyboard"
      @input="onInput"
      @delete="onDelete"
      delete-button-text="×"
      @blur="showKeyboard = false"
    />
  </div>
</template>

<script>

import { appService,preorderService,shopcartService } from '@/common/api'
import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'

export default {
  name: 'smscode',
  components: {
  },
  computed: {
  },
  data () {
    return {
      showSmsCodeInput: false,
      showKeyboard: false,
      code: '',
      btntxt: this.$t('PCLMoblieInputVC_sendCodeBtnTitle'),
			smsBtnDisabled: false,
      time: 0,

      mobile:'',
      country: '',
      productItemId: '',
      total:'',
      submitOrder: 'n'
    }
  },
  created () {
    this.mobile = this.$route.query.username
    this.country = this.$route.query.country
    this.productItemId = this.$route.query.productItemId
    this.total = this.$route.query.total
    this.submitOrder = this.$route.query.submitOrder
    
    this.time = 60
    this.smsBtnDisabled = true
    //验证码的时间设置 
    this.timer()
  },
  methods: {
    sendSms(){
      appService.sendSms({country: this.country, mobile: this.mobile}).then(res => {
        if(res.code == '0'){
          this.time = 60
          this.smsBtnDisabled = true
          this.timer()
        }
      }).catch(e => {
    
      })
    },
    onInput(key) {
      console.log(this.productItemId)
      // console.log(key)
      this.code = (this.code + key).slice(0, 4);

      //输入达到4位自动验证
      if(this.code.length == 4){
        let postParams = {}
        let checkCodeIsExist = cookieGet("code")
        if(checkCodeIsExist){
          postParams['country'] = this.country
          postParams['username'] = this.mobile
          postParams['verifycode'] = this.code
          postParams['loginType'] = "PULL"
          postParams['params1'] = checkCodeIsExist
        }else{
          postParams['country'] = this.country
          postParams['username'] = this.mobile
          postParams['verifycode'] = this.code
        }

        appService.registAndLogin(postParams).then(res => {
          if(res.code == '0'){
            fbq('track', 'Lead', {})
            //登录完成存信息和token存cookie
            cookieSet('token', res.data.token)
            cookieSet('user', res.data.user)
            this.customerquerylogo()
						if(this.$route.query.couponCode){
							return this.$router.push({
								path: '/receiveAward',
		            query:{
									code:this.$route.query.couponCode
								}
							})
						}
            if(this.submitOrder == 'y'){
              let cartTempId = cookieGet("cartTempId")
              if(cartTempId){
                shopcartService.shoppingcartMerge({tmpid: cartTempId}).then(res => {
                  if(res.code == '0'){
                    //从购物车创建预订单
                    // preorderService.create({}).then(preOrderRes => {
                    //   if(preOrderRes.code == '0'){
                    //     removeBigCache("preorderInfo") //清除旧的预订单
                    //     setBigCache("preorderInfo",JSON.stringify(preOrderRes.data)) //新的预订单放缓存
                    //     this.$router.push({path: '/order'})
                    //   }
                    // }).catch(e => {
                  
                    // })


                    //返回购物车页面
                    this.$router.push({path: '/cart'})
                  }
                }).catch(e => {
              
                })
              }
            }else{
              let cartTempId = cookieGet("cartTempId")
              if(cartTempId){
                shopcartService.shoppingcartMerge({tmpid: cartTempId}).then(res => {
                  if(res.code == '0'){

                  }
                }).catch(e => {
              
                })
              }
            }
            
            //如果有单品id直接创建预订单并跳转,没有则跳回首页
            if(this.productItemId){
              //创建预订单
              preorderService.createdirect({productitemid: this.productItemId, productitemcount: this.total}).then(preOrderRes => {
                if(preOrderRes.code == '0'){
                  removeBigCache("preorderInfo") //清除旧的预订单
                  setBigCache("preorderInfo",JSON.stringify(preOrderRes.data)) //新的预订单放缓存
                  this.$router.push({path: '/order'})
                }
              }).catch(e => {
            
              })
            }else{
              this.$router.push({path: '/'})
            }
            
          }
        }).catch(e => {
      
        })
      }
    },
    customerquerylogo(){
    	appService.customerquerylogo({})
				.then(res => {
					if(res.code=='0'){
						if(res.data.image){
							cookieSet('userpic', res.data.image)
						}
					}
				}).catch(() => {
				});
    },
    onDelete() {
      this.code = this.code.slice(0, this.code.length - 1);
    },
    //验证码的倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.$t('ResendSms') +" ("+ this.time + " s)";
        
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = this.$t('Get_Code');
        this.smsBtnDisabled = false;
      }
    },
  }
}
</script>
