<template>
	<div>
		<van-nav-bar fixed :title="$t('personalCenter.withdrawalsRecord')" @click-left="$router.back(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		 	</template>
		</van-nav-bar>
		<div style="width: 95%; margin: 55px auto;">
			<div>
				<!--成功提现-->
				<div class="block-style">
					<div style="color: #000000;font-size: 14px;">{{$t('personalCenter.withdrawnSuccessfully')}}</div>
					<div style="color: #F78E1E;font-size: 18px;">฿ {{amount | Calculat}}</div>
				</div>
				<div v-for="(item,index) in list" :key="index">
					<!--审核中-->
					<div style="margin-bottom: 5px;" v-if="item.status=='INIT'">
						<div class="block-style__two">
							<div style="color: #000000;font-size: 18px;">- ฿ {{item.amount | Calculat}}</div>
							<div style="color: #EE2523;font-size: 14px;">{{$t('personalCenter.underReview')}}</div>
						</div>
						<div class="block-style__bottom">{{item.createTime}}</div>
					</div>
					<!--审核通过-->
					<div style="margin-bottom: 5px;" v-if="item.status=='AGREE'">
						<div class="block-style__two">
							<div style="color: #000000;font-size: 18px;">- ฿ {{item.amount | Calculat}}</div>
							<div style="color: #EE2523;font-size: 14px;">{{$t('personalCenter.examinationPassed')}}</div>
						</div>
						<div class="block-style__bottom">{{item.createTime}}</div>
					</div>
					<!--已失败-->
					<div style="margin-bottom: 5px;" v-if="item.status=='REFUSE'">
						<div class="block-style__two">
							<div style="color: #000000;font-size: 18px;">- ฿ {{item.amount | Calculat}}</div>
							<div style="color: #000000;font-size: 14px;">{{$t('personalCenter.failed')}}</div>
						</div>
						<div class="block-style__bottom">{{item.createTime}}</div>
						<div class="block-style__bottom__tip">{{item.hint}}</div>
					</div>
					<!--已完成-->
					<div style="margin-bottom: 5px;" v-if="item.status=='FINISH'">
						<div class="block-style__two">
							<div style="color: #000000;font-size: 18px;">- ฿ {{item.amount | Calculat}}</div>
							<div style="color: #000000;font-size: 14px;">{{$t('personalCenter.completed')}}</div>
						</div>
						<div class="block-style__bottom">{{item.createTime}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api,personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				list:[],
				amount:'',
			}
		},
		methods: {
			walletfinishamount(){
				personalCenter.walletfinishamount({
					beginPage:1,
					rowSize:999
				})
				.then(res => {
						if(res.code == 0) {
							this.amount=res.data.amount
						}
					}).catch(() => {})
			},
			querywithdrawal(){
				personalCenter.querywithdrawal({
					beginPage:1,
					rowSize:999
				})
				.then(res => {
						if(res.code == 0) {
							this.list=res.list
						}
					}).catch(() => {})
			},
			
		},
		created() {
			this.querywithdrawal()
			this.walletfinishamount()
		},
	}
</script>

<style scoped>
	.block-style{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px 20px; 
		background: #FFFFFF;
		margin-bottom: 5px;
	}
	.block-style__two{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 25px 20px 0px 20px; 
		background: #FFFFFF;
	}
	.block-style__bottom{
		padding: 15px 20px; 
		background: #FFFFFF;
		font-size: 12px;
		color: #C9C9C9;
	}
	.block-style__bottom__tip{
		padding: 10px 20px;
		background: #FFFFFF;
		font-size: 12px;
		color: #C9C9C9;
		border-top: 1px dashed #E2E2E2;
	}
</style>