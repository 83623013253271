<template>
  <div>
    <van-nav-bar fixed
      :title="dataform.addressid ? $t('Mine_Edit_Address') : $t('Mine_Shipping_Address')"
    />
    <div style="padding:70px 10px 0 10px;border-radius:8px;">
      <van-form @submit="saveAddress">
        <van-field
          v-model="dataform.contactname"
          :name="$t('Mine_Address_Reciever')"
          :label="$t('Mine_Address_Reciever')"
          :placeholder="$t('Mine_Address_Input_Reciever')"
          :rules="[{ required: true, message: $t('Mine_Address_Input_Reciever') }]"
        />

        <van-field
          v-model="dataform.contactphone"
          :name="$t('Mine_Address_Phone')"
          :label="$t('Mine_Address_Phone')"
          :placeholder="$t('Root_Login_Phone_Hint_Cut')"
          :rules="[{ required: true, message: $t('Root_Login_Phone_Hint_Cut') }]"
        />

        <van-field
          readonly
          clickable
          name="picker"
          :value="dataform.provinceName"
          :label="$t('Common_Province')"
          :placeholder="$t('Order_Choose_Province')"
          :rules="[{ required: true, message: $t('Order_Choose_Province') }]"
          @click="showProvincePicker = true"
        />
        <van-popup v-model="showProvincePicker" position="bottom">
          <van-picker
            :columns="provinceList"
            :default-index="provinceIndex"
            @change="onProvinceConfirm"
            @cancel="showProvincePicker = false"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          name="picker"
          :value="dataform.cityName"
          :label="$t('Common_City')"
          :placeholder="$t('Order_Choose_City')"
          @click="queryCityList()"
          :rules="[{ required: true, message: $t('Order_Choose_City') }]"
        />
        <van-popup v-model="showCityPicker" position="bottom">
          <van-picker
            :columns="cityList"
            :default-index="cityIndex"
            @change="onCityConfirm"
            @cancel="showCityPicker = false"
          />
        </van-popup>


        <van-field
          v-model="dataform.zipcode"
          disabled
          :name="$t('Common_PostCode')"
          :label="$t('Common_PostCode')"
          :placeholder="$t('Mine_Address_Input_PostCode')"
        />

        <van-field
          v-model="dataform.street"
          rows="1"
          autosize
          type="textarea"
          :name="$t('Mine_Address_Detail')"
          :label="$t('Mine_Address_Detail')"
          :placeholder="$t('Mine_Address_Input_Address')"
          :rules="[{ required: true, message: $t('Mine_Address_Input_Address') }]"
        />



        <van-cell center :title="$t('AddAddressVC_setupDefaultAddress')">
          <van-switch active-value="Y" active-color="red" inactive-value="N" v-model="dataform.isDefault" slot="right-icon" size="24" />
        </van-cell>

        <div style="margin: 16px;">
          <van-button class="van-action-save-address" native-type="submit" round block type="danger" >
            {{ $t('Mine_Save_Address') }}
          </van-button>
        </div>

      </van-form>

      <van-cell style="border-radius:10px" center :label="$t('AddAddressVC_WarmPrompt_content')" >
        <template #title>
          <span style="color: #F78E1E">{{$t('AddAddressVC_WarmPrompt_Tips')}}</span>
        </template>
      </van-cell>

    </div>
  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { productService, preorderService, addressService} from '@/common/api'
import { Toast } from 'vant'

export default {
  name: 'addressEdit',
  components: {
  },
  computed: {
  },
  data () {
    return {
      dataform:{
        addressid: '',
        contactname:'',
        contactphone: '',
        provinceid: '',
        provinceName:'',
        cityid: '',
        cityName:'',
        street: '',
        zipcode: '',
        isDefault: 'Y'
      },
      originProvinceList:[],
      provinceList:[],
      showProvincePicker: false,
      provinceIndex: 0,

      originCityList:[],
      cityList:[],
      showCityPicker: false,
      cityIndex: 0
    }
  },
  methods: {
    //选择省份
    onProvinceConfirm(picker,value,index) {
      this.dataform.provinceName = value;
      this.originProvinceList.forEach( item => {
        if(item.name == value){
          this.dataform.provinceid = item.id
        }
      })
      addressService.getCityList({privinceid:this.dataform.provinceid}).then(res => {
        this.cityList = []
        this.dataform.cityid = ''
        this.dataform.cityName = ''
        this.dataform.zipcode = ''

        if(res.code == '0'){
          this.originCityList = res.list
          res.list.forEach( item => {
            this.cityList.push(item.name)
          })
        }
      }).catch(e => {
    
      })
      // this.showProvincePicker = false;
    },

    //查询城市,必须带上省份id
    queryCityList(){
      if(this.dataform.provinceid){
        this.cityList = []
        addressService.getCityList({privinceid:this.dataform.provinceid}).then(res => {
          if(res.code == '0'){
            this.originCityList = res.list
            res.list.forEach( item => {
              this.cityList.push(item.name)
            })
            this.showCityPicker = true
          }
        }).catch(e => {
      
        })
      }else{
        this.$Toast(this.$t('Order_Choose_Province'))
      }
    },

    //选择城市
    onCityConfirm(picker,value,index) {
      this.dataform.cityName = value;
      this.originCityList.forEach( item => {
        if(item.name == value){
          this.dataform.cityid = item.id
          this.dataform.zipcode = item.postCode
        }
      })
      // this.showCityPicker = false;
    },

    //保存或修改地址
    saveAddress(){
      if (!/^\d{9,11}$/.test(this.dataform.contactphone)) {
        Toast(this.$t('AddAddressVC_invalidPhone'))
        return
      }
      if(this.dataform.addressid){
        addressService.modify(this.dataform).then(res => {
          if(res.code == '0'){
            this.$router.push({path: '/addressList'})
          }
        }).catch(e => {
      
        })
      }else{
        addressService.add(this.dataform).then(res => {
          if(res.code == '0'){
            this.$router.push({path: '/addressList'})
          //   this.originProvinceList = res.list
          //   res.list.forEach( item => {
          //     this.provinceList.push(item.name)
          //   })
          }

        }).catch(e => {
      
        })
      }
    },

    //修改地址的时候取索引跳回原来的列表索引
    getArrayIndex(arr, obj) {
      var i = arr.length;
      while (i--) {
          if (arr[i] === obj) {
              return i;
          }
      }
      return -1;
    }
  },
  mounted(){
    let data = this.$route.params.dataform

    if(data){
      //修改
      this.dataform = {
        ...data,
        addressid: data.id,
        provinceid: data.baseProvinceId,
        provinceName:data.province,
        cityid: data.baseCityId,
        cityName: data.city,
        zipcode: data.zipCode,
        isDefault: data.default,
        contactname: data.contactName,
        contactphone: data.contactPhone
      }

      addressService.getProvinceList().then(res => {
        if(res.code == '0'){
          this.originProvinceList = res.list
          res.list.forEach( item => {
            this.provinceList.push(item.name)
          })
          let getIndex = this.getArrayIndex(this.provinceList,this.dataform.provinceName)
          if(getIndex != -1){
            this.provinceIndex = getIndex
          }
        }
      }).catch(e => {
    
      }) 

      addressService.getCityList({privinceid:this.dataform.provinceid}).then(res => {
        this.cityList = []
        if(res.code == '0'){
          this.originCityList = res.list
          res.list.forEach( item => {
            this.cityList.push(item.name)
          })
          
          let getIndex = this.getArrayIndex(this.cityList,this.dataform.cityName)
          if(getIndex != -1){
            this.cityIndex = getIndex
          }
        }
      }).catch(e => {
    
      })

      console.log(this.dataform)
    }else{
      //新增
      addressService.getProvinceList().then(res => {
        if(res.code == '0'){
          this.originProvinceList = res.list
          res.list.forEach( item => {
            this.provinceList.push(item.name)
          })
        }
      }).catch(e => {
    
      })  
    }
    // let addressId = this.$route.query.addressId
    // if(addressId){
      
    // }else{
    //   addressService.getProvinceList().then(res => {
    //     if(res.code == '0'){
    //       this.originProvinceList = res.list
    //       res.list.forEach( item => {
    //         this.provinceList.push(item.name)
    //       })
    //     }

    //   }).catch(e => {
    
    //   })
    // }
  },
  created () {
  
  },
}
</script>

<style scoped>
.van-form > .van-cell:first-child {
  border-top-left-radius:10px  !important;
  border-top-right-radius:10px !important;
}
/* .van-form > .van-cell:last-child {
  border-top-left-radius:10px !important;
  border-top-right-radius:10px  !important;
} */

	.van-button--danger{
		background-color:#F78E1E !important;
		border: 1px solid #F78E1E !important;
	}
</style>
