const t = {}

t.loading = 'กำลังดาวน์โหลด...'

t.brand = {}
t.brand.lg = 'Rabbit'
t.brand.mini = 'Rabbit'

t.Good_Buy_Now = "ซื้อเลย"
t.Add_To_Cart = "เพิ่มไปยังรถเข็น"
t.Open_app = "បើកកម្មវិធី"
t.Good_Temporarily_Out = "สินค้าหมด"
t.Good_Item_Detail = "รายละเอียดสินค้า";

t.Mine_Login = "เข้าสู่ระบบ";
t.Common_Login = "คลิกเพื่อเข้าระบบ";
t.Out_Login = "ออกจะระบบ";

t.Common_Logout = "คุณต้องการออกจากระบบหรือไม่?";
t.Common_Confirm = "ออกจากระบบ";
t.Common_Cancel = "ยกเลิก";

t.PCLMoblieInputVC_Title = "เข้าสู่ระบบ";

t.PCLMoblieInputVC_desc = "โปรดใส่เบอร์มือถือ";
t.PCLMoblieInputVC_InputFieldPlaceholder = "เบอร์มือถือ";
t.PCLMoblieInputVC_sendCodeBtnTitle = "ขอรหัส SMS";

t.PCLSMSInputVC_againBtnNoGetSMSTitle = "ฉันยังไม่ได้รับรหัส";

t.Order_Buyer_Comments = "ข้อความ:";
t.Order_Optional = "เลือก";
t.Order_Confirm_Order = "ยืนยันคำสั่งซื้อ";
t.Order_inputMessage = "ส่งข้อความถึงผู้ขาย/Rabbit";
t.Order_Out_Of_Stock = "（สินค้าหมด）";
t.Order_In_Stock = "มีสินค้า {0} ชิ้น";

t.Mine_My_Address = "ที่อยู่การจัดส่ง";
t.Common_Default_address = "ที่อยู่หลักสำหรับจัดส่งสินค้า";
t.Order_Add_Address = "เพิ่มที่อยู่ หรือสร้างที่อยู่สำหรับจัดส่งสินค้า";
t.Mine_Address_New = "เพิ่มที่อยู่ใหม่";
t.Mine_Save_Address = "บันทึก";
t.Mine_Edit_Address =  "แก้ไข";
t.Mine_Shipping_Address = "เพิมที่อยู่การส่ง";

t.Mine_Address_Reciever =  "ผู้รับ";
t.Mine_Address_Phone =  "เบอร์โทรศัพท์";
t.Mine_Address_Area =  "ที่อยู่";
t.Mine_Address_Detail =  "รายละเอียด";
t.Mine_Address_Option =  "-เลือก-";
t.Mine_Address_Input_Reciever =  "ใส่ชื่อผู้รับ";
t.Root_Login_Phone_Hint_Cut = "หมายเลขโทรศัพท์";
t.Mine_Address_Input_Address =  "รายละเอียดที่อยู่";
t.Mine_Address_Input_PostCode =  "รหัสไปรษณีย์";

t.Common_Province = "จังหวัด";
t.Common_City = "เขต / อำเภอ";
t.Common_PostCode = "รหัสไปรษณีย์";

t.Order_Choose_Province =  "เลือกจังหวัด";
t.Order_Choose_City =  "เลือกเขต / อำเภอ";


t.Order_Submit = "สั่งซื้อ";

t.Mine_Order_Payment = "ที่ต้องชำระ";
t.Mine_Order_Shipping = "ระหว่างขนส่ง";
t.Mine_Order_Complete = "สำเร็จแล้ว";
t.Mine_Order_Cancel = "ยกเลิกแล้ว";
t.Mine_Order_REFUNDING = "คืนสินค้า / คืนเงิน";
t.Mine_Order_REFUNDED = "คืนสินค้า / คืนเงินแล้ว";


//地址页面
t.AddAddressVC_WarmPrompt_Tips = "ข้อเสนอแนะ:";
t.AddAddressVC_WarmPrompt_content = "ผู้จัดส่งจะส่งสินค้าตามที่อยู่ที่ระบุ เพื่อเพิ่มความสะดวกและประสิทธิภาพในการส่งสินค้าถึงมือคุณ โปรดระบุข้อมูลให้ถูกต้อง ขอให้คุณช้อปปิ้งอย่างมีความสุข!";
t.AddAddressVC_setupDefaultAddress = "ตั้งค่าเป็นเป็นที่อยู่เริ่มต้นสำหรับจัดส่งสินค้า";

t.Order_Add_Addresses = "ตัวเลือกการจัดส่ง";

t.Order_Total_goodNum_1 = "ยอดรวม";
t.Order_Total_goodNum_2 = "จำนวนสินค้า";
t.Order_Total_TotalTitle = "ยอดรวม:";

t.Order_Total_Amount_1 = "ยอดรวมทั้งสิ้น ";
t.Order_Total_Amount_2 = "จำนวนสินค้า, ยอดรวม: ";

t.Order_Detail = "รายละเอียด";


t.Mine_My_Order = "รายการสั่งซื้อของฉัน";

t.Root_Cart = "รถเข็น";
t.Root_Cart_Navi = "รถเข็น";
t.Cart_Empty = "ไม่มีสินค้าในรถเข็น";
t.Cart_Go = "เลือกซื้อสินค้า";
t.Cart_Add = "เพิ่มไปยังรถเข็น";
t.Cart_Settlement = "ชำระเงิน";
t.Good_Select_All = "ทั้งหมด";
t.Good_Maximum = "ขออภัย จำนวนสินค้าไม่เพียงพอ";
t.Common_Complete = "สำเร็จ";
t.Common_Delete = "ตกลง";
t.Good_Delete_Item = "คุรแน่ใจว่าต้องการลบหรือไม่?";




t.PayCompleteVC_NaviTilte = "ชำระเงินสำเร็จ";
t.PayCompleteVC_OrderSuccess = "ชำระเงินสำเร็จแล้ว!";
t.PayCompleteVC_OrderSuccess_COD = "สั่งซื้อสำเร็จ!";
t.PayCompleteVC_btnTitle_ViewOrder = "ดูคำสั่งซื้อ";
t.PayCompleteVC_btnTitle_Keepwalking = "เลือกซื้อต่อ";

t.Order_Detail_paymentTime = "ชำระภายในระยะเวลา:";
t.Order_Payment = "ช่องทางชำระเงิน";
t.Order_Pay_At_The_Store_Amount = "ชำระผ่านหน้าร้าน: ";
t.Order_Pay_OnDelivery_Amount = "ยอดรวม:";// "รวมทั้งสิ้น：";
t.Order_Number = "หมายเลขคำสั่งซื้อ:";
t.Order_Time_Day = "วันและเวลาที่ทำรายการ:";
t.Order_Payment_Method = "ช่องทางการชำระเงิน:";
t.Order_Pay_Online = "ชำระเงินออนไลน์";
t.Order_Pay_At_The_Store = "ชำระผ่านหน้าร้าน";
t.Order_Pay_Pay_After_GetGood = "ชำระเงินปลายทาง";
t.Order_Ship_Method = "ตัวเลือกในการจัดส่ง:";
t.Order_logistics_ID = "รหัสขนส่ง:";
t.Order_Real_Payment = "ยอดชำระจริง:";
t.Order_Pickup_At_Store = "รับสินค้าหน้าร้าน";
t.Order_Fast_Delivery = "การจัดส่ง";
t.Order_Amout_Payment = "ยอดชำระทั้งหมด";
t.Order_Select_Payment_Method = "เลือกวิธีชำระเงิน"


t.Check_order_payment_status = "ตรวจสอบสถานะการจัดส่ง"
t.Checking_order_payment_status = "กำลังตรวจสอบการชำระเงิน โปรดอย่าปิดหน้าต่างนี้"
t.Continue_Shopping = 'เลือกซื้อต่อ'
t.Index_home = 'หน้าแรก'
t.My_Orders = 'รายการคำสั่งซื้อของฉัน'
t.Profile = 'บัญชี'
t.Country = 'ประเทศ'
t.Get_Code = 'รับรหัสยืนยัน'
t.Verification_Code = "รหัสยืนยัน"
t.ResendSms = "ส่งอีกครั้ง"
t.Coupon_Code ="โค้ด"
t.Commission_Goods = 'ค่าคอมมิชชั่นสินค้า'
t.Voucher ="គូប៉ុង"

t.add = 'เพิ่ม'
t.delete = 'ลบรายการ'
t.deleteBatch = 'ลบ'
t.update = 'แก้ไข'
t.query = 'ตรวจสอบ'
t.export = 'ส่งออก'
t.handle = 'ทำรายการ'
t.confirm = 'ตกลง'
t.cancel = 'ยกเลิก'
t.logout = 'ออกจากระบบ'


/**共用**/
t.public={}
t.public.set = 'ตั้งค่า'
t.public.edit = 'แก้ไข'
t.public.add = 'เพิ่ม'
t.public.delete = 'ลบ'
t.public.update = 'อัปเดต'
t.public.modify = 'ปรับเปลี่ยน'
t.public.success = 'สำเร็จ'
t.public.fail = 'ไม่สำเร็จ'
t.public.error = 'ผิดพลาด'
t.public.sort = 'ประเภท'
t.public.tips = 'แนะนำ'
t.public.operation = 'ปฏิบัติการ'
t.public.yes = 'ตกลง'
t.public.no = 'ยกเลิก'
t.public.submit = 'ตั้งค่า'
t.public.reset = 'รีเซต'
t.public.confirm = 'ตกลง'
t.public.cancel = 'ยกเลิก'
t.public.back = 'ถอยกลับ'
t.public.title = 'หัวข้อ'
t.public.content = 'เนื้อหา'
t.public.picture = 'รูปภาพ'
t.public.search = 'ค้นหา'
t.public.query = 'ตรวจสอบ'
t.public.output = 'ส่งออก'
t.public.icon = 'ไอคอน'
t.public.selectedIcon = 'เลือกไอคอน'
t.public.askDelete = 'การดำเนินการนี้จะถูกลบอย่างถาวร คุณต้องการทำรายการต่อหรือไม่?'
t.public.chinese = 'ภาษาจีน'
t.public.english = 'ภาษาอังกฤษ'
t.public.khmer = 'ภาษาเขมร'
t.public.createTime = 'เวลาที่ทำรายการ'
t.public.finishTime = 'เวลาที่สำเร็จ'
t.public.status = 'สถานะ'
t.public.valid = 'ถูกต้อง'
t.public.Used = 'បានប្រើ'
t.public.invalid = 'ไม่ถูกต้อง'
t.public.iSee = 'ตกลง'
t.public.close = 'ปิด'
t.public.remark = 'หมายเหตุ'
t.public.value = 'มูลค่า'
t.public.baseInformation = 'ข้อมูลพื้นฐาน'
t.public.all = 'ทั้งหมด'
t.public.name = 'ชื่อ'
t.public.numberSort = 'โปรดใส่หมายเลขที่มากกว่าจำนวนก่อนหน้า'
t.public.advert = 'อ้างอิง'
t.public.platform = 'แพลตฟอร์ม'
t.public.noData = 'ไม่มีข้อมูล'
t.public.operName = 'ชื่อผู้ดำเนินการ'
t.public.createDate = 'วันที่ทำรายการ'
t.public.updateDate = 'วันที่อัปเดต'
t.public.price = 'ราคา'
t.public.resetSearchKeyword = 'แก้ไขการตั้งค่าคำค้นหา'
t.public.pleMobile = 'โปรดระบุหมายเลขโทรศัพท์'
t.public.pleCode = 'โปรดระบุรหัสยืนยัน'
t.public.noRecording = 'ยังไม่มีการบันทึก'
t.public.Searchproducts = 'ស្វែងរក​លទ្ធផល'
t.public.Searchhistory = 'ប្រវត្តិ​ស្វែងរក'
t.public.Popularsearch = 'ស្វែងរក​ជា​មូលដ្ឋាន'
t.public.Nosearchrecord = 'គ្មាន​ថត​ស្វែងរក'
/**支付页面**/
t.payOrder={}
t.payOrder.barName = 'ยอดคงเหลือ'
t.payOrder.payableBalance= 'ยอดคงเหลือ'
t.payOrder.balance= 'คงเหลือ'
t.payOrder.paymentMethod= 'เลือกวิธีชำระเงิน'
t.payOrder.creditCard='บัตรเครดิต'
t.payOrder.creditDdbitCard='บัตรเครดิต/ บัตรเดบิต'
t.payOrder.mobileMode='โมบายแบงก์กิ้ง (ไม่มีค่าธรรมเนียม)'
t.payOrder.payNow='ชำระตอนนี้'

/**landingPage**/
t.landingPage={}

/**首页**/
t.home={}
t.home.finishedText="ไม่มีรายการแสดง"
t.home.loosingText="เลื่อนลงเพื่อโหลดใหม่"
t.home.loadingText="กำลังโหลด"
t.home.successText="ดาวน์โหลดสำเร็จ"
/**佣金商品**/
t.personalCenter={}
t.personalCenter.promoCode="โค้ดส่วนลด "
t.personalCenter.commission="เงินคืน"
t.personalCenter.copySuccess="คัดลอกสำเร็จ"
t.personalCenter.copyError="คัดลอกไม่สำเร็จ"
t.personalCenter.copyButton="คัดลอก"
t.personalCenter.whetheroutLogin="คุณต้องการออกจากระบบหรือไม่?"
t.personalCenter.Promoter="ผู้ลงโฆษณา"
t.personalCenter.Discountprice="ราคาพร้อมรหัส"
t.personalCenter.Commissionrecord="บันทึกค่าคอมมิชชั่น"
t.personalCenter.rebate="ส่วนลด"
t.personalCenter.torebate="ชำระคืน"
t.personalCenter.orderafterTipe="สามารถทำรายการคืนเงินภายใน 24 ชม. หลังทำรายการสั่งซื้อสำเร็จ"
t.personalCenter.failedRebate="การคืนเงินล้มเหลม"
t.personalCenter.refunded="คืนเงินแล้ว"
t.personalCenter.gained="ได้รับแล้ว"
t.personalCenter.ontheway="กำลังดำเนินการ"
t.personalCenter.failed="ล้มเหลว"
t.personalCenter.myWallet="วอลเล็ท"
t.personalCenter.myBalance="ยอดคงเหลือ"
t.personalCenter.withdraw="ถอน"
t.personalCenter.understand="ตกลง / ยืนยัน"
t.personalCenter.giftAmount="มูลค่าของรางวัล"
t.personalCenter.giftText="มูลค่าของรางวัลสามารถใช้ได้เฉพาะใน Rabbit APP เท่านั้น และไม่สามารถแลกเปลี่ยนเป็นเงินสดได้ เช่น รางวัลจากกิจกรรม Shake & Win, รางวัลสำหรับผู้ลงทะเบียนใช้งานครั้งแรก"
t.personalCenter.frozenAmount="จำนวนเงินที่อยู่ระหว่างดำเนินการ"
t.personalCenter.frozenText="จำนวนเงินที่ได้จากยอดขายจากการชวนเพื่อนซื้อสินค้า สามารถถอนได้เมื่อคำสั่งซื้อของเพื่อนสำเร็จและรีวิวสินค้า"
t.personalCenter.withdrawalsRecord="สรุปรายการถอน"
t.personalCenter.withdrawableCash="จำนวนที่สามารถถอนเป็นเงินสดได้" 
t.personalCenter.withdrawalAmount="จำนวนเงินที่ถอน"
t.personalCenter.withdrawalAmountTip="ไม่น้อยกว่า"
t.personalCenter.confirmWithdrawal="ตกลง / ยืนยัน"
t.personalCenter.bottomTip="เคล็ดลับ : ควรตรวจสอบความถูกต้องของข้อมูลก่อนทำการยืนยัน เพราะหลังจากยืนยันแล้ว จะไม่สามารถแก้ไขข้อมูลดังกล่าวได้"
t.personalCenter.withdrawnSuccessfully="ทำการถอนสำเร็จ"
t.personalCenter.underReview="การตรวจสอบ"
t.personalCenter.examinationPassed="ผ่าน"
t.personalCenter.completed="สำเร็จ"
t.personalCenter.ConfirmText="ยืนยันเลขบัญชีที่ทำการถอน"
t.personalCenter.changeAccount="เพิ่มบัญชี"
t.personalCenter.formTitle="กรุณากรอกเลขบัญชีที่ทำการถอน"
t.personalCenter.bankName="ธนาคาร"
t.personalCenter.account="ประเภทบัญชี"
t.personalCenter.name="ชื่อบัญชี"
t.personalCenter.protipText="บันทึกบัญชีเพื่อใช้สำหรับการถอนครั้งต่อไป"
t.personalCenter.withdrawalAmountTipTwo="เกินจำนวนที่สามารถถอนได้"
t.personalCenter.successtip="เคล็ดลับ : ควรตรวจสอบความถูกต้องของข้อมูลก่อนทำการยืนยัน เพราะหลังจากยืนยันแล้ว จะไม่สามารถแก้ไขข้อมูลดังกล่าวได้"
t.personalCenter.successTitle="ดำเนินการเรียบร้อย"
t.personalCenter.selectrecordingbutton="ดูประวัติการถอนเงิน"
t.personalCenter.Changepassword="เปลี่ยนรหัสผ่าน"
t.personalCenter.pleaseUsername="กรุณาใส่รหัสยืนยัน"
t.personalCenter.pleasePayword="กรุณาระบุรหัสผ่าน 6 หลัก"
t.personalCenter.pleasePaywordagain="กรุณาระบุรหัสผ่านอีกครั้ง"
t.personalCenter.tips="เคล็ดลับ"
t.personalCenter.payProtips="กรุณาตั้งรหัสผ่านเพื่อความปลอดภัยของบัญชีของท่าน"
t.personalCenter.setButton="ตั้งตอนนี้"
t.personalCenter.nextButton="ครั้งถัดไป"
t.personalCenter.differentText="รหัสผ่านผิด"
t.personalCenter.firstConfirmtitle="ยินดีต้อนรับสู่แพนโด้"
t.personalCenter.firstConfirmcontent="คุณแน่ใจที่จะเป็นผู้โปรโมทของแพนโด้แล้วใช่ไหม?"
t.personalCenter.firstConfirmbuttom="ยืนยัน"
t.personalCenter.secondConfirmtitle="ยินดีด้วย คุณได้เป็นผู้โปรโมทของแพนโด้แล้ว"
t.personalCenter.secondConfirmcode="โค้ดสุดพิเศษของคุณคือ : "
t.personalCenter.secondConfirmcontent="ตรวจดูวิธีการทำเงินได้เลย"
t.personalCenter.secondConfirmbuttom="ตรวจเดี๋ยวนี้"
t.personalCenter.BecomePromoter="ป็นผู้โปรโมทกับเรา"
t.personalCenter.BecomePromotertoptitle="มาร่วมเป็นผู้โปรโมทสินค้าและรับผลตอบแทนด้วยมือคุณ!"
t.personalCenter.BecomePromotertoptip="เข้าร่วมกับเรา"
t.personalCenter.BecomePromotertoptextone="-ผู้โปรโมทสินค้าของแพนโด้เป็นอย่างไร?-"
t.personalCenter.BecomePromotertoptexttwo="เป็นผู้โปรโมทกับเราจะได้รับสิทธิพิเศษในการรับโค้ดลดราคาที่สูงกว่าการขายทั่วไป"
t.personalCenter.BecomePromotertoptextthree="-โค้ดลดราคาสูงเป็นอย่างไร?-"
t.personalCenter.BecomePromotertoptextfour="1.คุณจะได้รับโค้ดลดราคาพิเศษและนำไปวางไว้ที่สินค้าบนร้านทางการของแพนโด้ 2.หลังจากคุณวางโค้ดของคุณและมีผู้ซื้อผ่านโค้ดของคุณ คุณจะได้รับส่วนแบ่งการขายจากเรา"
t.personalCenter.BecomePromotertoptextFives="-แล้วจะเป็นผู้โปรโมทได้อย่างไร?-"
t.personalCenter.BecomePromotertoptextsix="กดที่ลิ้งด้านล่างแล้วเข้าร่วมกับเราได้เลย"
t.personalCenter.BecomePromotertopbottomtip="-สิ้นสุด-"
/**翻译结束**/
t.personalCenter.pleasebankTips="กรุณาเลือกธนาคาร"
t.personalCenter.PleasecardTips="กรุณาใส่หมายเลขบัตรที่ถูกต้อง"
t.personalCenter.pleaseusernameTips="กรุณาใส่ชื่อบัญชี"
t.personalCenter.paywordTitle="ชำระด้วยวอลเล็ท"
t.personalCenter.paywordTip="โปรดระบุรหัสวอลเล็ทของคุณ"
t.personalCenter.Deadline="ផុតកំណត់"
t.personalCenter.ShareButtom="ចែកប័ណ្ណរបស់អ្នក | អេម"
t.personalCenter.Productlist="បញ្ជីផលិតផល"
t.personalCenter.Coupon="គូប៉ុង"
t.personalCenter.gavetip="បានផ្តល់ឱ្យអ្នកនូវប័ណ្ណមួយ"
t.personalCenter.YourEarne="ក្រវិលរបស់អ្នក"
t.personalCenter.receivedSuccessfuily="ទទួលបានជោគជ័យ!"
t.personalCenter.Notyetuse="មិន​ប្រើ​នៅ​ឡើយ"
t.personalCenter.Usehistory="ប្រើ​ប្រវត្តិ"
t.personalCenter.notcouponTip="ខ្ញុំ​របស់​អ្នក​មិន​មាន​កូប៉ុន"
t.personalCenter.moreinfo="ព័ត៌មាន​បន្ថែម"
t.personalCenter.Canusecoupon="អាច​ប្រើ​កូប៉ុន"
t.personalCenter.Canbeusecoupon="មិន​អាច​ប្រើ​កូប៉ុន"
t.personalCenter.useimmediately="ប្រើ​ភ្លាមៗ"
t.personalCenter.Sharetips="ប្រើ​កម្មវិធី​រុករក​នៅ​ក្នុង​ជ្រុង​ខាង​លើ​ស្ដាំ​ដើម្បី​ចែក​រំលែក"
t.personalCenter.iosSharetips="ចុច​លើ​រូបតំណាង​ចែក​រំលែក​ខាង​ក្រោម ដើម្បី​ចែក​រំលែក​ទំព័រ​នេះ & # 160; ។"

export default t

