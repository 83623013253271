import Vue from 'vue'
import App from './App.vue'
// import store from './store'
import axios from '@/plugin/axios'

import { createRouter } from "./router";
import { createStore } from "./store";
import { sync } from "vuex-router-sync";

// i18n
import i18n, { messages } from '@/i18n'

//引入vant-ui
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

//单独引入轻提示
import { Toast } from 'vant';
// Vue.use(Toast);
Vue.prototype.$Toast = Toast
//复制文本
import Clipboard from 'clipboard';
Vue.prototype.Clipboard = Clipboard
//引入nutui
// import NutUI from '@nutui/nutui';
// import '@nutui/nutui/dist/nutui.css';
// NutUI.install(Vue);

//element-ui(!尽量不使用)
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)
// 引入模块后自动生效
// 处理pc端访问的h5的问题
if (process.env.VUE_ENV == 'client') {
  require('@vant/touch-emulator')
}

Vue.config.productionTip = false

import '@/assets/css/custom.css';

// 挂载全局axios
Vue.prototype.$axios = axios

// 全局多语言设置
Vue.prototype.$languages = Object.keys(messages).map(langlage => ({
  label: messages[langlage]._lang,
  value: langlage
}))

import { Dialog } from 'vant';
// 全局注册vant Dialog
Vue.use(Dialog);
Vue.prototype.$Dialog = Dialog

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true


// new Vue({
//   i18n,
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// 导出一个工厂函数，用于创建新的
// 应用程序、router 和 store 实例
export function createApp() {

  // 创建 router 实例
  const router = createRouter()
  const store = createStore();

  const app = new Vue({
    // 根实例简单的渲染应用程序组件。
    i18n,
    router,
    store,
    render: h => h(App)
  })
  return { app, router, i18n, store }
}

// promise.finally Polyfill
if (!Promise.prototype.finally) {
  Promise.prototype.finally = function (callback) {
    let P = this.constructor;
    return this.then(
      value => P.resolve(callback()).then(() => value),
      reason =>
        P.resolve(callback()).then(() => {
          throw reason;
        })
    );
  };
}
