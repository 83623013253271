<template>
	<div>
		<!--<van-nav-bar fixed title="Help">
			<template #left>
				<van-icon name="arrow-left" size="18" color="#000000" @click.native="$router.go(-1)" />
			</template>
		</van-nav-bar>-->
		<div class="content">
			<p class="special">Why is there no prompt from the camera?</p>

			<p>1. Ensure that the lens is not blocked. If the camera lens has a protective film, remove it before you scan the QR code.</p>
			<P>2. Place the app QR code in front of the camera at a distance of 15 cm to 20 cm. Adjust the angle and distance until you hear a prompt.</P>

		</div>
	</div>
</template>

<script>
export default {
  name: 'details',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  tdk() {
    return {
      title: 'Help',
      ssrHeadAddInfo: {
        oglocale: 'en_US',
        ogtype: 'website',
        ogtitle: 'Help',
      }
      
    };
  },
  mounted(){
  },
  data () {
    return {
    }
  },
  created () {
  },
}
</script>

<style scoped="scoped">
	.content {
		width: 95%;
		margin: 20px auto;
		font-size: 16px;
		color: #101010;
	}
	
	.content span {
		color: #F78E1E;
		cursor: pointe
	}
	
	.special {
		font-size: 17px;
		color: #000000 !important;
		font-weight: 600;
	}
</style>