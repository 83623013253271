<template>
	<div>

		<div class="background-img">
			<!--<div style="padding-top:15px;text-align:center;">
				<img width="150px" src="../../../static/images/loading5-2.gif" />
			</div>-->
			<div class="block-register">
				<div class="block-register__country">
					<!--<van-field readonly clickable name="picker" :value="chooseValue" label="国家" placeholder="点击选择国家" @click="showPicker = true" />-->
					<div class="block-register__country__img">
						<img :src="chooseImg" />
					</div>
					<div class="block-register__country__value">
						{{chooseValue}}
					</div>
				</div>

				<div class="block-register__mobile">
					<van-field :autofocus="true" clearable v-model="mobile" placeholder="กรุณาใส่เบอร์โทรศัพท์">
					</van-field>
					<van-button slot="button" :disabled="smsBtnDisabled" @click="sendSms" type="default" size="small">{{btntxt}}</van-button>
				</div>
				<div class="block-register__code">
					<van-field clearable v-model="code" placeholder="กรุณาใส่รหัสยืนยัน">
					</van-field>
				</div>
				<div class="block-register__register">
					<van-button slot="button" type="danger" size="small" @click="registAndLogin">ลงทะเบียน</van-button>
				</div>
			</div>
			<div class="bottom-tip" @click="gotoShopping()">
				คุณมีแอป Rabbit แล้ว เปิดเลย>
			</div>
			<!--
			<div style="padding-top:25px;text-align:center;">
				<van-button slot="button" :disabled="smsBtnDisabled" @click="sendSms" type="danger">{{btntxt}}</van-button>
			</div>-->
		</div>
		<van-popup v-model="showPicker" position="bottom" :closeable="closeable">
			<van-picker confirm-button-text="ยืนยัน" cancel-button-text="ตกลง" show-toolbar :columns="columns" :default-index="1" value-key="dasdas" @confirm="onConfirm" @cancel="showPicker = false" />
		</van-popup>
		<van-popup v-model="graphicalCode" :closeable="closeable">
			<div class="graphical-code">
				<img class="graphical-code__img" :src="verImg" @click="verifyImage" />
				<van-field clearable v-model="picCode" placeholder="ระบุรหัสยืนยัน">
				</van-field>
				<van-button slot="button" type="danger" size="small" @click="sendCode">ส่งข้อความ</van-button>
				<div class="bottom-text">
					การลงชื่อเข้าใช้ หมายถึงการยอมรับข้อตกลงผู้ใช้บัญชีอะคูย่า และนโยบายความเป็นส่วนตัว
					<!--Signing in means agreeing to the Rabbit account <span>User Agreement</span> and <span>Privacy Policy</span>-->
				</div>
			</div>
		</van-popup>
		<van-popup v-model="registeredType" :closeable="closeable">
			<div class="graphical-code">
				<p class="registered-tip">บัญชีนี้ได้ลงทะเบียนเป็นสมาชิกของอะคูย่าแล้ว โปรดลงชื่อเข้าใช้เพื่อรับสิทธิพิเศษเฉพาะคุณ!</p>
				<van-button slot="button" type="danger" size="small" @click="gotoShopping()">ดูเลย</van-button>
			</div>
		</van-popup>
		<van-popup v-model="registerSuccessType" @close="registerSuccessType=true">
			<div class="graphical-code">
				<img class="graphical-code__fireworks" src="../../../static/images/fireworks.png" />
				<p class="graphical-code__title">ยินดีด้วยนะ คุณได้รับเงิน 1099 บาท!</p>
				<p class="registered-tip">ยินดีด้วย! คุณลงทะเบียนสำเร็จ! สมาชิกใหม่รับเลยเงินรางวัล 1,059 บาท ลงเข้าใช้เพื่อรับเลยตอนนี้!</p>
				<van-button slot="button" type="danger" size="small" @click="gotoShopping()">เช็กเลย!</van-button>
			</div>
		</van-popup>
		<van-overlay :show="show">
			<div class="text-tip">
				กรุณาเลือก "เบราว์เซอร์" เพื่อเปิด
			</div>
			<div class="img-tip">
				<img src="../../../static/images/Instructions.png" style="width: 100%;height: 100%;" />
			</div>
		</van-overlay>
	</div>

</template>

<script>
	import { productService, appService, preorderService,ocdev_api } from '@/common/api'
	import thailandImg from '../../assets/thailand.png'
	import chinaImg from '../../assets/china.png'
	import { Dialog } from 'vant';
	import mixinJumpModule from '@/mixins/jump-module'

	export default {
		name: 'login',
		components: {},
		computed: {},
		mixins: [ mixinJumpModule ],
		data() {
			return {
				chooseValue: '+66',
				chooseImg: thailandImg,
				country: 'TH',
				columns: [
					'<img style="width:15px" src="' + thailandImg + '"/> +66',
					'<img style="width:15px" src="' + chinaImg + '"/> +86'
				],
				showPicker: false,
				showLoading: true,
				mobile: "",
				btntxt: "ส่งรหัส",
				smsBtnDisabled: false,
				time: 0,
				code: '',
				graphicalCode: false,
				picCode: '',
				sendsmsAgain: false, //是否重发
				registeredType: false, //已注册
				registerSuccessType: false, //注册成功
				appstoreUrl: process.env.config.VUE_APP_IOS_URL,
      	googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
				channel: 'DEFAULT',
				scene: 'O2O',
				visitId: '',
				verImg: '',
				verRegist: '',
				closeable: true,
				show: false,
				showType: false,
			};
		},
		mounted() {
			if(this.$route.query.channel) {
				this.channel = this.$route.query.channel
			}
			this.channelVisitAdd()
			this.channelType() //打开类型
		},
		methods: {
			channelType() {
				var userAgent = navigator.userAgent || navigator.vendor || window.opera; //取得浏览器的userAgent字符串
				var isSafari = userAgent.indexOf("Safari") > -1 &&
					userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
				var isChrome = userAgent.indexOf("Chrome") > -1 &&
					userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
				var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //IE
				var firefox = userAgent.indexOf("Firefox") > -1; //火狐
				var isOpera = userAgent.indexOf("Opera") > -1; //Opera
				var ua = navigator.userAgent.toLowerCase();
				var weixin = ua.match(/MicroMessenger/i) == "micromessenger" //判断微信打开
				if(isSafari) {
					this.showType = true
				}
				if(isChrome) {
					this.showType = true
				}
				if(isIE) {
					this.showType = true
				}
				if(firefox) {
					this.showType = true
				}
				if(isOpera) {
					this.showType = true
				}
			},
			verifyImage() {
				var num = Math.ceil(Math.random() * 10);
				this.verImg = ocdev_api + '/api/verify/image?' + 'country=' + this.chooseValue + '&' + 'mobile=' + this.mobile + '&num' + num
			},
			channelVisitAdd() {
				this.$axios({
						method: 'post',
						url: ocdev_api + '/v1/channelVisit/visit/add',
						timeout: 6000, // 请求超时时间
						headers: {
							Accept: 'application/json',
							"content-type": "application/x-www-form-urlencoded",
						},
						data: {
							channel: this.channel,
							scene: this.scene
						}
					})
					.then(res => {
						console.log(res)
						this.visitId = res.data.id
					}).catch(() => {})
			},
			gotoShopping() {
				this.jumpToAppOrStore()
			},
			registAndLogin() {
				appService.registAndLogin({
					country: this.chooseValue,
					username: this.mobile,
					verifycode: this.code
				}).then(res => {
					if(res.code == '0') {
						console.log(res)
						this.registerSuccessType = true
						this.channelVisitRegist(res.data.user.id)
					}
				}).catch(e => {

				})
			},
			channelVisitRegist(userId) {
				this.$axios({
						method: 'post',
						url: ocdev_api + '/v1/channelVisit/regist',
						data: {
							channel: this.channel,
							scene: this.scene,
							visitId: this.visitId,
							userId: userId
						}
					})
					.then(res => {
						console.log(res)
						this.visitId = res.data.id
					}).catch(() => {})
			},
			//发送短信,目前写死中国号码规则,后面需要更换泰国号码规则
			sendSms() {
				if(this.showType) {
					var reg = /^[0-9\+\-\(\)]{9,20}$/
					if(this.mobile == '') {
						this.$Toast.fail("*กรุณาใส่หมายเลขโทรศัพท์")
					} else if(!reg.test(this.mobile)) {
						this.$Toast.fail("หมายเลขโทรศัพท์ไม่ถูกต้อง")
					} else {
						appService.isuserexist({
							country: this.chooseValue,
							username: this.mobile
						}).then(res => {
							if(res.code == '0') {
								if(res.data.exist == '1') {
									this.registeredType = true
								}
								if(res.data.exist == '0') {
									this.verifyImage()
									this.graphicalCode = true
								}
							}
						}).catch(e => {

						})
					}
				} else {
					this.show = true
				}

			},
			//发送严重码
			sendCode() {
				this.$axios({
						method: 'post',
						url: ocdev_api + '/api/verify/regist',
						data: {
							country: this.chooseValue,
							mobile: this.mobile,
							random: this.picCode
						}
					})
					.then(res => {
						if(res.code == '0') {
							this.time = 60
							this.smsBtnDisabled = true
							this.timer()
							this.graphicalCode = false
							this.picCode = ''
						}
					}).catch(() => {})

			},
			//处理国旗字符串问题
			onConfirm(value, index) {
				if(index == 0) {
					this.chooseValue = '+66';
					this.chooseImg = thailandImg
				} else if(index == 1) {
					this.chooseValue = '+86';
					this.chooseImg = chinaImg
				}
				this.showPicker = false;
			},

			//验证码的倒计时(这边应该用不上已经移到smscode)
			timer() {
				if(this.time > 0) {
					this.time--;
					this.btntxt = this.time + "s";
					setTimeout(this.timer, 1000);
				} else {
					this.time = 0;
					this.btntxt = "ส่งรหัส";
					this.smsBtnDisabled = false;
					this.sendsmsAgain = true;
				}
			},
		},
		created() {},
	}
</script>

<style scoped>
	.background-img {
		height: 100vh;
		background: url(../../../static/images/Asset_013.png);
		background-size: 100% 100%;
	}
	
	.block-register {
		width: 80%;
		margin: 0 auto;
		border: 1px solid #EFEDED;
		background: #FFFFFF;
		padding: 20px;
		position: relative;
		top: 47%;
	}
	
	.van-popup--bottom {
		left: initial !important;
	}
	
	.van-popup {
		max-width: 540px;
	}
	
	.van-number-keyboard {
		z-index: 3000
	}
	
	.block-register__country {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}
	
	.block-register__country__img {
		width: 25%;
		height: 30px;
		border: 1px solid #d5d5d5;
		background: #F8F8F8;
		display: flex;
		align-items: center;
	}
	
	.block-register__country__img img {
		width: 23px;
		height: 25px;
		display: block;
		margin: 0 auto;
	}
	
	.block-register__country__value {
		width: 70%;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #d5d5d5;
		background: #F8F8F8;
		color: #9F9F9F;
		font-size: 13px;
	}
	
	.block-register__mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 15px 0;
	}
	
	.block-register__mobile .van-cell {
		border: 1px solid #d5d5d5;
		background: #F8F8F8;
		padding: 5px 10px !important;
		width: 68%;
	}
	
	.block-register__mobile button {
		border: 1px solid #EE2523;
		padding: 2px 8px;
		height: auto;
		color: #EE2523 100%;
		font-size: 11px;
		width: auto;
	}
	
	.block-register__code {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.block-register__code .van-cell {
		border: 1px solid #d5d5d5;
		background: #F8F8F8;
		padding: 5px 10px !important;
		width: 100%;
	}
	
	.block-register__register {
		width: 100%;
		margin: 15px 0;
	}
	
	.block-register__register button {
		width: 100%;
	}
	
	.bottom-tip {
		color: #A1A1A1;
		font-size: 11px;
		text-align: end;
		width: 90%;
		margin: 0 auto;
		position: relative;
		top: 48%;
	}
	
	.graphical-code {
		width: 282px;
		padding: 25px;
		position: relative;
	}
	
	.graphical-code__img {
		width: 100%;
		height: 34px;
		display: block;
		margin: 0 auto;
	}
	
	.graphical-code .van-cell {
		border: 1px solid #d5d5d5;
		background: #F8F8F8;
		padding: 5px 10px !important;
		width: 100%;
		margin: 20px 0;
	}
	
	.graphical-code button {
		width: 100%;
	}
	
	.registered-tip {
		color: #101010;
		font-size: 14px;
		text-indent: 20px;
	}
	
	.graphical-code__fireworks {
		width: 90px;
		height: 88px;
		position: absolute;
		top: 0;
		right: 0;
	}
	
	.graphical-code__title {
		color: #EE2523;
		text-align: center;
		font-size: 16px;
	}
	
	.bottom-text {
		color: #DDDDDD;
		font-size: 10px;
		margin: 10px 10px 0 10px;
		text-align: center;
	}
	
	.bottom-text span {
		color: #F78E1E;
	}
	
	.text-tip {
		width: 100%;
		height: 50px;
		color: #FFFFFF;
		font-size: 18px;
		position: absolute;
		top: 20%;
		text-align: center;
	}
	
	.img-tip {
		width: 82px;
		height: 88px;
		position: absolute;
		top: 5%;
		right: 2%;
	}
</style>