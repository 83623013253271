<template>
	<div style="margin-bottom:50px;overflow:auto;">
		<van-tabs v-model="activeTab" sticky color="#F78E1E" title-active-color="#F78E1E">
			<van-tab :title="'CATEGORIES'" name="cat">
                <cats class="h10"></cats>
			</van-tab>
			<van-tab :title="'BRAND'" name="brand">
                <brands></brands>
			</van-tab>
		</van-tabs>
		<tabbar />
	</div>
</template>

<script>
	import Tabbar from '@/components/Tabbar.vue';
    import brands from './catsub_brands.vue';
    import cats from './catsub_cat.vue';

	export default {
		components: {
			Tabbar,
            brands,
            cats,
		},
		data() {
			return {
				activeTab: 'cat',
			};
		},
	};
</script>

<style scoped>
::v-deep .van-tab {
	margin-top: 10px;
}
::v-deep .van-tab__pane{
	height: calc(100vh - 51px - 50px);
}
</style>
