<template>
	<div>
		<van-nav-bar fixed left-arrow :title="$t('Commission_Goods')" @click-left="$router.go(-1)" @click-right="$router.push('/promoter')">
			<template #right>
		    	<van-icon name="question-o" size="18" color="#bbbbbb"/>
		  	</template>
		</van-nav-bar>
		<div @click="$router.push('/commissionRecord')" style="padding: 50px 0 10px 0;text-align: center;background: #FFFFFF;position: relative;">
			<span style="font-size: 14px;color: #BBBBBB;">{{$t('personalCenter.YourEarne')}}</span>
			<div class="top_amount">
				{{amounttData.finishAmount |Calculat}}
			</div>
			<van-icon name="arrow" color="#bbbbbb" style="position: absolute;right:10px;top: 60%;"/>
		</div>
		<div style="padding-bottom:50px;">
			<div style="margin-top: 10px;background: #FFFFFF;">
				<div style="padding: 10px;font-size: 14px;">{{$t('personalCenter.Productlist')}} ({{count}})</div>
				<van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('home.loosingText')" :loosing-text="$t('home.loosingText')" :loading-text="$t('home.loadingText')" :success-text="$t('home.successText')">
					<van-list 
			          v-model="isUpLoading"
			          :finished="upFinished"
			          :finished-text="$t('home.finishedText')"
			          :loading-text="$t('home.loadingText')"
			          @load="onLoadList"
			        >
						<div v-for="(item,index) in couponList" :key="index" style="height: auto;">
							<van-card
								currency="฿"
								style="background: #FFFFFF;margin-top: 10px; height: auto;">
								<template #thumb>
									<img :src="item.imageobjcet.imageurl" width="114px" height="114px"/>
								</template>
								<template #tag>
									<div class="pic-top-left-tag">
										<div class="pic-top-left-tag-text">{{$t('personalCenter.Coupon')}}</div>
										<div class="pic-top-left-tag-Amount">฿{{item.amount}}</div>
										<img src="../../assets/Couponlabel.png" />
									</div>
								</template>
								<template #title>
									<div style="font-size: 14px;">{{item.productName}}</div>
									<span style="font-size: 12px;width: auto;padding: 2px;">{{item.productItemName}}</span>
								</template>
								<template #price>
									<div style="color: #000000; font-size: 16px;">฿{{item.price |Calculat}}</div>
									<div style="color: #F78E1E; font-size: 18px; margin: 5px 0;"><span style="color:#BABABA;font-size: 14px;">{{$t('personalCenter.Discountprice')}}：</span>฿{{item.price-item.amount |Calculat}}</div>
									<div style="color:#F78E1E; font-size: 18px;"><span style="color:#BABABA;font-size: 14px;">{{$t('personalCenter.commission')}}：</span>฿{{item.commission |Calculat}}</div>
									<div style="color:#BABABA; font-size: 14px;">{{$t('personalCenter.Deadline')}}：{{item.endDate}}</div>
								</template>
							</van-card>
							<van-button type="primary" block color="rgb(218, 185, 107)" @click="commoditySharing(item)">{{$t('personalCenter.ShareButtom')}} ฿{{item.commission |Calculat}}</van-button>
						</div>
					 </van-list>
				</van-pull-refresh>
			</div>
		</div>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService,commission_api } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {
		},
		computed: {},
		data() {
			return {
				couponList:[],
				amounttData:{},
				isLoading:false,
				count:0,
				beginPage:1,
				rowSize:10,
				isUpLoading: false,
				upFinished:false,
			}
		},
		methods: {
			 commoditySharing(a){
			 	let that=this
					this.$axios({
						method: 'post',
						url: commission_api + '/v1/commission/productItem/share',
						data: {
							productItemId:a.productItemId,
						}
					})
					.then(res => {
//						window.location.href=res.data.url
//						let code =res.data.url.substring(res.data.content.indexOf('code=') + 1)
						let code= res.data.url.split("code=")[1]
						console.log(code)
						that.$router.push({
							path:'/commoditySharing',
							query:{
								code:code,
							}
						})
					}).catch(error => {
					})
				
			},
			onLoadList() {
		      if (this.isLoading) {
		          this.couponList = [];
		          this.isLoading = false;
		       }
		      this.couponproduct()
		      
		    },
			onRefresh(){
				this.rowSize = 10
      			this.upFinished = false // 不写这句会导致你上拉到底过后在下拉刷新将不能触发下拉加载事件
      			this.isUpLoading = true;
      			this.onLoadList()
			},
			couponproduct(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/commission/coupon/list',
						params: {
							beginPage:this.beginPage,
							rowSize:this.rowSize,
						}
					})
					.then(res => {
						if(res.code == 0) {
							this.isUpLoading = true;
							this.couponList=res.list
							this.count=res.count
							if(this.rowSize>=this.count){
								this.upFinished=true
							}
							if(this.rowSize<this.count){
								this.rowSize =this.rowSize+10
              					this.isUpLoading = false;
							}
						}
					}).catch(() => {})
			},
			commissionamount(){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/commission/amount',
						params: {}
					})
					.then(res => {
						if(res.code == 0) {
							this.amounttData=res.data
						}
					}).catch(() => {})
			},
			login() {
				this.$router.push({
					path: '/login'
				})
			},
			loginOut() {
				console.log('123')
				cookieRemove('token')
				this.$router.push({
					path: '/login'
				})
			},
		},
		created() {
				this.couponproduct()
				this.commissionamount()
		},
	}
</script>

<style scoped>
 .van-card__thumb{
 	width: auto !important;
 	height: auto !important;
 }
 .top_amount{
 	font-size: 28px;
 	color: rgb(218, 185, 107);
 	margin-top: 15px;
 }
 .top_amount span{
 	font-size: 14px;
 	margin-right: 5px;
 }
 .pic-top-left-tag{
 	position: relative;
 	color: #FFFFFF;
 	font-size: 10px;
 }
 .pic-top-left-tag img{
 	width: 40px;
 }
  .pic-top-left-tag .pic-top-left-tag-text{
 	position: absolute;
 	left: 2px;
 	top: 2px;
 	
 }
  .pic-top-left-tag .pic-top-left-tag-Amount{
 	position: absolute;
 	left: 5px;
 	top: 15px;
 	
 }
</style>