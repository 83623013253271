import axios from 'axios'
import { cookieGet } from '@/common/cookie'
import { Toast } from 'vant'
import { isPlainObject } from 'lodash'
import qs from 'qs'
// import store from '@/store'
import {ocdev_api,commission_api} from '@/common/api'

// 记录和显示错误
function errorLog (info) {
  // 显示提示
  Toast({
    message: info,
    type: 'fail',
    duration: 3 * 1000,
    className:"error_tip"
  })
}


// 成功
function success () {
  // 显示提示
  Toast({
    message: cookieGet('language') == 'th-THA'?'ความสำเร็จ': 'success',
    type: 'success',
    duration: 3 * 1000
  })
}

console.log('path',process.env.config)
// 创建一个 axios 实例
const service = axios.create({
  baseURL: process.env.config.VUE_APP_API,
  timeout: 1000 * 180,
  withCredentials: true
})

/**
 * 请求拦截
 */
service.interceptors.request.use(config => {
  // config.headers['language'] = cookieGet('language') == 'zh-CN'?'chs': 'eng'
  config.headers['language'] = cookieGet('language') == 'th-THA'?'th': 'eng'
  config.headers['apptype'] = 'h5mall'
  config.headers['appversion'] = '1'
  // config.headers['Content-type'] = 'application/json; charset=utf-8'
  // config.headers['token'] = cookieGet('token') || ''
  var token = cookieGet('token') || ''
  
  // 默认参数
  var defaults = {}
  
  // 防止缓存，GET请求默认带_t参数
  if (config.method === 'get' || config.method === 'delete' || config.data.noQs) {
    
    config.params = {
      ...config.params,
      ...{ '_t': new Date().getTime() }
    }

    if(token != ''){
      config.params.token = token
    }
  }else{
    
    config.data = {
      ...config.data
    }

    if(token != ''){
      config.data.token = token
    }

  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    // if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      if (!config.data.noQs){
         config.data = qs.stringify(config.data)
      }
    // }
  }
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
service.interceptors.response.use(response => {
	//console.log('1111',response)
  if (response.data.code === "401" || response.data.code === "10001") {
    // store.dispatch('d2admin/account/logout')
    return Promise.reject(response.data.message)
  } else if (response.data.code !== "0") {
    errorLog(response.data.message)
    return Promise.reject(response.data.message)
  } else {
    if (response.config.method !== "get" && response.config.url !== ocdev_api+"/v1/channelVisit/visit/add" && response.config.url !== commission_api+"/v1/commission/productItem/share"){
      success()
    }
    return response.data;
  }
}, error => {
  errorLog(error.message)
  return Promise.reject(error)
})

export default service

