<template>
  <div>
    <!-- <topbar/> -->
	<van-nav-bar fixed
      :title="catalogName"
	  @click-left="$router.push('/')">
	    <template #left>
			<img style="width:30px;height:30px" src="../../../static/images/left.png" />
	  	</template>
    </van-nav-bar>

	<div style="padding-top:46px;">
		<van-sticky :offset-top="46">
			<van-nav-bar
			:left-arrow="false"
			style="width:95%;margin:auto;"
			class="vnl-nopad"
			> 
			<template #left>
				<van-dropdown-menu active-color="#F78E1E">
					<van-dropdown-item @change="changeSort" title-class="priceSort" v-model="sortValue" :options="sortOption" />
				</van-dropdown-menu>
			</template>
			<template #right>
				<div style="width: 30px;height: 30px;" v-if="layoutType" @click="layoutswitch()">
					<img src="../../../static/images/layoutsecond.png" style="width: 100%; height: 100%;"/>
				</div>
				<div style="width: 30px;height: 30px;" v-else @click="layoutswitch()">
					<img src="../../../static/images/layoutfirst.png" style="width: 100%; height: 100%;"/>
				</div>
			</template>
			</van-nav-bar>
		</van-sticky>
	</div>

	<div class="idx_data">
		<van-list
			v-model="loading"
			:finished="finished"
          	:finished-text="$t('home.finishedText')"
          	:loading-text="$t('home.loadingText')"
			:immediate-check="false"
			@load="onLoad"
		>
		<div id="allType">
			<van-row style="display: flex;flex-wrap: wrap;">
				<!-- PRODUCT -->
				<van-col :span="layoutType? '12':'24'" v-for="item in originList" :key="item.id" >
					<product-ui-half :product="item" v-if="layoutType"></product-ui-half>
					<!-- <div class="goods-list__block" >
						<div class="goods-list__block__img">
							<img :src="item.imageobjcet.imageurl" />
						</div>
						<div class="goods-list__block__details">
							<div class="goods-list__block__details__name">
								<span v-if="language=='en-US'">[{{((1-item.price/item.price_origin)*100).toFixed(0)}}% OFF]</span>
								<span v-if="language=='th-THA'">[ลดสูงสุด {{((1-item.price/item.price_origin)*100).toFixed(0)}}%]</span>
								{{item.productName}}
							</div>
							<div class="goods-list__block__details__price">
								฿{{item.price}}
								<span v-if="item.price != item.price_origin">฿{{item.price_origin | Calculat}}</span>
							</div>
						</div>
					</div> -->
					<product-ui-line :product="item" v-else></product-ui-line>
				</van-col>
			</van-row>
		</div>
		</van-list>
	</div>

  </div>
  
</template>

<script>

import Topbar from '@/components/Topbar.vue'
import Tabbar from '@/components/Tabbar.vue'
import productUiHalf from '@/components/product-ui-half';
import productUiLine from '@/components/product-ui-line';
import { cookieGet, cookieSet } from '@/common/cookie'
import { productService,homepageService,ocdev_api} from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
import mixinJumpModule from '@/mixins/jump-module'
export default {
	name: 'catalogProduct',
	mixins: [ mixinJumpModule ],
	components: {
		Topbar,
		Tabbar,
		productUiHalf,
		productUiLine,
	},
  	filters: {
		Calculat(amount){
			return CalculatAmount(amount)
		}
	},
	computed: {
	},
	data () {
		return {
			catalogName: '',
			catalogId: '',

			loading: false,
			finished: false,
			page:1,
			size:20,
			maxPage: 2,
			originList: [],
			isMore: true,

			sortValue: 'normal',
			//这里需要改成多语言
			sortOption: [
				{ text: 'Popularity', value: 'normal' },
				{ text: 'Top Sales', value: 'saledDesc' },
				{ text: 'Price low to high', value: 'priceAsc' },
				{ text: 'Price high to low', value: 'priceDesc' },
			],
			layoutType:true,
		}
	},
	created () {
		this.language=cookieGet('language');
	},
	mounted(){
		if(this.$route.query.catalogId != undefined){
			this.catalogId = this.$route.query.catalogId
			this.catalogName = this.$route.query.catalogName
			this.loading = true
			// this.finished = true
			this.onLoad()
		}
	},
	methods:{
		layoutswitch(){
			this.layoutType=!this.layoutType
		},
		productDetail(productItemId){
			this.$router.push({path: '/details',query: { productItemId: productItemId }})
		},
		changeSort(value){
			console.log(value)
			this.sortValue = value
			this.loading = true
			this.finished = false
			this.isMore = true
			this.page = 1
			this.size = 20
			// this.maxPage = 2
			this.originList = []
			this.onLoad()
		},
		onLoad(){
			if(this.isMore == true){
				
				homepageService.getCatalogProduct({beginPage: this.page , rowSize: this.size, sort: this.sortValue, catalogId: this.catalogId}).then(res => {
					if(res.code == '0'){

						if(res.list.length == 0){
							this.loading = false;
							this.finished = true;
						}else{

							res.list.forEach( item => {
								this.originList.push(item)
							})

							if(res.list.length == 20){
								this.isMore = true
								this.finished = false
							}else{
								this.isMore = false
								this.finished = true
							}

							this.page +=1
							this.loading = false;
						}
					}
				}).catch(e => {
			
				})
			}else{
				this.finished = true;
			}
		},
		
	}
}
</script>

<style>
	.van-image > img{
		border-top-left-radius:5px;
		border-top-right-radius:5px;
	}
	#allType  .goods-list:nth-child(odd){
		width: 48%;
		float: right;
		overflow-x: hidden;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	
	#allType  .goods-list:nth-child(even){
		width: 48%;
		float: left;
		overflow-x: hidden;
		margin: 0 auto;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.priceSort{
		font-size:14px !important;
		color: #F78E1E !important;
	}

	.van-dropdown-menu__bar{
		height: 35px !important;
		box-shadow: none !important;
	}
</style>
<style scoped>
	::v-deep .vnl-nopad .van-nav-bar__left{
		padding: 0;
	}
	.bar-img{
		width: 95%;
		height: auto;
		margin: 0 auto;
		padding-top: 20px;
		text-align: center;
	}
	.van-swipe{
		/* border-radius: 5px; */
	}

	
	.goods-list__block{
		border-radius: 5px;
		width: 100%;
		background: #FFFFFF;
		margin-bottom: 15px;
	}
	.goods-list__block__img{
		width: 100%;
		padding-top: 100%;
		position: relative;
	}
	.goods-list__block__img img{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.goods-list__block__details{
		width: 100%;
		/* padding: 10px; */
	}
	.goods-list__block__details__name{
		margin-top: 10px;
		font-size: 13px;
		padding: 0 10px 0 10px;
		color: #000000;
		/* max-height: 40px; */
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	.goods-list__block__details__name span{
		color: #F78E1E;
	}
	.goods-list__block__details__price{
		font-size: 14px;
		padding: 10px 10px 5px 10px;
		color: #F78E1E;
	}
	.goods-list__block__details__pricecope{
		font-size: 14px;
		padding: 10px 10px 5px 0;
		color: #F78E1E;
	}
	.goods-list__block__details__pricecope span{
		color: #CCCCCC;
		font-size: 12px;
		margin-left: 5px;
		text-decoration:line-through;
	}
	.goods-list__block__details__price span{
		color: #CCCCCC;
		font-size: 12px;
		margin-left: 5px;
		text-decoration:line-through;
	}
	.tags{
		width: 95%;
		height: auto;
		margin: 0 auto;
		padding-top: 20px;
		text-align: center;
		border-radius: 5px;
	}
	.idx_data{
		width: 95%;
		margin: 0 auto;
		height: auto;
		padding-top: 15px;
	}
	.van-col--12{
		padding-left: 3.2px;
    	padding-right: 3.2px;
	}
	.goods-list__block__img > img {
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
.van-card__thumb{
 	width: auto !important;
 	height: auto !important;
 }
</style>