<template>
	<div class="goods-list__block" @click="jumpHandler">
		<div class="goods-list__block__img">
			<img :src="parsedProduct.img" />
			<div v-if="parsedProduct.discount < 1" class="goods-list__block__badge">{{ parsedProduct.discount | discountTran }}%</div>
		</div>
		<div class="goods-list__block__details">
			<div class="goods-list__block__details__name">
				<!-- <span v-if="language == 'en-US' && 1 > parsedProduct.discount">[{{ parsedProduct.discount | discountTran }}% OFF]</span>
				<span v-if="language == 'th-THA' && 1 > parsedProduct.discount">[ลดสูงสุด {{ parsedProduct.discount | discountTran }}%]</span> -->
				{{ parsedProduct.name }}
			</div>
			<div class="goods-list__block__details_price_sales">
				<div class="goods-list__block__details__price">
				<div>฿{{ parsedProduct.price | Calculat }}</div>
				<div>
					<span v-if="parsedProduct.price != parsedProduct.orgPrice">฿{{ parsedProduct.orgPrice }}</span>
				</div>
				</div>
				<div class="goods-list__block__details_sales">
					<div>{{parsedProduct.saled}} Sold</div>
				</div>
			
			</div>
			
		</div>
	</div>
</template>

<script>
	import mixinJumpModule from '@/mixins/jump-module';
	import { cookieGet } from '@/common/cookie';
	import { CalculatAmount } from '@/common/calculation';

	/**
	 * 兼容flash接口
	 */
	function compatFlash(untyped) {
		if (typeof untyped.productid !== 'string') {
			return null;
		}

		return {
			img: untyped.imageobjcet.imageurl,
			discount: untyped.price / untyped.price_origin,
			name: untyped.productName,
			price: untyped.price,
			// saled:untyped.ui.param1.saled,
			orgPrice: untyped.price_origin,
			jump: untyped.productItemId,
			handle: 'productDetail',
		};
	}

	/**
	 * 兼容/homepage
	 */
	function compatHomepage(untyped) {
		if (!(untyped.jump && untyped.ui)) {
			return null;
		}
		//可以拿到价格，销量，图片等等数据（untyped)
		// console.log(untyped)
		
		return {
			img: untyped.ui.param1.publishPictureUrl,
			discount: untyped.ui.param1.discountRate,
			name: untyped.ui.param1.name,
			price: untyped.ui.param1.price,
			saled:untyped.ui.param1.saled,
			orgPrice: untyped.ui.param1.originalPrice,
			jump: untyped.jump,
			handle: 'jumpHandle',
		};
	}

	/**
	 * 兼容/searchproductList & /catalogProduct
	 */
	function compatSearchproduct(untyped) {
		if (typeof untyped.productItemId !== 'string') {
			return null;
		}
	
		return {
			img: untyped.imageobjcet.imageurl,
			discount: untyped.price / untyped.price_origin,
			name: untyped.productName,
			price: untyped.price,
			saled:untyped.productSaled,
			orgPrice: untyped.price_origin,
			jump: untyped.productItemId,
			handle: 'productDetail',
		};
	}

	/**本组件统一了需要显示同一UI的不同组件,这里为兼容补丁,意图将不同的数据结构做区分并统一转换 */
	const capability = [compatHomepage, compatSearchproduct, compatFlash];

	export default {
		mixins: [mixinJumpModule],
		props: {
			product: {
				type: Object,
				default: Object,
			},
		},
		data() {
			return {
				/**转换过的product结构,为一层对象 */
				parsedProduct: {},
				language: '',
			};
		},
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount);
			},
			discountTran(discount) {
				return -((1 - discount) * 100).toFixed(0);
			},
		},
		created() {
			
			for (let fn of capability) {
				const temp = fn(this.product);
			
				if (temp) {
					this.parsedProduct = temp;
					// console.log(temp)
					break;
				}
			}
			// console.log(this.parsedProduct)
			this.language = cookieGet('language');

			// this.productquerybrandid()
		},
		methods: {
			jumpHandler() {
				this[this.parsedProduct.handle](this.parsedProduct.jump);
			},
			productDetail(productItemId) {
				this.$router.push({ path: '/details', query: { productItemId: productItemId } });
			},
		},
	};
</script>

<style scoped>
	.goods-list__block {
		border-radius: 5px;
		width: 100%;
		background: #ffffff;
		margin-bottom: 10px;
	}
	.goods-list__block__img {
		width: 100%;
		padding-top: 100%;
		position: relative;
	}
	.goods-list__block__img img {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.goods-list__block__badge {
		position: absolute;
		right: 1em;
		top: 1em;
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		font-size: 12px;
		text-align: center;
		padding: 0.4em;
		border-radius: 50%;
		background-color: #f78e1e;
		color: white;
	}
	.goods-list__block__details {
		width: 100%;
		/* padding: 10px; */
	}
	.goods-list__block__details__name {
		font-size: 14px;
		padding: 0 10px 0 10px;
		color: #000000;
		line-height: 1.5;
		height: 3em;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
	.goods-list__block__details_price_sales{
		display: flex;
		justify-content: space-between;
		
	}
	.goods-list__block__details__price {

		font-size: 14px;
		padding: 10px 10px 5px 10px;
		color: #f78e1e;
	}
	.goods-list__block__details__price > div {
		height: 1rem;
	}
	.goods-list__block__details__price span {
		color: #cccccc;
		font-size: 12px;
		/* margin-left: 5px; */
		text-decoration: line-through;
	}
	.goods-list__block__details_sales{
		padding: 25px 10px 5px 10px;
		color: #A2A2A2;
		font-size: 9px;

	}
	
</style>
