<template>
	<div>
		<van-nav-bar :title="$t('personalCenter.BecomePromoter')"/>
		<div class="content">
			<div class="content__top">
				<div class="content__top__first">{{$t('personalCenter.BecomePromotertoptitle')}}</div>
				<div class="content__top__second">{{$t('personalCenter.BecomePromotertoptip')}}</div>
			</div>
			<div class="content__botoom">
				<div class="content__botoom__left">
					{{$t('personalCenter.BecomePromotertoptextone')}}
				</div>
				<div class="content__botoom__right">
					{{$t('personalCenter.BecomePromotertoptexttwo')}}
				</div>
				<div class="content__botoom__left">
					{{$t('personalCenter.BecomePromotertoptextthree')}}
				</div>
				<div class="content__botoom__right">
					{{$t('personalCenter.BecomePromotertoptextfour')}}
				</div>
				<div class="content__botoom__left">
					{{$t('personalCenter.BecomePromotertoptextFives')}}
				</div>
				<div class="content__botoom__right">
					{{$t('personalCenter.BecomePromotertoptextsix')}}
				</div>
			</div>
			<div style="text-align: center;padding:80px 0 20px;font-size: 14px;">
				{{$t('personalCenter.BecomePromotertopbottomtip')}}
			</div>
		</div>
		<div style="width: 80%;position: fixed; bottom: 10px;left: 10%;">
			<van-button type="default" color="#962D00" style="width: 100%; border-radius: 20px;" @click="firstConfirm=true">{{$t('personalCenter.BecomePromoter')}}</van-button>
		</div>
		<van-popup v-model="firstConfirm" style="width: 80% !important;">
		  <div style="padding:30px 40px;">
		  	<div style="text-align: center;font-size: 14px;">{{$t('personalCenter.firstConfirmtitle')}}</div>
		  	<p style="font-size: 14px;">{{$t('personalCenter.firstConfirmcontent')}}</p>
		  	<van-button type="default" color="#F78E1E" size="small" style="width: 100%;border-radius: 2px;" @click="creartecode()">{{$t('personalCenter.firstConfirmbuttom')}}</van-button>
		  </div>
		</van-popup>
		<van-popup v-model="secondConfirm" style="width: 80% !important;">
		  <div style="padding:0 30px 20px;">
		  	<p style="text-align: center;font-size: 16px;">{{$t('personalCenter.secondConfirmtitle')}}</p>
		  	<p style="font-size: 14px;">{{$t('personalCenter.secondConfirmcode')}}<span style="color:#F56723;">{{code}}</span></p>
		  	<p style="font-size: 14px;">{{$t('personalCenter.secondConfirmcontent')}}</p>
		  	<van-button
		  		 type="default"
		  		 color="#F78E1E" 
		  		 size="small" 
		  		 style="width: 90%;border-radius: 2px;display: block; margin: 0 auto;"
		  		 @click="$router.replace('promoter')">
		  		 {{$t('personalCenter.secondConfirmbuttom')}}
		  		 </van-button>
		  </div>
		</van-popup>
	</div>
</template>
<script>
	import Tabbar from '@/components/Tabbar.vue'
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api,personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				firstConfirm:false,
				secondConfirm:false,
				code:'',
				user:{}
			}
		},
		methods: {
			creartecode(){
				this.$axios({
						method: 'post',
						url: ocdev_api + '/v1/pc/customer/createcode',
						data: {}
					})
					.then(res => {
						if(res.code==0){
							this.pccustomer()
						}
					}).catch(() => {})
			},
			pccustomer() {
				this.$axios({
						method: 'get',
						url: ocdev_api + '/v1/pc/customer',
						params: {
							clientId: this.user.id
						}
					})
					.then(res => {
						if(res.code == 0) {
							this.code = res.data.couponCode
							this.firstConfirm=false
							this.secondConfirm=true
						}
					}).catch(() => {})
			},
		},
		created() {
			let userDataStr = cookieGet("token")
			if(userDataStr) {
				this.user = JSON.parse(cookieGet("user"))
			}
		},
	}
</script>

<style scoped>
	.content{
		background: #E6E6E6;
	}
	.content__top{
		width: 90%;
		margin: 0 auto;
	}
	.content__top__first{
		color: #FFFFFF;
		font-size: 14px;
		text-align: center;
		padding:20px;
		background: #000000;
	}
	.content__top__second{
		color: #DABD83;
		font-size: 36px;
		text-align: center;
		display: block;
		padding:0 0 30px 0;
		background: #000000;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
	}
	.content__botoom{
		width: 100%;
		margin: 20px auto;
	}
	.content__botoom__left{
		width: 70%;
		height: auto;
		color: #000000;
		font-size: 16px;
		padding: 30px 20px 40px 20px;
		background: url(../../../static/images/dialogue_left.png) no-repeat;
		background-size:100% 100%;
		margin-bottom: 20px;
		margin-left: 5%;
	}
	.content__botoom__right{
		width: 70%;
		height: auto;
		color: #DABD83;
		font-size: 16px;
		padding: 30px 20px 40px 20px;
		background: url(../../../static/images/dialogue_right.png) no-repeat;
		background-size:100% 100%;
		margin-bottom: 20px;
		margin-left: 15%;
	}
</style>