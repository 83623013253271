<template>
	<div>
		<van-nav-bar fixed :title="$t('personalCenter.withdraw')" @click-left="$router.back(-1)" @click-right="$router.push('withdrawalsRecord')">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  	</template>
		  	<template #right>
		    	<div>{{$t('personalCenter.withdrawalsRecord')}}</div>
		  	</template>
		</van-nav-bar>
		<div style="width: 95%; margin: 55px auto;">
			<div class="top-style">
				<div style="color:#C9C9C9; font-size: 14px;">{{$t('personalCenter.withdrawableCash')}}</div>
				<div style="color:#F78E1E; font-size: 20px;">฿ {{usableAmount | Calculat}}</div>
			</div>
			<div class="bottom-style">
				<div style="color: #101010;font-size: 14px;">{{$t('personalCenter.withdrawalAmount')}}</div>
				<div style="display: flex;align-items: center;"><b>฿</b><van-field v-model="amount" type="number" @input="inputAmount()"/></div>
				<div v-if="inputTip=='0'" style="color: #C9C9C9;font-size: 12px;padding: 10px 0;border-top: 1px solid #F1F1F1;">{{$t('personalCenter.withdrawalAmountTip')}}฿{{limitAmount | Calculat}}</div>
				<div v-if="inputTip=='1'" style="color: #F78E1E;font-size: 12px;padding: 10px 0;border-top: 1px solid #F1F1F1;">{{$t('personalCenter.withdrawalAmountTip')}}฿{{limitAmount | Calculat}}</div>
				<div v-if="inputTip=='2'" style="color: #F78E1E;font-size: 12px;padding: 10px 0;border-top: 1px solid #F1F1F1;">{{$t('personalCenter.withdrawalAmountTipTwo')}}</div>
				<div><van-button type="default" color="#F78E1E" style="width: 100%;display: block;margin: 0 auto;" :disabled="inputTip != '0' || amount==''" @click="selectcardList()">{{$t('personalCenter.confirmWithdrawal')}}</van-button></div>
			</div>
			<div style="font-size: 12px;color: #C9C9C9; text-align: right;">
				{{$t('personalCenter.bottomTip')}}
			</div>
		</div>
		<van-popup
		  v-model="cardShow"
		  position="bottom"
		  :style="{ height: '70%' }"
		>
			<div style="text-align: center;font-size: 14px;color: #000000;padding: 20px 0;">{{$t('personalCenter.ConfirmText')}}</div>
			<div v-if="cardList.length>0">
				<div v-for="(item,index) in cardList.slice(0,5)" :key="index" :class="selecttype==index? 'card-stylecope':'card-style'" @click="selectCardinfo(item.id,index)">
					<div>{{item.bankName}}</div>
					<div style="margin: 15px 0;">{{item.cardNo}}</div>
					<div>{{item.accountName}}</div>
					<van-icon name="close" size="20" style="position: absolute;right: 10px; top: 10px;" @click="withdrawacardlremove(item.id)"/>
				</div>
			</div>
			<div class="addcard-style" @click="withdrawalbanklist()" v-if="cardList.length<5">
				{{$t('personalCenter.changeAccount')}} <van-icon name="arrow" />
			</div>
			<div style="position: fixed;bottom: 0;width: 100%;"><van-button type="default" color="#F78E1E" style="width: 100%;display: block;margin: 0 auto;" @click="requestwithdrawal()">{{$t('personalCenter.confirmWithdrawal')}}</van-button></div>
		</van-popup>
		<van-popup
		  v-model="addcardShow"
		  position="bottom"
		  :style="{ height: '70%' }"
		>
			<div style="text-align: center;font-size: 14px;color: #000000;padding: 20px 10px;"><van-icon @click="addcardShow=false" name="arrow-left" style="float: left;" size="20px"/>{{$t('personalCenter.formTitle')}}</div>
			
			<van-form @submit="addcardInfo">
			<div style="width:90%; margin: 0 auto;">
				  <div style="color: #101010;font-size: 14px; margin: 10px 0;">{{$t('personalCenter.bankName')}}</div>
				  <van-field
				  	readonly
          			clickable
				    v-model="form.bankName"
				    :rules="[{required: true,message: $t('personalCenter.pleasebankTips') }]"
				    style="border: 1px solid #E2E2E2;"
				    @click="showPicker = true"
				  />
				  <div style="color: #101010;font-size: 14px; margin: 10px 0;">{{$t('personalCenter.account')}}</div>
				  <van-field
				    v-model="form.cardNo"
				    type="digit"
				    :rules="[{ validator, required: true,message: $t('personalCenter.PleasecardTips') }]"
				    style="border: 1px solid #E2E2E2;"
				  />
				  <div style="color: #101010;font-size: 14px; margin: 10px 0;">{{$t('personalCenter.name')}}</div>
				  <van-field
				    v-model="form.accountName"
				    :rules="[{required: true,message: $t('personalCenter.pleaseusernameTips') }]"
				    style="border: 1px solid #E2E2E2;"
				  />
				  <div style="display: flex;justify-content: flex-end;align-items: center;">
				  	<div style="color: #C9C9C9;font-size: 12px; margin: 20px 15px;">{{$t('personalCenter.protipText')}}</div>
				  	<van-switch v-model="form.saved" size="25" active-color="#F78E1E" />
				  </div>
				
			</div>
			<div style="position: fixed;bottom: 0;width: 100%;"><van-button type="default" color="#F78E1E" style="width: 100%;display: block;margin: 0 auto;" native-type="submit">{{$t('personalCenter.confirmWithdrawal')}}</van-button></div>
			</van-form>
		</van-popup>
		<van-popup v-model="showPicker" position="bottom">
		  <van-picker
		    :columns="bankListCopy"
		    :default-index="provinceIndex"
		    @change="onConfirm"
		    @cancel="showPicker = false"
		  />
		</van-popup>
		<van-popup v-model="successShow" style="width: 90% !important;">
			<div style="padding: 10px 20px 30px 20px;">
				<div style="text-align: center;"><van-icon name="passed" color="green" size="50"/></div>
				<div style="color: #000000;font-size: 16px;text-align: center;">{{$t('personalCenter.successTitle')}}</div>
				<div style="color: #C9C9C9;font-size: 12px;text-align: center;margin: 30px 0;">{{$t('personalCenter.successtip')}}</div>
				<div style="display: flex;justify-content:space-around;">
					<van-button type="default" size="small" plain hairline color="#F78E1E" @click="$router.push('withdrawalsRecord')" >{{$t('personalCenter.selectrecordingbutton')}}</van-button>
					<van-button type="default" size="small" color="#F78E1E" @click="cardShow=false;addcardShow=false;successShow=false" >{{$t('personalCenter.understand')}}</van-button>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="setpaywordShow" style="width: 90% !important;">
			<div style="padding:  20px;">
				<div style="color: #000000;font-size: 16px;text-align: center;">{{$t('personalCenter.tips')}}</div>
				<div style="color: #101010;font-size: 14px;text-align: center;margin: 30px 0;">{{$t('personalCenter.payProtips')}}</div>
				<div style="display: flex;justify-content:space-around;">
					<van-button type="default" size="small" plain hairline color="#F78E1E" @click="setpaywordShow=false" >{{$t('personalCenter.nextButton')}}</van-button>
					<van-button type="default" size="small" color="#F78E1E" @click="$router.push('/payPassword')" >{{$t('personalCenter.setButton')}}</van-button>
				</div>
			</div>
		</van-popup>
		<van-popup
		  v-model="paywordShow"
		  position="bottom"
		  closeable
		  :style="{ height: '70%' }"
		>
			<div style="text-align: center;font-size: 16px;margin: 20px 0;">{{$t('personalCenter.paywordTitle')}}</div>
			<div style="text-align: left;font-size: 12px;margin: 20px 0;color: #C9C9C9;">{{$t('personalCenter.paywordTip')}}</div>
			<div>
			<van-password-input
				  :value="passwordvalue"
				  :focused="showKeyboard"
				  @focus="showKeyboard = true"
				/>
				<!-- 数字键盘 -->
				<van-number-keyboard
				  :show="showKeyboard"
				  @input="onInput"
				  @delete="onDelete"
				  delete-button-text="×"
				  @blur="showKeyboard = false"
				/>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api,personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				amount:'',
				cardShow:false,
				addcardShow:false,
				form:{
					bankName:'',
					bankNameTextId:'',
					cardNo:'',
					accountName:'',
					saved:true
				},
				inputTip:'0',
				usableAmount:'',
				limitAmount:'',
				cardList:[],
				successShow:false,
				setpaywordShow:false,
				paywordShow:false,
				passwordvalue:'',
				showKeyboard: false,
				bankList:[],
				bankListCopy:[],
				showPicker:false,
				provinceIndex: 0,
				selecttype:0
			}
		},
		methods: {
			selectCardinfo(id,index){
				this.selecttype=index
				this.withdrawalCardId=id
			},
			validator(val){
				if(5<val.length && val.length<17){
					return true
				}else{
					return false
				}
			},
			onConfirm(picker,value,index) {
				this.form.bankName=value
		      this.bankList.forEach( item => {
		        if(item.name == value){
		          this.form.bankNameTextId = item.id
		        }
		      })
		      this.showPicker = false;
		    },
			withdrawalbanklist(){
				personalCenter.withdrawalbanklist({})
			    .then(res => {
					if(res.code == 0) {
						this.bankList=res.list
						this.bankListCopy=[]
						 res.list.forEach( item => {
				            this.bankListCopy.push(item.name)
				          })
						this.addcardShow=true
					}
				}).catch(() => {})	
			},
			withdrawacardlremove(id){
				this.$Dialog.alert({
				  message: this.$t('public.askDelete'),
				  confirmButtonText:this.$t('confirm'),
				  cancelButtonText:this.$t('cancel'),
				  showCancelButton:true,
				}).then(() => {
				 	personalCenter.withdrawacardlremove({
		      			withdrawalCardId:id
			      	})
			      	.then(res => {
							if(res.code == 0) {
								this.cardList=[]
							}
						}).catch(() => {})	
				});
			},
			onInput(key) {
		      this.passwordvalue = (this.passwordvalue + key).slice(0, 6);
		      if(this.passwordvalue.length==6){
		      	personalCenter.checkpasswd({
		      		passwd:this.passwordvalue
		      	})
		      	.then(res => {
						if(res.code == 0) {
							this.configwithdrawal()
						}
					}).catch(() => {})	
		      }
		    },
		    onDelete() {
		      this.passwordvalue = this.passwordvalue.slice(0, this.passwordvalue.length - 1);
		    },
			checkpaypwdexist(){
				personalCenter.checkpaypwdexist({})
				.then(res => {
						if(res.code == 0) {
							if(res.data.exist=='0'){
							 this.setpaywordShow=true;
							 this.cardShow=false
							 this.addcardShow=false
							}
							if(res.data.exist=='1'){
							 this.cardShow=false;
							 this.addcardShow=false;
							 this.passwordvalue=''
							 this.paywordShow=true;
							 this.showKeyboard=true;
							}
						}
					}).catch(() => {})	
			},
			requestwithdrawalCope(id){
				this.checkpaypwdexist()
				this.withdrawalCardId=id
			},
			requestwithdrawal(){
				if(this.cardList.length<=0){
					this.withdrawalbanklist()
					return
				}
				this.checkpaypwdexist()
			},
			configwithdrawal(){
				personalCenter.requestwithdrawal({
					amount:this.amount,
					withdrawalCardId:this.withdrawalCardId
				})
				.then(res => {
						if(res.code == 0) {
							this.walletquery()
							this.walletlimitwithdrawal()
							this.cardShow=false
							this.addcardShow=false
							this.amount=''
							this.form={
								bankName:'',
								cardNo:'',
								accountName:'',
								saved:true
							}
							this.withdrawalCardId=''
							this.successShow=true
							this.paywordShow=false;
							this.showKeyboard=false;
						}
					}).catch(() => {})
			},
			addcardInfo(){
				personalCenter.addcard(this.form)
				.then(res => {
						if(res.code == 0) {
							this.form={
								bankName:'',
								cardNo:'',
								accountName:'',
								saved:true
							}
							this.withdrawalCardId=''
							this.requestwithdrawalCope(res.data.id)
							this.cardShow=false
							this.addcardShow=false
						}
					}).catch(() => {})
			},
			inputAmount(){
				if(Number(this.amount)<Number(this.limitAmount)){
					this.inputTip='1'
				}else if(Number(this.amount)>Number(this.usableAmount)){
					this.inputTip='2'
				}else{
					this.inputTip='0'
				}
				console.log('this.inputTip',this.inputTip,this.amount,this.limitAmount)
			},
			selectcardList(){
				//this.checkpaypwdexist()
				personalCenter.querycard({})
				.then(res => {
						if(res.code == 0) {
							this.cardList=res.list
							this.selecttype=0
							this.cardShow=true
						}
					}).catch(() => {})
			},
			walletquery(){
				personalCenter.walletqueryacount({})
				.then(res => {
						if(res.code == 0) {
							this.usableAmount=res.data.cash
						}
					}).catch(() => {})
			},
			walletlimitwithdrawal(){
				personalCenter.walletlimitwithdrawal()
				.then(res => {
						if(res.code == 0) {
						this.limitAmount=res.data.limit
						}
					}).catch(() => {})
			},
		},
		created() {
			this.walletquery()
			this.walletlimitwithdrawal()
		},
	}
</script>

<style scoped>
	.top-style{
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		padding: 25px 20px;
	}
	.bottom-style{
		margin: 5px 0 10px 0;
		background: #FFFFFF;
		padding: 20px;
	}
	.card-style{
		width: 80%;
		margin: 15px auto;
		border: 1px solid #F78E1E;
		border-radius: 5px;
		color: #000000;
		font-size: 14px;
		padding: 5%;
		position: relative;
	}
	.card-stylecope{
		width: 80%;
		margin: 15px auto;
		border: 1px solid #007AFF;
		border-radius: 5px;
		color: #000000;
		font-size: 14px;
		padding: 5%;
		position: relative;
	}
	.addcard-style{
		width: 80%;
		margin: 0 auto 60px;
		border: 1px dashed #BBBBBB;
		border-radius: 5px;
		color: #BBBBBB;
		font-size: 14px;
		padding: 5%;
		text-align: center;
	}
</style>