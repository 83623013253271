<template>
	<div style="height: 100vh;">
		<div style="width: 100%; height: 50%;" v-if="Androidshow">
			<van-overlay :show="Androidshow" class-name="overlaystyle" @click="$router.push('/discountGoods')">
			  <div class="wrapper" @click.stop>
			    <div class="block">
			    	{{$t('personalCenter.Sharetips')}}
			    </div>
			    <img style="width: 100px;position: absolute;right: 0;" src="../../../static/images/wechat_arrows.png"/>
			  </div>
			</van-overlay>
		</div>
		<div class="share_top">
			<img src="../../assets/commission_share.jpg" width="100%" height="100%"/>
			<div class="share_top_info">
				<div class="share_top_info_user">{{user.usernamecode}}<span style="margin-left: 10px;">{{$t('personalCenter.gavetip')}}</span></div>
				<div style="margin-top: 10px;margin-left: 15%;">{{productInfo.productName}}</div>
				<div class="share_top_info_coupon">
					<span style="font-size: 35px;font-weight: 800;">฿{{productInfo.amount |Calculat}}</span> {{$t('personalCenter.Coupon')}}
				</div>
				<div class="share_top_info_time">{{productInfo.startDate}} - {{productInfo.endDate}}</div>
			</div>
		</div>
		<div style="width: 100%; height: 50%;" v-if="iosshow">
			<van-overlay :show="iosshow" class-name="overlaystylecope" @click="$router.push('/discountGoods')">
			  <div class="wrapper" @click.stop>
			    <div class="block">
			    	{{$t('personalCenter.iosSharetips')}}
			    </div>
			    <img style="width: 100px;position: absolute;left: 45%;bottom: 0;transform: rotate(180deg);" src="../../../static/images/wechat_arrows.png"/>
			  </div>
			</van-overlay>
		</div>
	</div>
</template>
<script>
	import { mapState } from "vuex";
	import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'
	import { orderService, commission_api,personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	import updateTdk from "../../utils/tdk.js"
	export default {
		name: 'commoditysharing',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {
		    ...mapState(["topicDetail"])
		 },
		data() {
			return {
				userPic:'',
				user:{},
				productInfo:{},
				code:'',
				beforeUrl:'',
				pathUrl:'',
				Androidshow:false,
				iosshow:false,
			}
		},
		tdk() {
		    return {
		      title: this.topicDetail && this.topicDetail.productName,
		      description: this.topicDetail && this.topicDetail.content,
		      keywords: this.topicDetail && this.topicDetail.productName,
		      ssrHeadAddInfo: {
		        oglocale: 'en_US',
		        ogtype: 'website',
		        ogtitle: this.topicDetail && this.topicDetail.productName,
		        ogdescription: this.topicDetail && this.topicDetail.content,
		        // 生产:https://m.rabbitselection.com/  测试:https://h5-training.rabbitselection.com/
		        ogurl: 'https://m.rabbitselection.com/commoditySharing?code='+this.topicDetail.code,
		        ogsite_name: 'Rabbit',
		        fbapp_id: '2323409301072036',
		        ogimage: this.topicDetail && this.topicDetail.imageobjcet.imageurl,
		        ogimagesecure_url: this.topicDetail && this.topicDetail.imageobjcet.imageurl,
		      }
		      
		    };
		  },
		  destroyed() {
		    this.$store.commit("SET_TOPIC_DETAIL", { detail: null });
		  },
		serverPrefetch() {
		    return this.fetchData()
		},
		methods: {
			fetchData() {
				return this.$store.dispatch("FETCH_TOPIC_DETAIL", {
		          code: this.$route.query.code
		      	}).finally(() => {
		          updateTdk.call(this);
		       });
//			let data=JSON.parse(this.$route.query.data)
//			this.$store.commit("SET_TOPIC_DETAIL", { detail: data })
//			return updateTdk.call(this);
		   },
			productItem(){
				this.sharecode(this.$route.query.code)
			},
			sharecode(code){
				this.$axios({
						method: 'get',
						url: commission_api + '/v1/commission/shareCode/query',
						params: {
							code:code,
						}
					})
					.then(res => {
						if(res.code == 0) {
							this.productInfo=res.data
						}
					}).catch(() => {})
			},
		},
		
		mounted() {
			if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
                 this.Androidshow=false
                 this.iosshow=true
            } else {
              //Android终端
              this.Androidshow=true
              this.iosshow=false
            }
			if(this.pathUrl){
				 this.$router.replace({
		            path: '/receiveAward',
		            query:{
						code:this.$route.query.code
					}
		        })
			}
			this.productItem()
			let userDataStr = cookieGet("token")
			if(userDataStr) {
				this.userPic = cookieGet("userpic")
				this.user = JSON.parse(cookieGet("user"))
				let a= this.user.username
				this.user.usernamecode=a.replace(/^(\d{4})\d{4}(\d{4})$/,'$1****$2')
			} else {
			}
		},
		beforeRouteEnter (to, from, next) {
			console.log(to,from)
			let that=this
			if(to.name=='commoditySharing' && (from.name!='discountGoods'||from.name==null)){
				next(vm=>{
					vm.pathUrl='/receiveAward'
				})
			}else{
				next()	
			}
		},
		created(){
		}
	}
</script>

<style scoped="scoped">
	.block{
		color: #FFFFFF;
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.share_top{
		position: relative;
		width: 100%;
		height: 50%;
	}
	.share_top_info{
		position: absolute;
		top: 16%;
		width: 100%;
	}
	.share_top_info_user{
		color: #101010;
		font-size: 10px;
		margin-left: 15%;
	}
	.share_top_info_time{
		font-size: 12px;
		text-align: center;
	}
	.share_top_info_coupon{
		color: #F78E1E;
		font-size: 23px;
		margin: 15px 0;
		text-align: center;
	}
	.overlaystyle{
		height: 50%;
	}
	.overlaystylecope{
		height: 50%;
		top: 50% !important;
	}
</style>