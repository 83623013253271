<template>
	<!-- flash专用的商品列表UI -->
	<div class="fx h10 bdbox" ref="flashRoot">
		<div class="goods-list__wrapper" v-for="prdItem in parsedProduct" :key="prdItem.code">
			<div class="goods-list__block" @click="jumpHandler(prdItem)" :style="{ width: dynamicWidth }">
				<div class="goods-list__block__img" :style="{ height: dynamicWidth }">
					<img :src="prdItem.img" :style="{ width: dynamicWidth, height: dynamicWidth }" />
					<div v-if="prdItem.discount < 1" class="goods-list__block__badge">{{ prdItem.discount | discountTran }}%</div>
				</div>
				<div class="goods-list__block__price">฿{{ prdItem.price | Calculat }}</div>
				<div class="goods-list__block__orgprice" v-if="prdItem.price < prdItem.orgPrice">฿{{ prdItem.orgPrice }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinJumpModule from '@/mixins/jump-module';
	import { cookieGet } from '@/common/cookie';
	import { CalculatAmount } from '@/common/calculation';

	/**
	 * 兼容flash接口
	 */
	function compatFlash(untyped) {
		if (typeof untyped.productid !== 'string') {
			return null;
		}

		return {
			img: untyped.imageobjcet.imageurl,
			discount: untyped.price / untyped.price_origin,
			name: untyped.itemname,
			price: untyped.price,
			orgPrice: untyped.price_origin,
			jump: untyped.productid,
			handle: 'productDetail',
		};
	}

	/**本组件统一了需要显示同一UI的不同组件,这里为兼容补丁,意图将不同的数据结构做区分并统一转换 */
	const capability = [compatFlash];

	export default {
		mixins: [mixinJumpModule],
		props: {
			products: {
				type: Array,
				require: true,
			},
			wrapper: {
				require: true,
			},
		},
		data() {
			return {
				/**转换过的product结构,为数组内嵌一层对象 */
				parsedProduct: [],
				dynamicWidth: null,
				language: '',
			};
		},
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount);
			},
			discountTran(discount) {
				return -((1 - discount) * 100).toFixed(0);
			},
		},
		created() {
			for (let fn of capability) {
				const temp = fn(this.products[0]);

				if (temp) {
					this.parsedProduct = this.products.map(compatFlash);
					break;
				}
			}

			this.language = cookieGet('language');

			// this.productquerybrandid()
		},
		mounted() {
			this.$nextTick(() => {
				this.dynamicWidth = this.wrapper.clientWidth * 0.25 - 8 + 'px';
				this.$nextTick(() => {
					this.$refs.flashRoot.style.width = this.wrapper.clientWidth * 0.25 * this.products.length + 'px';
				});
			});
		},
		methods: {
			jumpHandler(prdItem) {
				this[prdItem.handle](prdItem.jump);
			},
			productDetail(productItemId) {
				this.$router.push({ path: '/details', query: { productItemId: productItemId } });
			},
		},
	};
</script>

<style scoped>
	.goods-list__wrapper {
		margin: 0 4px;
	}
	.goods-list__block {
		background-color: white;
		flex-shrink: 0;
		height: 100%;
		border-radius: 8px;
		overflow: hidden;
	}
	.goods-list__block__img {
		position: relative;
	}
	.goods-list__block__img img {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.goods-list__block__badge {
		position: absolute;
		right: 1em;
		top: 1em;
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		font-size: 8px;
		text-align: center;
		padding: 0.4em;
		border-radius: 50%;
		background-color: #f78e1e;
		color: white;
	}
	.goods-list__block__details {
		width: 100%;
	}
	.goods-list__block__price {
		padding-left: 3px;
		text-align: left;
		font-size: 12px;
		color: #f78e1e;
	}
	.goods-list__block__orgprice {
		padding-left: 3px;
		text-align: left;
		text-decoration: line-through;
		font-size: 10px;
		color: #888888;
	}
</style>
