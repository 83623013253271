<template>
  <div id="home-container" style="width: 100vw;overflow: hidden;">
    <a :href="notice">{{notice}}</a>
  </div>
</template>
<script>
import mixinJumpModule from '@/mixins/jump-module'
export default {
  name: "qrcode",
  mixins: [ mixinJumpModule ],
  
  data() {
    return {
      notice: this.getAppDownloadUrl()
    }
  },
  mounted () {
    console.log("-------------------")
    this.jumpToAppOrStore(null, false)
  },
};
</script>