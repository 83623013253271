<template>
	<div>
		<div>
			<van-nav-bar
		      :title="$t('payOrder.barName')"
		    />
		</div>
		<div class="top-balance">
			<div class="top-balance__title">{{$t('payOrder.payableBalance')}}</div>
			<div class="top-balance__value">฿{{payamount | Calculat}}</div>
		</div>
		<div class="cont-style">
				<van-cell-group>
					<!-- ฿ 泰币符号-->
					<!--<van-cell :title="wallet.title" clickable>
						<template #label>
							<span class="custom-title">{{$t('payOrder.balance')}}</span>
							<span style="color: #F78E1E;margin-left: 5px;">${{wallet.enable}}</span>
						</template>
						<template #icon>
							<img :src="wallet.image" class="icon-left" />
						</template>
						<template #right-icon>
							<van-checkbox v-model="choosetype" checked-color="#F78E1E" @click="balanceClect()"/>
						</template>
					</van-cell>-->
				</van-cell-group>
		</div>
		<div class="bottom-method">
			<div class="bottom-method__title">{{$t('payOrder.paymentMethod')}}</div>
				<van-cell-group >
					<van-cell :title="item.title" v-for="(item,index) in logoList" :key="index">
						<template #label>
							<span class="custom-title">{{item.title}}</span>
						</template>
						<template #icon>
							<img :src="item.image" class="icon-left" />
						</template>
						<template #right-icon>
							<van-checkbox v-model="item.paytype" checked-color="#F78E1E" @click="otherPayType(item)"/>
						</template>
					</van-cell>
				</van-cell-group>
		</div>
		<div class="pay-button">
			<van-button color="#F78E1E" block size="large" @click="orderPay()">{{$t('payOrder.payNow')}}</van-button>
		</div>
		<van-popup v-model="show" :style="{ height: '100%', width:'100%'}" closeable>
				<iframe :srcdoc="payHtml" style="height: 100%; width: 98%;border: 0;"></iframe>
			<!--<div v-html="payHtml" style="width: 100%; height: 100%;"></div>-->
		</van-popup>
	</div>
</template>

<script>
	import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderPay } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'paymentOrder',
		filters: {
			Calculat(amount){
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				orderId: '',
				logoList:[],
				paytype:'',
				wallet:{
					choose:'0'
				},
				choosetype:false,
				payamount:'',
				orderamount:'',
				payHtml:'',
				show:false
			}
		},
		created() {
			//从缓存取出预订单id
			this.orderId = cookieGet("orderId")
			this.cashierRefresh()
		},
		watch:{
			logoList(){
				for(var i=0;i<this.logoList.length;i++){
						if(this.logoList[i].choose=='0'){
						this.logoList[i].paytype=false							
						}else{
						this.logoList[i].paytype=true
						}
					}
			},
		},
		methods: {
			/*paylogolist(){
				orderPay.paylogolist()
				.then(res=>{
					console.log(res)
					this.logoList=res.list
				})
				.catch(() => {})
			},*/
			orderPay(forcePaytype){
				let paytype=''
				if (forcePaytype) {
					paytype = forcePaytype;
				}
				else {
					for(var i=0;i<this.logoList.length;i++){
						if(this.logoList[i].choose=='1'){
							paytype=this.logoList[i].code
						}
					}
				}
				orderPay.orderPay({
					orderid:this.orderId,
					usewallet:this.wallet.choose,
					paytype:paytype,
					orderamount:this.orderamount,
					payamount:this.payamount,
					payChannel:'WEB'
					
				})
				.then(res=>{
					if(res.code==0 && res.data.type=='CREDITCARD'){
						
						// removeBigCache("2c2pPayHtmlInfo") //清除2c2p的html信息
						// setBigCache("2c2pPayHtmlInfo",JSON.stringify(res.data)) //新的2c2p的html信息放缓存
						// this.$router.push({path: '/payHtml',query: { orderId: this.orderId }})
						// this.show=true
						// this.payHtml=res.data.data.html

						// 删除已经添加的
						let payNode=document.getElementById("payDiv");
						// console.log(payNode)
						if(payNode != null){
							payNode.parentElement.removeChild(payNode)
						}

						// 创建新的
						const payDiv = document.createElement('div')
						payDiv.id = "payDiv"
						payDiv.innerHTML = res.data.data.html
						document.body.appendChild(payDiv)
						document.forms[0].submit()
					}

					if(res.code==0 && res.data.type=='COD'){
						fbq('track', 'Purchase', {currency: 'THB', value: this.payamount})
						this.$router.push({path: '/paySuccess',query: { payType: 'COD',orderId:res.data.data.id,traceNo:res.data.data.traceNo }})
					}
				})
				.catch(() => {})
			},
			otherPayType(data){
				if(this.paytype==data.code){
					this.paytype=''
				}else{
					this.paytype=data.code
				}
				this.cashierRefresh()
			},
			balanceClect(){
				console.log(this.choose)
				if(this.wallet.choose=='0'){
					this.wallet.choose='1'
				}else{
					this.wallet.choose='0'
				}
				this.cashierRefresh()
			},
			cashierRefresh(){
				orderPay.cashierRefresh({
					orderid:this.orderId,
					usewallet:this.wallet.choose,
					paytype:this.paytype
				})
				.then(res=>{
					this.payamount=res.data.payamount
					this.orderamount=res.data.orderamount
					this.logoList=res.data.paylist
					this.logoList = this.logoList.filter(t => t.code == 'CREDITCARD' || t.code=='COD')
					this.wallet=res.data.wallet
					// if(red.data.wallet.choose=='0'){
					// 	this.choosetype=false
					// }else{
					// 	this.choosetype=true
					// }
					
					// 订单额为0,强制继续
					if (this.payamount <= 0) {
						this.wallet.choose = 0;
						this.orderPay('COD');
					}
				})
				.catch(() => {})
			},
		}
	}
</script>

<style scoped>
	.top-balance {
		background: #FFFFFF;
		width: 95%;
		margin: 10px auto;
		border-radius: 10px;
	}
	
	.top-balance div {
		text-align: center;
	}
	
	.top-balance__title {
		font-size: 20px;
		color: #909399;
		padding: 20px 0 0 0;
	}
	
	.top-balance__value {
		font-size: 35px;
		color: #F78E1E;
		padding: 20px 0;
		letter-spacing: 3px;
	}
	
	.icon-left {
		width: 30px;
		height: 30px;
		display: block;
		margin-right: 10px;
	}
	
	.van-cell {
		align-items: center;
	}
	
	.cont-style {
		background: #FFFFFF;
		width: 95%;
		margin: 20px auto;
		border-radius: 10px;
	}
	
	.bottom-method {
		background: #FFFFFF;
		width: 95%;
		margin: 0 auto;
		border-radius: 10px;
		margin-bottom: 100px;
	}
	
	.bottom-method__title {
		padding: 20px;
	}
	
	.pay-button {
		width: 100%;
		position: fixed;
		bottom: 0;
		left: 0;
	}
	
	.pay-button button {
		width: 100%;
	}
</style>