<!-- 给其他支付回调用的界面,进入界面开始轮询订单,每2s一次 -->

<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Check_order_payment_status')"
    />
    <div style="padding-top:150px;text-align:center;">
      <van-loading size="24px" color="red">{{ $t('Checking_order_payment_status') }}</van-loading>
    </div>
  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService } from '@/common/api'

export default {
  name: 'checkPay',
  components: {
  },
  computed: {
  },
  data () {
    return {
      orderId:null,
      timer: ''
    }
  },
  methods: {

    //检测订单支付状态
    // checkPayStatus(){
    //   orderService.getDetail({orderid: this.orderId}).then(res => {
    //     if(res.code == '0'){
    //       if(res.data.payType == 'ONLINE'){ //在线支付
    //         if(res.data.status == 'WR'){ //订单支付完成
    //           clearInterval(this.timer); // 检测到订单支付清掉定时器
    //           this.$router.push({path: '/paySuccess', query:{ payType: res.data.payType }})
    //         }
    //       }else if (res.data.payType == 'COD'){ //货到货款
    //         if(res.data.status == 'WR'){ //订单没给钱,货到再给钱
    //           clearInterval(this.timer); // 检测到订单支付清掉定时器
    //           this.$router.push({path: '/paySuccess', query:{ payType: res.data.payType }})
    //         }
    //       }
    //     }
    //   }).catch(e => {
    //     //出异常清掉定时器
    //     clearInterval(this.timer);
    //   })
    // }
  },
  created () {
  },
  mounted(){

    //http://appdev.fanslink.vip:8086/checkPay?status=000&desc=success&orderId=4b6f03fd24a14e1a8007b2768252a541&traceNo=20200405192351502711

    let status = this.$route.query.status
    let desc = this.$route.query.desc
    let orderId = this.$route.query.orderId
    let traceNo = this.$route.query.traceNo
    
    let self = this
    setTimeout(() => {
      
      if(status == '0' || status == '00' || status == '000'){
        self.$router.push({path: '/paySuccess', query:{ payType: 'ONLINE', status: status, desc: desc, orderId:orderId, traceNo:traceNo }})
      }else{
        self.$router.push({path: '/payFail', query:{ payType: 'ONLINE', status: status, desc: desc, orderId:orderId, traceNo:traceNo }})
      }

    }, 1000)
    //从缓存取出订单id
    // this.orderId = cookieGet("orderId")
    // if(this.orderId){
    //   this.timer = setInterval(this.checkPayStatus, 2000);
    // }
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  }
}
</script>
