<template>
   <div class="bg">

    <!-- 暂时不显示 -->
    <!-- <div class="openAppBox" v-if="topShow">
      <div class="openAppBox_content">
          <img class="openAppBox_logo" src="../../../static/images/akuya_radius.png">
          
          <a class="openAppBox_btn" id="open" @click="gotoShopping()">{{openAppTitle}}</a>
          <img class="openAppBox_close" src="../../../static/images/del.png" @click="close()">
      </div>
    </div> -->

    <div v-if="wechatNoShow" style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index:99999999999 !important">
      <div style="float:right;position: relative;top: 20px;right: 30px;">
        <img style="width:110px;height:110px;" src="../../../static/images/wechat_arrows.png" />
      </div>
      <div style="position: relative;top: 50px;color:#FFF;text-align:center;font-size:20px;font-weight:bold;width:100%;height:40px;clear: both;">
        Please select "Open in browser"
      </div>
    </div>

    <van-nav-bar fixed
      :title="$t('Good_Item_Detail')"
      @click-left="$router.push('/mi')">
	    <template #left>
        <img style="width:30px;height:30px" src="../../../static/images/left.png" />
	  	</template>
    </van-nav-bar>

    <section class="h5-header">
      <van-loading style="text-align:center" type="spinner" v-if="loadingType"/>
      <van-swipe v-else :autoplay="3000" :width="imgWidth" :height="imgHeight">
        <van-swipe-item  v-for="(item, index) in productItemInfo.images" :key="index">
          <img :src="item.imageurl"  />
        </van-swipe-item>
      </van-swipe>
    </section>

    <section class="h5-content">
      <div style="height:150px;padding:25px 25px 0 25px;">
        <div style="text-align:left;color: #F78E1E;font-size:20px;width:100%;height:25px;">
          ฿{{ productItemInfo.price | Calculat }}
        </div>
        <div style="text-align:left;color:#BEBEBE;font-size:14px;width:100%;height:25px;text-decoration:line-through">
          ฿{{ productItemInfo.price_origin | Calculat }}
        </div>
        <div style="text-align:left;color:#000;font-size:16px;width:100%;height:25px;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
          {{productItemInfo.name}}
        </div>
        <div style="text-align:left;color:#8B8B83;font-size:14px;width:100%;height:50px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
          {{productItemInfo.itemhint}}
        </div>
      </div>
    </section>

    <section class="h5-bg-gray"></section>

    <section class="h5-sku" style="padding-bottom:30px;"> 
      <div class="details__goodsSku__box__type" v-for="(item,index) in skuNameList" :key="index">
        <h5 class="details__goodsSku__box__type_h5">{{item}}</h5>
        <div class="details__goodsSku__box__type__color">
          <div class="details__goodsSku__box__type__div"
            v-for="(a,b) in productItemSkuInfo[index][item]"
            :key="b"
            :class="{borderCur:a.active}" 
            @click="handelSkuActive(productItemSkuInfo[index][item],item,a.name,productItemSkuInfo);">
            <span >{{a.name}}</span>
          </div>
        </div>
      </div>
      <div style="float: right;padding: 0 20px 20px 0;">
        <van-stepper v-model="total" :min="existStock==true ? '1':'0'" integer :max="productItemInfo.total" />
      </div>
    </section>


    <section class="h5-bg-gray"></section>

    <section class="h5-footer" >
      <div v-html="productItemInfo.h5.h5content"></div>
    </section>

    <van-goods-action>
      <van-goods-action-button class="van-action-add-to-cart-search" color="#000" :disabled="!(productItemInfo.total > 0 && productItemId)"  :text="!(productItemInfo.total > 0 && productItemId) == false ?  $t('Add_To_Cart') : $t('Good_Temporarily_Out')" @click="addCart" />
      <van-goods-action-button class="van-action-buy-now-search" color="#F78E1E" :disabled="!(productItemInfo.total > 0 && productItemId)" :text="!(productItemInfo.total > 0 && productItemId) == false ?  $t('Good_Buy_Now')  : $t('Good_Temporarily_Out')" @click="buy" />
    </van-goods-action>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Topbar from '@/components/Topbar.vue'
import Tabbar from '@/components/Tabbar.vue'
import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'
import { productService, preorderService,shopcartService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
import updateTdk from "../../utils/tdk.js"
import mixinJumpModule from '@/mixins/jump-module'

export default {
  name: 'details',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  mixins: [ mixinJumpModule ],
  components: {
  },
  tdk() {
    return {
      title: this.topicDetail && this.topicDetail.name,
      description: this.topicDetail && this.topicDetail.itemhint,
      keywords: this.topicDetail && this.topicDetail.name,
      ssrHeadAddInfo: {
        oglocale: 'en_US',
        ogtype: 'website',
        ogtitle: this.topicDetail && this.topicDetail.name,
        ogdescription: this.topicDetail && this.topicDetail.itemhint,
        // 生产:https://m.rabbitselection.com/  测试:https://h5.akuya.in.th:8086/
        ogurl: 'https://m.rabbitselection.com/singleDetails?productItemId=' + this.topicDetail.id,
        ogsite_name: 'Rabbit',
        fbapp_id: '2323409301072036',
        ogimage: this.topicDetail && this.topicDetail.image,
        ogimagesecure_url: this.topicDetail && this.topicDetail.image,
      }
      
    };
  },
  computed: {
    ...mapState(["topicDetail"])
  },
  serverPrefetch() {
    return this.fetchData()
  },
  destroyed() {
    this.$store.commit("SET_TOPIC_DETAIL", { detail: null });
  },
  mounted(){

    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.imgTempWidth = window.screenWidth
      })()
    }

    this.imgWidth = this.imgTempWidth
    this.imgHeight = this.imgWidth * (4/3)

    this.productItemId = this.$route.query.productItemId
    this.lang = this.$route.query.language

    if(this.lang == 'ENG'){
      this.openAppTitle = 'Open APP'
    }else if(this.lang == 'TH'){
      this.openAppTitle = 'เปิดApp'
    }else{  
      this.openAppTitle = 'Open APP'
    }

    //单品id取单品信息
    productService.getProductItemInfo({productitemid: this.productItemId}).then(res => {
      if(res.code == 0 && res.data){
        
        this.productItemInfo = res.data
        //单品id取该单品所有sku组合,然后排列维护
        productService.getProductItemSkuInfo({productitemid: this.productItemId}).then(skuRes => {
          this.loadingType = false
          this.productItemSkuAllList = skuRes.list

          if(res.data.SKU){
            let tempSkuList = []
            res.data.SKU.forEach( item => {
              if(tempSkuList.indexOf(item.SKUName) == -1){
                tempSkuList.push(item.SKUName)
              }
            })

            this.skuNameList = tempSkuList
            tempSkuList.forEach( newItem => {
              let tempSkuObj = {}
              let tempSkuObjList = []
              let tempSkuObjListObj = []

              skuRes.list.forEach( allSkuItem => {
                if(allSkuItem.SKU){
                  allSkuItem.SKU.forEach( oldItem => {
                    if(newItem == oldItem.SKUName){
                      if(tempSkuObjList.indexOf(oldItem.SKUValue) == -1){
                        tempSkuObjList.push(oldItem.SKUValue)
                        res.data.SKU.forEach( currItem => {
                            if(currItem.SKUName == oldItem.SKUName && currItem.SKUValue == oldItem.SKUValue){
                              tempSkuObjListObj.push({'name':oldItem.SKUValue,'active':true})
                              this.skuActive[currItem.SKUName] = currItem.SKUValue
                            }else if(currItem.SKUName == oldItem.SKUName && currItem.SKUValue != oldItem.SKUValue){
                              // tempSkuObjListObj.push({'name':oldItem.SKUValue,'active':false})
                            }
                        })
                      }
                    }
                  })
                }
              })
              tempSkuObj[newItem] = tempSkuObjListObj
              this.productItemSkuInfo.push(tempSkuObj)
            })

            let tempList = []
            skuRes.list.forEach( i => {
              if(i.SKU.length > 0){
                let tempChildObj = {}
                i.SKU.forEach( j => {
                  tempChildObj[j.SKUName] = j.SKUValue
                  tempChildObj['itemId'] = j.productItemID
                })
                tempList.push(tempChildObj)
              }
            })
            // console.log(tempList)
            // console.log(this.productItemInfo)
            this.productItemSkuList = tempList
          }
        }).catch(e => {
      
        })
      }
    }).catch(e => {
      
    })

    
  },
  data () {
    return {
      productItemId: '',
      productItemSkuInfo:[],
      productItemSkuList:[],
      productItemSkuAllList:[],
      skuNameList:[],
      skuActive:{},
      productItemInfo: {
        SKU:[],
        images: [],
        h5:{}
      },
      dialogVisible: false,
      schemesUrl: 'akuya://fanslink.vip/buyfree',
      appstoreUrl: process.env.config.VUE_APP_IOS_URL,
      googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
      localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
      wechatNoShow: false,
      topShow:true,
      lang: 'ENG',
      openAppTitle: 'Open APP',
      existStock: true,
      total: 1,
      imgWidth:0,
      imgTempWidth: 0, // 屏幕尺寸,
      imgHeight:0,
      loadingType: true
    }
  },
  methods:{
    addCart(){

      let tokenInfo = cookieGet("token")
      let userInfo = cookieGet("user")
      let cartTempId = cookieGet("cartTempId")
      if(tokenInfo && userInfo){
        shopcartService.shoppingcartAdd({productitemid:this.productItemId, count:this.total}).then(res => {
          if(res.code == '0'){
            console.log(res.data)
          }
        }).catch(e => {
      
        })
      }else{
        if(cartTempId){ //已经有临时购物车id
          shopcartService.shoppingcarttmpAdd({productitemid:this.productItemId, count:this.total, tmpid:cartTempId}).then(res => {
            if(res.code == '0'){
              console.log(res.data)
            }
          }).catch(e => {
        
          })
        }else{ //还没有临时购物车id
          let nowtime = new Date().getTime();
          let randStr = Math.random().toString(36).substr(2)
          let createCartTempId = nowtime + randStr
          cookieSet("cartTempId",createCartTempId)
          shopcartService.shoppingcarttmpAdd({productitemid:this.productItemId, count:this.total, tmpid:createCartTempId}).then(res => {
            if(res.code == '0'){
              console.log(res.data)
            }
          }).catch(e => {
        
          })
        }
      }
    },
    fetchData() {
      return this.$store.dispatch("FETCH_TOPIC_DETAIL", {
          id: this.$route.query.productItemId
        }).finally(() => {
          console.log(this.topicDetail)
          updateTdk.call(this);
        });
    },
    buy(){
      let tokenInfo = cookieGet("token")
      let userInfo = cookieGet("user")
      if(tokenInfo && userInfo){
        //已登录状态直接创建预订单
        preorderService.createdirect({productitemid: this.productItemId, productitemcount: this.total}).then(res => {
          if(res.code == '0'){
            removeBigCache("preorderInfo") //清除旧的预订单
            setBigCache("preorderInfo",JSON.stringify(res.data)) //新的预订单放缓存
            this.$router.push({path: '/order'})
          }
        }).catch(e => {
      
        })
        
      }else{
        //未登录状态跳转登录带上单品id
        this.$router.push({path: '/login', query:{ productItemId: this.productItemId, total: this.total}})
      }
    },

    //sku选择器
    handelSkuActive(items,type,name,list) {
      
      this.skuActive[type] = name
      // for(let i in items) {
      //   this.$set(items[i], 'active', false)
      // }

      let currProductItemID
      for(let j in this.productItemSkuList){
        let isDiff = false
        for(let k in this.skuActive){
          if (!(k in this.productItemSkuList[j]) || this.skuActive[k] != this.productItemSkuList[j][k]) {
            isDiff = true
            continue
          }
        }
        if (!isDiff) {
          currProductItemID = this.productItemSkuList[j].itemId
          break
        }
      }


      if(currProductItemID != this.productItemId){
        this.$Toast.fail("No discounts on other goods !")
      }

      // if(currProductItemID != undefined){
      //   for(let p in this.productItemSkuAllList){
          
      //     if (this.productItemSkuAllList[p].id == currProductItemID) {
      //       this.productItemInfo = this.productItemSkuAllList[p]
      //     }
      //   }
      // }
      // this.productItemId = currProductItemID

    },

    close(){
      this.topShow = false
    },

    gotoShopping(productItemId){
      if (!productItemId) {
        productItemId = this.productItemId
      }

      let params = '?jumptype=productitem&param1=' + productItemId
      if ('ios' == this.parseDeviceType()) {
        params = '?jumptype=PRODUCTITEM&param1=' + productItemId
      }

      this.jumpToAppOrStore(params)
    }
  },
  created () {
  },
}
</script>

<style>
.van-nav-bar--fixed{
  z-index: 6 !important;
}
.bg{
  max-width: 540px; margin: 0 auto;
}
.openAppBox {
    position: fixed;
    /* position: absolute; */
    max-width: 540px;
    width: 100%;
    top: 0;
    /* left: 0; */
    z-index: 10;
    /* display: none; */
}
.openAppBox_content {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    height: 60px;
    background: rgba(242,242,242,0.7)!important;
    /* background: #F2F2F2; */
    filter: Alpha(opacity=70);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.openAppBox_content .openAppBox_logo {
    margin-left: 5%;
    max-width: 50%;
    height: auto;
    max-height: 80%;
}
.openAppBox_content .openAppBox_btn {
    width: 75px;
    height: 35px;
    font-size: 13px;
    line-height: 35px;
    border-radius: 15px;
    background-color: #F78E1E;
    color: #FFFFFF !important;
    text-decoration: none;
    text-align: center;
    margin-left: 50%;
}
.openAppBox_content .openAppBox_close {
    /* max-width: 20%; */
    margin-right: 2%;
    height: auto;
    width: 30px;
}
.h5-header{
  padding-top:50px;
  width:100%;
  /* margin-top: 60px; */
  /* max-height: 475px; */
}
.h5-content{
  width:100%;
  max-height: 550px;
}
.h5-bg-gray{
  width: 100%;
  height: 10px;
  background-color: #F5F5F5;
}
.buyfree-content-box{
  max-height:500px;
  width:100%;
  padding:0 10px 0 10px;
}
.buyfree-content-box-list{
  max-height:280px;
  height:280px;
  width:100%;
  padding-left:10px;
  padding-right:10px;
  /* margin-top: 10px; */
}
.buyfree-content-box-list-goods{
  height:270px;
  width:100%;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 15px;
}
.buyfree-content-box-goods{
  height:350px;
  width:100%;
  background-color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 15px;
}
.buyfree-middle-font{
  text-align: center;
  margin-top:25px;
  color: #ffffff;
  font-size:14px;
  height:30px;
  width:100%;
}
.buyfree-list{
  padding-top: 20px;
}
.buyfree-content-box-goods-top{
  height:150px;
  width:100%;
}
.buyfree-content-box-goods-center{
  height:100px;
  width:100%;
}
.buyfree-content-box-goods-bottom{
  height:100px;
  width:100%;
}


/**iphone5*/
@media (min-width: 320px) {
  .buyfree-content-box-goods-top-right-content{
    width: 150px;
  }
  .buyfree-content-box-goods-top-right {
    width: 180px;
  }
}

/**iphone6*/
@media (min-width: 375px) {
  .buyfree-content-box-goods-top-right-content{
    width: 200px;
  }
  .buyfree-content-box-goods-top-right {
    width: 220px;
  }
}
/**iphone6*/
/* @media (min-width: 375px){
  .buyfree-content-box-goods-top-right-content{
    width: 200px;
  }
  .buyfree-content-box-goods-top-right {
    width: 235px;
  }
} */


/**iphone6p*/
@media (min-width: 414px) {
  .buyfree-content-box-goods-top-right-content{
    width: 235px;
  }
  .buyfree-content-box-goods-top-right {
    width: 265px;
  }
}



/**pc*/
@media (max-width: 480px) {
  .component {
    background: #00ff00;
  }
}

/**pc*/
@media (max-width: 767px) {
  .component {
    background: #00ff00;
  }
}

/**pc*/
@media (min-width: 768px) and (max-width: 979px) {
  .component {
    width: 600px;
    background: #00ff00;
    font-size: 30px;
  }
}

/**pc*/
@media (max-width: 979px) {
  .component {
    background: #00fff0;
  }
}

.details__goodsSku__box__type{
  /* background: #fff;bottom: 0;width: 100%;padding: 10PX 3%;overflow: auto; */
  padding-top: 10px;
}
.details__goodsSku__box__type_h5{
  margin-bottom: 10PX;
  font-size: 14px;
  text-align: left;
  margin-left: 25px;
  color: rgb(139, 139, 131);
}

.details__goodsSku__box__type__color{
  padding: 0 25px 0 25px;
  text-align: initial;
}
.details__goodsSku__box__type__div{
  display: inline-flex;
  line-height: 35PX;border-radius: 5PX;
  /* width:85PX; */
  border:1PX solid #ddd;
  padding:0 5PX;margin-bottom:5PX;margin-right: 10PX;
  font-size: 13px;
}
.h5-footer{
  margin-left: 20px;
  margin-right: 20px;
  padding-top:30px;
  max-width: 100%;
  margin-bottom: 50px;
}
.h5-footer div{
  max-width: 100%;
}
.h5-footer > div img {
  max-width: 100%;
}
.h5-footer > div > img, .h5-footer > div > p > img {
  min-width: 100%;
}


.borderCur{
  border:1PX solid #ff0000!important;
}

.van-goods-action{
  width: 100%;
  max-width: 540px;
  left: initial !important;
  right: initial !important;
}

.van-swipe-item > img {
  height:100%;
  width: 100%;
}

</style>
