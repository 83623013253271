import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './zh-CN'
import enUS from './en-US'
import thTHA from './th-THA'
import { cookieGet } from '@/common/cookie'

Vue.use(VueI18n)
 
// 注册i18n实例并引入语言文件
export const messages = {
    // 'zh-CN': {
    //     '_lang': '中文',
    //     ...zhCN
    // },
    'en-US': {
        '_lang': 'English',
        ...enUS
    },
    'th-THA': {
        '_lang': 'ภาษาไทย',
        ...thTHA
    }
}

export default new VueI18n({
    locale: cookieGet('language') || 'en-US',
    messages
})