import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { cookieGet,cookieSet } from '@/common/cookie'
import about from '../views/h5/about.vue'
import blog from '../views/h5/blog.vue'
import contact from '../views/h5/contact.vue'
import categories from '../views/h5/categories.vue';
import policy from '../views/h5/policy.vue'
import terms from '../views/h5/terms.vue'
import home from '../views/h5/home.vue'
import details from '../views/h5/details.vue'
import flash from '../views/h5/flashSale.vue'
import singleDetails from '../views/h5/singleDetails.vue'
import login from '../views/h5/login.vue'
import smscode from '../views/h5/smscode.vue'
import order from '../views/h5/order.vue'
import addressList from '../views/h5/addressList.vue'
import addressEdit from '../views/h5/addressEdit.vue'
import paymentOrder from '../views/h5/paymentOrder.vue'
import landingPage from '../views/h5/landingPage.vue'
import orderList from '../views/h5/orderList.vue'
import myOrderDetails from '../views/h5/myOrderDetails.vue'
import checkPay from '../views/h5/checkPay.vue'
import paySuccess from '../views/h5/paySuccess.vue'
import payFail from '../views/h5/payFail.vue'
import discountGoods from '../views/h5/discountGoods.vue'
import personalCenter from '../views/h5/personalCenter.vue'
import commissionRecord from '../views/h5/commissionRecord.vue'
import myWallet from '../views/h5/myWallet.vue'
import withdraw from '../views/h5/withdraw.vue'
import withdrawalsRecord from '../views/h5/withdrawalsRecord.vue'
import payPassword from '../views/h5/payPassword.vue'
import jumpTest from '../views/h5/jumpTest.vue'
import merchantCatalog from '../views/h5/merchantCatalog.vue'
import homepage from '../views/h5/homepage.vue'
import allProduct from '../views/h5/allProduct.vue'
import catalogProduct from '../views/h5/catalogProduct.vue'
import becomePromoter from '../views/h5/becomePromoter.vue'
import promoter from '../views/h5/promoter.vue'
import cart from '../views/h5/cart.vue'
import commoditySharing from '../views/h5/commoditySharing.vue'
import receiveAward from '../views/h5/receiveAward.vue'
import voucher from '../views/h5/voucher.vue'
import pandoehome from '../views/h5/pandoehome.vue'
import searchgoods from '../views/h5/searchgoods.vue'
import searchproductList from '../views/h5/searchproductList.vue'
import coupon from '../views/h5/coupon.vue'
import distributionError from '../views/h5/distributionError.vue'
import distributionErrorApp from '../views/h5/distributionErrorApp.vue'
import distributionErrorHelp from '../views/h5/distributionErrorHelp.vue'
import distributionErrorCode from '../views/h5/distributionErrorCode.vue'
import download from '../views/h5/download.vue'
import rabbitDownload from '../views/h5/rabbitDownload.vue'
import Weight from '../views/h5/Weight.vue'
import Bodyfatpercentage from '../views/h5/Bodyfatpercentage.vue'
import Bodywaterpercentage from '../views/h5/Bodywaterpercentage.vue'
import Basalmetabolicrate from '../views/h5/Basalmetabolicrate.vue'
import Visceralfatlevel from '../views/h5/Visceralfatlevel.vue'
import Musclesmass from '../views/h5/Musclesmass.vue'
import Bonemineralcontent from '../views/h5/Bonemineralcontent.vue'
import Protein from '../views/h5/Protein.vue'
import BMI from '../views/h5/BMI.vue'
import Skeletalmuscle from '../views/h5/Skeletalmuscle.vue'
import BodyScore from '../views/h5/BodyScore.vue'
import BodyAge from '../views/h5/BodyAge.vue'
import myFans from '../views/h5/myFans.vue'
import liveSharing from '../views/h5/liveSharing.vue'
import qrcode from '../views/h5/qrcode.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: homepage
  },
  {
    path: '/about',
    name: 'about',
    component: about,
  },
  {
    path: '/blog',
    name: 'blog',
    component: blog,
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact,
  },
  {
    path: '/categories',
    name: 'categories',
    component: categories,
  },
  {
    path: '/policy',
    name: 'policy',
    component: policy,
  },
  {
    path: '/terms',
    name: 'terms',
    component: terms,
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/singleDetails',
    name: 'singleDetails',
    component: singleDetails
    // component: () =>
    // import(/* webpackChunkName: "detail" */ "../views/h5/singleDetails.vue")

  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/smscode',
    name: 'smscode',
    component: smscode
  },
  {
    path: '/order',
    name: 'order',
    component: order,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: addressList,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    component: addressEdit,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },{
    path: '/paymentOrder',
    name: 'paymentOrder',
    component: paymentOrder,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },{
    path: '/landingPage',
    name: 'landingPage',
    component: landingPage,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },{
    path: '/checkPay',
    name: 'checkPay',
    component: checkPay,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },{
    path: '/paySuccess',
    name: 'paySuccess',
    component: paySuccess,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/payFail',
    name: 'payFail',
    component: payFail,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: orderList,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/myOrderDetails',
    name: 'myOrderDetails',
    component: myOrderDetails,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/discountGoods',
    name: 'discountGoods',
    component: discountGoods,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/personalCenter',
    name: 'personalCenter',
    component: personalCenter,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/commissionRecord',
    name: 'commissionRecord',
    component: commissionRecord,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/myWallet',
    name: 'myWallet',
    component: myWallet,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: withdraw,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/withdrawalsRecord',
    name: 'withdrawalsRecord',
    component: withdrawalsRecord,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/payPassword',
    name: 'payPassword',
    component: payPassword,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/jumpTest',
    name: 'jumpTest',
    component: jumpTest,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/merchantCatalog',
    name: 'merchantCatalog',
    component: merchantCatalog,
  },
  {
    path: '/mi',
    name: 'home',
    component: home,
  },
  {
    path: '/allProduct',
    name: 'allProduct',
    component: allProduct,
  },
  {
    path: '/catalogProduct',
    name: 'catalogProduct',
    component: catalogProduct,
  },
  {
    path: '/becomePromoter',
    name: 'becomePromoter',
    component: becomePromoter,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/promoter',
    name: 'promoter',
    component: promoter,
    // meta: {
    //   requireAuth: true //设置权限
    // }
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart,
  },
  {
    path: '/commoditySharing',
    name: 'commoditySharing',
    component: commoditySharing,
  },
  {
    path: '/receiveAward',
    name: 'receiveAward',
    component: receiveAward,
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: voucher,
  },{
    path: '/pandoehome',
    name: 'pandoehome',
    component: pandoehome,
  },{
    path: '/searchgoods',
    name: 'searchgoods',
    component: searchgoods,
  },{
    path: '/searchproductList',
    name: 'searchproductList',
    component: searchproductList,
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: coupon,
  },
  {
    path: '/distributionError',
    name: 'distributionError',
    component: distributionError,
  },
    {
    path: '/distributionErrorApp',
    name: 'distributionErrorApp',
    component: distributionErrorApp,
  },
  {
    path: '/distributionErrorHelp',
    name: 'distributionErrorHelp',
    component: distributionErrorHelp,
  },
  {
    path: '/distributionErrorCode',
    name: 'distributionErrorCode',
    component: distributionErrorCode,
  },
  {
    path: '/download',
    name: 'download',
    component: download,
    // meta: {
    //   title: process.env.config.VUE_APP_PANDO_TITLE
    // }
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: qrcode,
  },
  {
    path: '/rabbitDownload',
    name: 'rabbitDownload',
    component: rabbitDownload,
  },
  {
    path: '/Weight',
    name: 'Weight',
    component: Weight,
  },{
    path: '/Bodyfatpercentage',
    name: 'Bodyfatpercentage',
    component: Bodyfatpercentage,
  },{
    path: '/Bodywaterpercentage',
    name: 'Bodywaterpercentage',
    component: Bodywaterpercentage,
  },{
    path: '/Basalmetabolicrate',
    name: 'Basalmetabolicrate',
    component: Basalmetabolicrate,
  },{
    path: '/Visceralfatlevel',
    name: 'Visceralfatlevel',
    component: Visceralfatlevel,
  },{
    path: '/Musclesmass',
    name: 'Musclesmass',
    component: Musclesmass,
  },{
    path: '/Bonemineralcontent',
    name: 'Bonemineralcontent',
    component: Bonemineralcontent,
  },{
    path: '/Protein',
    name: 'Protein',
    component: Protein,
  },{
    path: '/BMI',
    name: 'BMI',
    component: BMI,
  },{
    path: '/Skeletalmuscle',
    name: 'Skeletalmuscle',
    component: Skeletalmuscle,
  },{
    path: '/BodyScore',
    name: 'BodyScore',
    component: BodyScore,
  },{
    path: '/BodyAge',
    name: 'BodyAge',
    component: BodyAge,
  },{
    path: '/myFans',
    name: 'myFans',
    component: myFans,
  },{
    path: '/liveSharing',
    name: 'liveSharing',
    component: liveSharing,
  },{
    path: '/flash',
    name: 'flash',
    component: flash,
    props: true,
  }
]
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(m => m.meta.requireAuth)) {
//     // console.log(store.state.token)
//     // 对路由进行验证
//     if (store.state.token) { // 已经登陆
//       next() // 正常跳转
//     } else {
//       // 未登录则跳转到登陆界面
//       next({ path: '/login' })
//     }
//   } else {
//     next()
//   }
// })

// export default router

export function createRouter () {
  return router
}
