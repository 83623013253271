<template>
	<div>
		<!--<van-nav-bar fixed title="Help">
			<template #left>
				<van-icon name="arrow-left" size="18" color="#000000" @click.native="$router.go(-1)" />
			</template>
		</van-nav-bar>-->
		<div class="content">
			<p class="special">Connecting your device</p>
			<p>How to connect</p>
			<p style="text-indent: 2em;">Step 1: Add device</p>
			<p style="text-indent: 2em;">1. Connect your device to the power source and turn it on</p>
			<p style="text-indent: 2em;">2. Open the App, select "+" on the top right of the home page to add device. At the net-pairing page, select the type of product</p>
			<p style="text-indent: 2em;">3. Make sure the device is in net-pairing status: the Wi-Fi indicator light blinks quickly (twice a second)</p>
			<p>- Please refer to the operation instructions found above the "Confrm indicator light blinks rapidly" button on "how to make the indicator light blinks rapidly".
			</p>
			<p>- Please press and hold the power button for 5 seconds to reset the device if the product doesn't have a reset button;</p>
			<p>- For devices such as the light which do not have a switch, please connect it to the light holder and operate the switch 3 times (on-off-on-off-on), stop at a light on condition in which the light blinks quickly;</p>
			<p>- If the product has a reset button, please press the reset button directly.</p>
			<p>- It is recommended to check the operation manual in order to connect the device to the network quickly.</p>
			<p style="text-indent: 2em;">Step 2: Enter Wi-Fi Password</p>
			<p style="text-indent: 2em;">Select the Wi-Fi through which the device can connect to the Internet, and enter the Wi-Fi password. Then click "OK" to proceed to net- pairing. Only 2.4G Wi-Fi is supported at this moment, please check if the Wi-Fi network is 2.4G. </p>
			<p>Finally, wait a few seconds until it displays that network has successfully been paired.</p>
			<p>Troubleshooting for failure in connecting the network (failed to add device)</p>
			<p style="text-indent: 2em;">1. Make sure the device has been connected to the power source and turned on.</p>
			<p style="text-indent: 2em;">2. Make sure the device has been in net-pairing status.</p>
			<p style="text-indent: 2em;">3. Make sure that the device, the phone and the router are close to each other.</p>
			<p style="text-indent: 2em;">4. Make sure that the device, the phone and the router are close to each other.</p>
			<p style="text-indent: 2em;">5. Make sure the router password is correct.</p>
			<p style="text-indent: 2em;">6. Make sure the device has been added using 2. 4G WiFi band. Enable the Wi-Fi broadcast function and do not set it to hidden mode.</p>
			<p style="text-indent: 2em;">7. Make sure that the encryption method of the router's wireless setting is WPA2-PSK and the authentication type is AES, or both are set as automatic.11nonly is not allowed in wireless mode.</p>
			<p style="text-indent: 2em;">8. Make sure the Wi-Fi name does not contain any Chinese characters.</p>
			<p style="text-indent: 2em;">9. If the number of the router's connected devices reaches the limit, try to turn off some device's Wi-Fi functions and configure it again.</p>
			<p style="text-indent: 2em;">10. If the router has enabled the wireless MAC address filter, try to remove the device from the router's MAC filter list to ensure the device is not prohibited by the router to connect to the network</p>
			<p>What if the device is ofline</p>
			<p style="text-indent: 2em;">1. Please make sure the device has been powered on;</p>
			<p style="text-indent: 2em;">2. Please check if the network is stable. Checking method: put a mobile phone or an iPad next to the device and make sure they are connected to the same network, then try to open a web page;</p>
			<p style="text-indent: 2em;">3. Please make sure the home Wi-Fi network is working well, or whether the Wi-Fi name, password, etc have been modified. If so, reset the device and add it again. (How to remove the device: Open the App and enter the device control page, click ".. button on the top right hand corner, click on the bottom of the page "Remove device'")</p>
			<p style="text-indent: 2em;">4. Please try to reboot the router and wait 3 minutes to observe the status of the device;</p>
			<p style="text-indent: 2em;">5. If the network is in good condition and shows no sign of modifications after troubleshooting, please go to the device's control page and click the ..” button in the upper right hand corner to get into the "More" page. Then, click "Feedback" and enter the issue you encountered and provide your Router model, we will handle it ASAP.</p>
			<p class="special">App-related</p>
			<p>The app is not able to control the device remotely, the function not effective after control The app couldn't control the device suddenly. It might be the result of a bad network environment or device is ofline.</p>
			<p style="text-indent: 2em;">1. Please make sure the device has been powered on;</p>
			<p style="text-indent: 2em;">2. It is recommended that you keep the App open for about 3 minutes, and see whether the device is still online.</p>
			<p style="text-indent: 2em;">3. If it's still online, please place the phone next to the device and make sure they are connected to the same Wi-Fi. Try to open a web page to check for the network's status.</p>
			<p style="text-indent: 2em;">4. If device is online and network is in good condition, yet it still cannot control any device, please go to the control page of the device, click on the ".. button in the upper right hand corner, and then click on the "Send Feedback" to enter the issue you encountered, and provide your Router model, we will handle it ASAP.</p>
			<p>When the device is connected, it can be controlled at the same location, but cannot be remotely controlled by the phone when moved to another location or other network.</p>
			<p style="font-size: 14px;padding: 0 20px;">If the device encounters such a situation, it may be because the device and the mobile phone are connected to the same LAN, but are not connected to the Internet.<br />
				 - Please place the phone next to the device and connect it to the same Wi-Fi as the device. Try to open a web page to check if the Wi-Fi can connect to the Internet;<br />
				  - If you cannot access the network,it is recommended to restart the router.<br />
				   - If the device has been connected to the network and the above issue occurs, please go to the control page of the device, click on the .. button in the upper right hand corner, and then click on the 'Send Feedback" to enter the issue you encountered, and provide your Router model, we will handle it ASAP.</p>
			<p class="special">Device status cannot be synchronized with the status displayed on the App</p>
			<p>1. If the device is controlled by a physical switch, the device may not be synchronized at the same time.</p>
			<p>2. It is recommended that you open the App and the device status will be updated after operating.</p>
			<p class="special">The lights turns on suddenly when the power reconnects after powering off.</p>
			<p>This may happen on lights that have no switch button (not a smart switch and no scene linkage at home). If the device needs to connect to the network, it is necessary to ensure that the physical switch of the lamp is open, and then the lamp can be controlled with the App. However, when it is powered off, the lights and the App will lose connection, so the light turns into an ordinary device. When the power is reconnected, if the light's physical switch has been turned 'on' before, then the light will turn on automatically.</p>
			<p class="special">How to cancel the countdown setting?</p>
			<p>Please set the countdown timer at 0 hours and 0 minutes to cancel the countdown.</p>
			<p class="special">How do I switch between Celsius and Fahrenheit?</p>
			<p>Please go to the App - "Personal Settings", click on your profile picture to enter into the basic information page. At "Temperature unit", choose the unit you need. After changing, the scene and others will perform and show the temperature in the unit you chose.</p>
			<p>What if net-pairing in compatible mode fails to find SmarLife-xxxx?</p>
			<p>You may find the undefined-xxxx in the WiFi list, or other similar hotspots to connect.</p>
			<p class="special">What is a local scene?</p>
			<p>ZigBee Scene Switch uses special device connection methods to create local scenes that support offine execution, so only ZigBee devices under the same gateway can be added.</p>
			<p class="special">Third-party control</p>
			<p>How to check if a third party control is supported on my device?</p>
			<p>Please add the device into the App, and go to the Settings page. Select the ". button on the top right, you will see "Supported Third-partly Control", click the icon to find the quick guide for using third party control.</p>
			<p>What if the third party device is connected but unable to perform voice control?</p>
			<p>Please check the user manual of the third party control device app and see if this voice command is recognized accurately.</p>
		</div>
	</div>
</template>
<script>
export default {
  name: 'details',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  tdk() {
    return {
      title: 'Help',
      ssrHeadAddInfo: {
        oglocale: 'en_US',
        ogtype: 'website',
        ogtitle: 'Help',
      }
      
    };
  },
  mounted(){
  },
  data () {
    return {
    }
  },
  created () {
  },
}
</script>

<style scoped="scoped">
	.content {
		width: 95%;
		margin: 20px auto;
		font-size: 16px;
		color: #101010;
	}
	
	.content span {
		color: #F78E1E;
		cursor: pointe
	}
	
	.special {
		font-size: 17px;
		color: #000000 !important;
		font-weight: 600;
	}
</style>