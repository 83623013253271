<template>
  <div>
    <router-view />

    <van-tabbar route
      active-color="#F78E1E"
      inactive-color="#000" 
    >
      <van-tabbar-item replace :to="homePath" icon="home-o">
        {{$t('Index_home')}}
      </van-tabbar-item>
      <van-tabbar-item replace to="/categories" icon="apps-o">
        {{$t('Catagories')}}
        <!-- <template #icon="props">
          <img :src="props.active ? '../../static/images/Categories-b.png' : '../../static/images/Categories-a.png'" style="width:22px;height:22px;">
        </template> -->
      </van-tabbar-item>
      <van-tabbar-item replace to="/cart" icon="shopping-cart-o"  :badge="cartCount">
        {{$t('Root_Cart')}}
      </van-tabbar-item>
      <van-tabbar-item replace to="/orderList" icon="orders-o">
        {{$t('My_Orders')}}
      </van-tabbar-item>
      <van-tabbar-item replace to="/personalCenter" icon="user-o">
        {{$t('Profile')}}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>


<script>
import { shopcartService } from '@/common/api'
import { cookieGet,cookieSet,setBigCache,removeBigCache,getBigCache,cookieRemove,cookieGetAll } from '@/common/cookie'
export default {
	name: 'tabbar',
	computed: {
	},
	data () {
		return {
      homePath: '',
      cartCount: null
		}
  },
  props:{
    count: Number
  },
  watch:{
    count(val,oVal){
      if(val == 0){
        this.cartCount = null
        cookieSet("cartCount",null)
      }else{
        this.cartCount = val
        cookieSet("cartCount",val)
      }
    }
  },
	created () {
    if(this.$router.app._route.path == '/mi'){
      cookieSet('homePath', '/mi')
      this.homePath = cookieGet('homePath')
    }else if(this.$router.app._route.path == '/'){
      cookieSet('homePath', '/')
      this.homePath = cookieGet('homePath')
    }else{
      this.homePath = cookieGet('homePath')
    }
	},
	mounted(){
    let temp = cookieGet("cartCount")
    if(temp == 'null'){

      let tokenInfo = cookieGet("token")
      let userInfo = cookieGet("user")
      let cartTempId = cookieGet("cartTempId")
      if(tokenInfo && userInfo){
        shopcartService.shoppingcartQuery({}).then(res => {
          if(res.code == '0'){
            if(res.data.totalcount == 0){
              this.cartCount = null
            }else{
              this.cartCount = res.data.totalcount
            }
          }
        }).catch(e => {
      
        })
      }else{
        if(cartTempId){ //已经有临时购物车id
          shopcartService.shoppingcarttmpQuery({tmpid:cartTempId}).then(res => {
            if(res.code == '0'){
              if(res.data.totalcount == 0){
                this.cartCount = null
              }else{
                this.cartCount = res.data.totalcount
              }
            }
          }).catch(e => {
        
          })
        }else{ //还没有临时购物车id
          this.cartCount = null
        }
      }

      
    }else{
      this.cartCount = temp
    }
	},
	methods:{
	}
}
</script>

<style >


  .van-info{
    background-color:#F78E1E !important;
  }
</style>
<style scoped>
  .van-tabbar{
    max-width: 540px !important;
  }
  .van-tabbar--fixed{
    left: initial !important;
  }
</style>

