<template>
  <section style="background: #FFFFFF;min-height: 100vh;">
  	<van-nav-bar fixed @click-left="$router.go(-1)">
      <template #left>
		<img style="width:30px;height:30px" src="../../../static/images/left.png" />
      </template>
      <template #title>
        <van-search
          v-model="searchValue"
          shape="round"
          left-icon=""
          @search="onSearch"
          :placeholder="$t('public.Searchproducts')"
          :autofocus="true"
          style=""
        >
          <template #right-icon>
            <div class="van-action-searching" @click="onSearch">
              <van-icon name="search" />
            </div>
          </template>
        </van-search>
      </template>
   </van-nav-bar>
   <div class="sacrch-history">
   	<div class="sacrch-history-top">
   		<p>{{$t('public.Searchhistory')}}</p>
   		<van-icon name="delete" @click="resethistory()"/>
   	</div>
   	<div class="history-array-block" v-if="historyvalue.length>0">
   		<div v-for="(i,index) in historyvalue" :key="index" class="history-array" @click="onSearchcope(i)">
   			{{i}}
   		</div>
   	</div>
   	<div v-else style="text-align: center;font-size: 14px;margin-top: 20px;">{{$t('public.Nosearchrecord')}}</div>
   </div>
   <div class="sacrch-history">
   	<div class="sacrch-history-top">
   		<p>{{$t('public.Popularsearch')}}</p>
   	</div>
   	<div class="history-array-block" v-if="HotData.length>0">
   		<div v-for="(i,index) in HotData" :key="index" class="history-array" @click="onSearchcope(i)">
   			{{i}}
   		</div>
   	</div>
   	<!--<div v-else style="text-align: center;font-size: 14px;margin-top: 20px;">{{$t('Search_No_History')}}</div>-->
   </div>
  </section>
</template>

<script>
	import { cookieGet, cookieSet,cookieRemove } from '@/common/cookie'
	import { productService} from '@/common/api'
export default {
  layout: 'default',
  components: {},
  data() {
    return {
    	searchValue:'',
    	historyvalue:[],
    	HotData:[],
    	storageType:true,
    }
  },
  mounted() {
		if(cookieGet('searchValue')){
	      	this.historyvalue=cookieGet('searchValue').split(',')
	  }
  },
  methods: {
	resethistory(){
		cookieRemove('searchValue')
		this.historyvalue=[]
	},
	onSearch(val) {
      if(this.searchValue != ''){
      	if(this.historyvalue.length>0){
      		this.historyvalue.forEach(item=>{
      			if(this.searchValue==item){
      				this.storageType=false
      			}
      		})
      		if(this.storageType){
      			this.historyvalue.unshift(this.searchValue)
      			cookieSet('searchValue',this.historyvalue.join(','))
      		}
      	}else{
      		cookieSet('searchValue',this.searchValue)	
      	}
				fbq('track', 'Search', {search_string: this.searchValue})
      	this.$router.push({path: '/searchproductList',query: { keywords: this.searchValue }})
      }
    },
    onSearchcope(val){
    	this.searchValue=val	
    	this.onSearch()
    },
    searchkeywordsData(){
    	productService.productsearchkeywords()
    	.then(res => {
            if(res.code == 0){
							this.HotData=res.list
            }
       }).catch(err => {   
       })
    },
  },
  created(){
		this.searchValue='';
		this.searchkeywordsData()
  }
}
</script>
<style scoped>

.sacrch-history{
	padding: 0 20px;
	padding-top: 80px;
}
.sacrch-history-top{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.sacrch-history-top p{
	font-size: 15px;
}
.sacrch-history-top i{
	font-size: 18px;
}
.history-array{
	font-size: 16px;
	border: 1px solid #f1f1f1;
	padding: 10px;
	width: auto;
	margin-right: 10px;
	margin-bottom: 10px;
}
.history-array-block{
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
</style>
<style>
.van-nav-bar__title{
	min-width: 70% !important;
}
</style>