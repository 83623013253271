<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Order_Confirm_Order')"
      @click-left="$router.go(-1)">
      <template #left>
        <img style="width:30px;height:30px" src="../../../static/images/left.png" />
	  	</template>
    </van-nav-bar>
    <!-- 收货人地址没有默认的情况 -->
    <div @click="chooseAddress()" v-if="!preorderInfo.address" style="text-align:center;margin-top:10px;width:95%;height:100px;border:1px dashed #969799; border-radius:10px; margin:60px auto 10px auto;">
      <div style="width:100%;line-height:60px;font-size:50px;">
        +
      </div>
      <div style="width:100%;font-size:14px;line-height:30px;">
        {{$t('Order_Add_Address')}}
      </div>
    </div>

    <!-- 收货人地址有默认的情况 -->
    <div @click="chooseAddress()" v-if="preorderInfo.address" style="text-align:center;margin-top:10px;width:100; border-radius:10px; padding:10px; padding-top:50px">
      <div class="van-address-item">
        <div class="van-cell van-cell--borderless">
          <div class="van-cell__value van-cell__value--alone van-address-item__value">
          <div class="van-radio">
            <div class="" style="font-size: 22px;color:#F78E1E">
              <van-icon name="location" />
            </div>
            <span class="van-radio__label">
            <div class="van-address-item__name">
              {{preorderInfo.address.contactName}} {{preorderInfo.address.contactPhone}}
            </div>
            <div class="van-address-item__address">
              {{preorderInfo.address.province}} {{preorderInfo.address.city}} {{preorderInfo.address.street}}
              
            </div></span>
          </div>
          </div>
          <van-icon class="van-address-item__edit" name="arrow" />
        </div>
      </div>
    </div>
    
    <!--  预订单展示的信息 -->
    <div v-if="preorderInfo">

      <div style="padding:10px 10px;"  v-for ="(item,index) in preorderInfo.group" :key="index">

        <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom: 1px solid #dddddd;">
          <div style="width:30px;padding:7.5px 0 0 15px;float:left;display: inline;">
            <img style="width:100%;height:100%" :src="item.merchant.image" />
          </div>
          <div style="width:70%;padding-left:10px;line-height:50px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
            {{item.merchant.name}}
          </div>
        </div>

        <div v-for ="(product,pIndex) in item.products" :key="pIndex">
          <div style="height:15px;background-color: #ffffff;"></div>
          <van-card
            :num="product.count"
            currency="฿"
            :price="product.price | Calculat"
            :desc="product.itemhint"
            :title="product.itemname"
            :thumb="product.image"
          >
          </van-card>
        </div>

        <div style="height:10px;background-color: #ffffff;"></div>
        <div style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;"></div>
        
      </div>
      
      <!-- 使用vant cell替代 -->
      <!-- <div style="padding:10px 10px;" >
        <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;">
          <div style="line-height:50px;display:inline;padding-left:20px;">
            
          </div>
          <div style="line-height:50px;display:inline;padding-right:20px;float:right">
            共: <span  style="color: #F78E1E">฿{{preorderInfo.productprice}}.00</span>
          </div>
        </div>
      </div> -->


      <div style="padding:10px;">
        <van-cell style="border-radius: 10px;" >
          <!-- 使用 title 插槽来定义标题 -->
          <template slot="title">
            {{$t('Order_Total_goodNum_1')}} <span style="color: #F78E1E;font-size:16px;">{{preorderInfo.totalcount}}</span> {{$t('Order_Total_goodNum_2')}} 
          </template>
          <!-- 使用 default 插槽来定义内容 -->
          <template slot="default">
            <span style="color:#000">{{$t('Order_Total_TotalTitle')}}</span> <span  style="color: #F78E1E;font-size:16px;">฿{{preorderInfo.productprice | Calculat}}</span>
          </template>
        </van-cell>
      </div>

      <div style="padding:10px 10px;" >
        <van-field v-model="mergeForm.remark" :placeholder="$t('Order_inputMessage')" rows="1" autosize type="textarea" style="border-top-left-radius:10px;border-top-right-radius:10px;border-bottom-left-radius:10px;border-bottom-right-radius:10px;" />
      </div>

      <div style="padding:10px;">

        <div  style="height:10px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;">
        </div>

        <!--<van-field
          v-model="mergeForm.couponCode"
          center
          clearable
          :label="$t('Coupon_Code')"
          :placeholder="$t('Coupon_Code')"
        >
          <template #button>
            <van-button size="mini" icon="arrow" color="#F78E1E" @click="useCouponCode()" :disabled="mergeForm.couponCode == 'undefined' || mergeForm.couponCode == null || mergeForm.couponCode == '' ? true: false"></van-button>
          </template>
        </van-field>-->
				<van-cell-group>
				  <van-cell title="Coupon" is-link value="No Coupon" :clickable="false" />
				  <van-cell title="Coupon Code" :clickable="false">
            <template slot="default">
              <div class="fx-list">
                <div class="f-grow"></div>
                <span class="mx4e" v-if="preorderInfo.couponCodeModel" style="color:#F78E1E;">{{preorderInfo.couponCodeModel.couponCode}}</span>
                <van-icon name="plus" class="showCouponBtn" @click="showUseCouponCode = true" v-if="!showUseCouponCode"/>
                <van-icon name="minus" class="hideCouponBtn" @click="showUseCouponCode = false" v-else/>
              </div>
            </template>
          </van-cell>
				  <van-cell :clickable="false" v-if="showUseCouponCode">
            <template #title>
              <div class="fx-list">
                <input v-model="userInputCouponCode" class="couponInput">
                <!-- <van-field v-model="" class="p0" placeholder="Please enter code and submit" /> -->
                <!-- <div class="verifyCouponBtn" @click="confirmCouponCode">→</div> -->
                <van-button class="verifyCouponBtn" :loading="couponCodeLoading" type="info" @click="confirmCouponCode" text="→" />
              </div>
            </template>
          </van-cell>
				</van-cell-group>

				<van-cell-group @click="voucher()" v-if="preorderInfo.couponcount>0">
				  <van-cell :title="$t('Voucher')+':'" :value="preorderInfo.counponidprice" is-link/>
				</van-cell-group>
        <van-cell-group :border="false">
          <van-cell v-for ="(price,priceIndex) in preorderInfo.pricelist" :key="priceIndex">
            <!-- 使用 title 插槽来定义标题 -->
            <template slot="title">
              {{price.name}}:
            </template>
            <!-- 使用 default 插槽来定义内容 -->
            <template slot="default">
              <span  style="color: #F78E1E;font-size:16px;">฿{{price.amount | Calculat}}</span>
            </template>
          </van-cell>
        </van-cell-group>

         <div  style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;">
        </div>
      </div>

      <!-- 使用vant cell替代 -->
      <!-- <div style="padding:10px 10px;font-size:15px;" >
        <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom: 1px solid #dddddd;">
          <div style="line-height:50px;display:inline;padding-left:20px;">
            优惠券
          </div>
          <div style="line-height:50px;display:inline;padding-right:20px;float:right">
            无可用
          </div>
        </div>
        <div  style="height:10px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;">
        </div>

        <div style="height:50px;background-color: #ffffff;" v-for ="(price,priceIndex) in preorderInfo.pricelist" :key="priceIndex">
          <div style="line-height:50px;display:inline;padding-left:20px;">
            {{price.name}}:
          </div>
          <div style="line-height:50px;display:inline;padding-right:20px;float:right">
            <span  style="color: #F78E1E">฿{{price.amount}}.00</span>
          </div>
        </div>

        <div  style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;">
        </div>
      </div> -->
    </div>

    <div style="padding-bottom:50px;">
    </div>

    <van-submit-bar
      :price="preorderInfo.counponidprice?(preorderInfo.totalprice-preorderInfo.counponidprice)*100:preorderInfo.totalprice*100"
      currency="฿"
      :label="$t('Order_Total_TotalTitle')"
      :button-text="$t('Order_Submit')"
      @submit="submitOrder"
    />

  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { productService, preorderService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'

export default {
  name: 'order',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  computed: {
  },
  data () {
    return {
      preorderInfo:{

      },
      mergeForm:{
        remark:'',
        couponCode:'',
      },
      userInputCouponCode: '',
      couponCodeLoading: false,
      showUseCouponCode: false,
    }
  },
  created () {
    console.log(this.$router);
	console.log('mergeForm.couponCode',this.mergeForm.couponCode)
    //每次调用预订单修改接口的时候需要重新覆盖一次缓存!

    //从缓存取出预订单信息
    let orderInfo = getBigCache("preorderInfo")
    if(orderInfo){
      this.preorderInfo = JSON.parse(orderInfo)

//    //从缓存取出优惠码
//    if(this.preorderInfo.couponCodeModel && this.preorderInfo.couponCodeModel.enable == '1'){
//      this.mergeForm.couponCode = this.preorderInfo.couponCodeModel.couponCode
//    }
    }
    console.log(this.preorderInfo)
  },
  methods: {
  	voucher(){
  		this.$router.push({
  			path:'/voucher',
  			query:{
  				preorderid:this.preorderInfo.id
  			}
  		})
  	},
    //提交预订单,创建订单
    submitOrder(){
      const preorderInfo = this.preorderInfo;

      if(!this.preorderInfo.address){
        this.$Toast(this.$t('Order_Add_Addresses'))
        return
      }
      else if (!/^\d{9,11}$/.test(preorderInfo.address.contactPhone)) {
        this.$Toast(this.$t('AddAddressVC_invalidPhone'))
        return
      }

      this.preorderInfo = {
          ...this.preorderInfo,
          preorderid: this.preorderInfo.id,
          addressid: this.preorderInfo.address.id,
          remark: this.mergeForm.remark,
      }

      //创建订单
      preorderService.finish({
        addressid: preorderInfo.address.id,
        couponCode: preorderInfo.couponCodeModel && preorderInfo.couponCodeModel.couponCode,
        remark: this.mergeForm.remark,
        shiptype: this.preorderInfo.shiptype,
        paytype: preorderInfo.paytype,
        preorderid: preorderInfo.id
      }).then(res => {
        if(res.code == '0'){
          //订单id放缓存
          cookieSet('orderId', res.data.id)
          //剩下跳转到收银台
          if(cookieGet("orderId")!=='' && cookieGet("orderId")){
          	this.$router.push({path: '/paymentOrder'})
          }

          fbq('track', 'InitiateCheckout', {content_ids: [res.data.id]})
        }
      }).catch(e => {
    
      })
    },
    useCouponCode(){

      if(this.mergeForm.couponCode == ''){
        this.$Toast.fail("Please Enter The Coupon Code !")
        return ;
      }


      preorderService.checkCouponCode({preOrderId: this.preorderInfo.id, couponCode: this.mergeForm.couponCode}).then(res => {
        console.log("dhashdashdhjkashjkdhjkasjhkdahjshjdkaskjhjdhkasjhkd")
        if(res.code == '0'){
          let changeForm = {}
          if(this.preorderInfo.address){
            changeForm = {
              ...this.preorderInfo,
              preorderid: this.preorderInfo.id,
              addressid: this.preorderInfo.address.id,
              couponCode: this.mergeForm.couponCode
            }
          }else{
            changeForm = {
              ...this.preorderInfo,
              preorderid: this.preorderInfo.id,
              couponCode: this.mergeForm.couponCode
            }
          }
          
          preorderService.modify(changeForm).then(res => {
            if(res.code == '0'){
              removeBigCache("preorderInfo") //清除旧的预订单
              setBigCache("preorderInfo",JSON.stringify(res.data)) //新的预订单放缓存
              this.preorderInfo = res.data
            }
          }).catch(e => {
        
          })
        }else{
          return ;
          
        }
      }).catch(e => {
        this.mergeForm.couponCode = ''
        // let changeForm = {}
        // if(this.preorderInfo.address){
        //   changeForm = {
        //     ...this.preorderInfo,
        //     preorderid: this.preorderInfo.id,
        //     addressid: this.preorderInfo.address.id
        //   }
        // }else{
        //   changeForm = {
        //     ...this.preorderInfo,
        //     preorderid: this.preorderInfo.id
        //   }
        // }
        
        // preorderService.modify(changeForm).then(res => {
        //   if(res.code == '0'){
        //     removeBigCache("preorderInfo") //清除旧的预订单
        //     setBigCache("preorderInfo",JSON.stringify(res.data)) //新的预订单放缓存
        //     this.preorderInfo = res.data
        //   }
        // }).catch(e => {
      
        // })
      })

      
      console.log(this.preorderInfo)
    },
    chooseAddress(){
      this.$router.push({path: '/addressList'})
    },
    // 确认优惠码是否有效
    confirmCouponCode() {
      const preorderInfo = this.preorderInfo;

      if (!this.userInputCouponCode) {
        return;
      }

      this.couponCodeLoading = true;

      this.$axios.get('/v1/preorder/couponcode', {
        params: {
          couponCode: this.userInputCouponCode,
          preOrderId: preorderInfo.id,
        }
      }).then(res => {
        // 返回id有效,重写预订单
        if (res.data.id) {
          this.$axios.post('/v1/preorder/modify', {
            addressid: preorderInfo.address && preorderInfo.address.id,
            couponCode: res.data.code,
            remark: this.mergeForm.remark,
            shiptype: this.preorderInfo.shiptype,
            paytype: preorderInfo.paytype,
            preorderid: preorderInfo.id
          }).then(preOrderRes => {
            if(preOrderRes.code == '0'){
              removeBigCache("preorderInfo") //清除旧的预订单
              setBigCache("preorderInfo",JSON.stringify(preOrderRes.data)) //新的预订单放缓存
              this.$set(this, 'preorderInfo', preOrderRes.data);
            }
          })
        }
      }).catch((err) => {
        this.userInputCouponCode = '';
      }).finally(() => {
        this.couponCodeLoading = false;
      })
    }
  }  
}
</script>

<style>
/** 特殊 */
.van-field__control{
  width: 95% !important;
  padding-left:10px !important;
}
</style>

<style scoped>

.showCouponBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  background-color: #F78E1E;
  color: white;
  border-radius: 50%;
  float: right;
}
.hideCouponBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  color: rgb(150, 150, 150);
  border-radius: 50%;
  float: right;
}
.couponInput {
  outline: none;
  border: none;
  border-radius: 4px;
  padding-left: 10px;
  margin-right: 20px;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  flex-grow: 1;
  background-color: rgb(230, 230, 230);
}
.verifyCouponBtn {
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  width: 3em;
  background-color: #F78E1E;
  height: 2em;
  color: white;
  float: right;
  border-radius: 6px;
}

.van-field__control{
  width:90%;
  padding-left:10px;
}
.van-card{
  margin-top:0px !important;
  background-color: transparent !important;
  padding: 0;
}
.van-card__header{
  background-color: #FFFFFF !important;
}
.van-card__content{
  padding-right: 15px;
}
.van-card__thumb{
  padding-left: 12px;
}
.van-card__price-currency{
  font-size: 16px;
  color: #F78E1E;
}
.van-card__price-integer{
  color: #F78E1E;
}
.van-card__price{
  color: #F78E1E;
}
.van-card__num{
  font-size: 16px;
}
.van-submit-bar {
  max-width: 540px;
  left: initial !important;
}

.van-cell:not(:last-child)::after {
  border-bottom:0px
}
.van-submit-bar__button{
	background: #F78E1E !important;
}

::v-deep .van-submit-bar__price {
  color: #F78E1E;
}
</style>
