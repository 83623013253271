import { productService} from '@/common/api'

export default {
  data () {
    return {
      //枚举
      jumpTypeOption:{ 
        ALL_PRODUCT:1,
        CATALOG_PRODUCT:2,
        MERCHANT:3,
        PRODUCT:4,
      },
      jump:{
        jumpType: null,
        param1: '',
        param2: '',
        param3: ''
      }
    }
  },
  methods: {
    jumpHandle (jumpObj) {

      this.jump = jumpObj
      if(this.jump.jumpType == "ALL_PRODUCT"){
        this.$router.push({path: '/allProduct'})
      }else if(this.jump.jumpType == "CATALOG_PRODUCT"){
        this.$router.push({path: '/catalogProduct',query: { catalogId: this.jump.param1, catalogName: this.jump.param2 }})
      }else if(this.jump.jumpType == "MERCHANT"){
        this.$router.push({path: '/merchantCatalog',query: { merchantId: this.jump.param1 }})
      }else if(this.jump.jumpType == "PRODUCT"){

        //商品id取单品信息
        productService.getProductItemInfo({productitemid: this.jump.param1}).then(res => {
          if(res.code == 0){
            // console.log(res.data)
            this.$router.push({path: '/details',query: { productItemId: res.data.id }})
          }
        })
        
      }else if(this.jump.jumpType == "LIVE"){
        window.location.href = this.jumpToAppOrStore('?jumptype=LIVE')
      }
      else if(this.jump.jumpType === 'url') {
        location.assign(this.jump.param1);
      }
    },
    jumpCallback (res) {
      console.log("jumpcallback"+res)
    },
    getAppName() {
      let href = window.location.href.toLowerCase()
      return /(pando)/i.test(href) ? 'pando' : 'rabbit'
    },
    getAppNoticeUrl() { // ios/android
      let resource = {
        "pando.ios": process.env.config.VUE_PANDO_APP_NOTICE_URL,
        "pando.android": process.env.config.VUE_PANDO_APP_NOTICE_URL,
        "rabbit.ios": process.env.config.VUE_RABBIT_APP_NOTICE_URL,
        "rabbit.android": process.env.config.VUE_RABBIT_APP_NOTICE_URL
      }

      let deviceType = this.parseDeviceType()
      let appName = this.getAppName()
      console.log('getAppNoticeUrl', resource[appName + '.' + deviceType])
      return resource[appName + '.' + deviceType]
    },
    getAppDownloadUrl() {
      let resource = {
        "pando.ios": process.env.config.VUE_PANDO_APP_IOS_URL,
        "pando.mac": process.env.config.VUE_PANDO_APP_IOS_URL,
        "pando.android": process.env.config.VUE_PANDO_APP_ANDROID_URL,
        "pando.windows": process.env.config.VUE_PANDO_APP_ANDROID_URL,
        "rabbit.ios": process.env.config.VUE_RABBIT_APP_IOS_URL,
        "rabbit.mac": process.env.config.VUE_RABBIT_APP_IOS_URL,
        "rabbit.android": process.env.config.VUE_RABBIT_APP_ANDROID_URL,
        "rabbit.windows": process.env.config.VUE_RABBIT_APP_ANDROID_URL
      }

      let deviceType = this.parseDeviceType()
      let appName = this.getAppName()
      return resource[appName + '.' + deviceType]
    },
    parseDeviceType() {
      let ua = navigator.userAgent
      console.log('userAgent', ua)
      if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
        return 'ios'
      } else if (/(Android)/i.test(ua)) {
        return 'android'
      } else if (/(Mac)/i.test(ua)) {
        return 'mac'
      }
      return 'windows'
    },
    isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    },
    jumpToAppOrStore(noticeParam, noticeMode) { // noticeParam: '?jumpType=...'; noticeMode: true notice app|false: download|null both
      let self = this

      let ua = navigator.userAgent.toLowerCase()
      let isWeixinBrowser = /micromessenger/.test(ua)
      let isQQBrowser = !!ua.match(/ qq\//i)
      
      if (isWeixinBrowser || isQQBrowser) {
          //暂时不用
        // this.topShow = false
        this.wechatNoShow = true
      } else {
        let noticeUrl = noticeMode === false ? '' : self.getAppNoticeUrl()
        if (noticeUrl) {
          // window.location.href = noticeUrl + (noticeParam ? noticeParam : '');
        }
        let downloadUrl = noticeMode === true ? '' : self.getAppDownloadUrl()
        if (downloadUrl) {
          window.setTimeout(function() {
            window.location.href = downloadUrl
          }, 1000)
        }
      }
    }
  }
}