<template>
  <div style="position: relative;">
  	<van-nav-bar fixed
	      title="Rabbit-Live"
		  @click-left="$router.go(-1)">
	      <template #left>
			<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  	</template>
	    </van-nav-bar>
	<div style="position:fixed;top:45px;width: 100%;">
		<img :src="dataForm.coverBigImageUrl" style="width: 100%;"/>
	</div>
	<div style="height: 100vh;background: #000000;opacity: 0.4;">
	</div>
	<div style="color: #FFFFFF;position: fixed;top:70px;text-align: center;font-size: 16px;">{{dataForm.title}}
	</div>
	<div style="position: fixed;bottom: 0;height: 200px;background: #FFFFFF;width: 100%;">
		<div v-if="dataForm.status !='LIVING'" style="background: #F78E1E;padding: 15px 10px; font-size: 16px;color: #FFFFFF;">
			Start Time {{dataForm.startTime}}
		</div>
		<div @click="gotoLive()" v-if="dataForm.status !='LIVING'" style="width: 80%;background: #F78E1E;color: #FFFFFF;padding: 8px 0;border-radius: 50px;position: absolute; bottom: 10px;left: 10%;display: flex;align-items: center;justify-content: center;font-size: 16px;">
			<van-icon name="clock" size="28px" style="margin-right: 15px;" />Check It In Rabbit Now
		</div>
		<div @click="gotoLive()" v-else style="width: 80%;background: #F78E1E;color: #FFFFFF;padding: 8px 0;border-radius: 50px;position: absolute; bottom: 10px;left: 10%;display: flex;align-items: center;justify-content: center;font-size: 16px;">
			<van-icon name="play-circle" size="28px" style="margin-right: 15px;" />Play It In Rabbit Now
		</div>
	</div>
	<div v-if="wechatNoShow" style="width: 100%;height: 100%;background-color: rgba(0, 0, 0, 0.5);position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index:99999999999 !important">
      <div style="float:right;position: relative;top: 20px;right: 30px;">
        <img style="width:110px;height:110px;" src="../../../static/images/wechat_arrows.png" />
      </div>
      <div style="position: relative;top: 50px;color:#FFF;text-align:center;font-size:20px;font-weight:bold;width:100%;height:40px;clear: both;">
        Please select "Open in browser"
      </div>
    </div>
  </div>
</template>

<script>

import { liveManage } from '@/common/api'
import mixinJumpModule from '@/mixins/jump-module'

export default {
  name: 'live',
  mixins: [ mixinJumpModule ],
  components: {
  },
  computed: {
  },
  data () {
    return {
    	id:'',
    	dataForm:{},
    	schemesUrl: 'akuya://fanslink.vip/buyfree',
      	appstoreUrl: process.env.config.VUE_APP_IOS_URL,
        googlepalyUrl: process.env.config.VUE_APP_ADROID_URL,
        localDownUrl: process.env.config.VUE_APP_ADROID_URL + '?' + new Date(),
      	wechatNoShow:false
    }
  },
  created () {
  	this.id = this.$route.query.liveRoomId
  	this.livedetail()
  },
  methods: {
  	gotoLive(){
      let params = '?jumptype=OpenLiveRoom&param1=' + this.id + '&param2=' + this.dataForm.liveNo

      this.jumpToAppOrStore(params)
    },
  	livedetail(){
  		liveManage.livedetail({
  			id:this.id
  		})
  		.then(res => {
           this.dataForm = res.data
          }).catch(e => {
        
          })
  	},
  }
}
</script>
