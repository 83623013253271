<template>
	<div>
		<van-nav-bar :title="$t('personalCenter.Promoter')" @click-left="$router.back(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
		  	</template>
		</van-nav-bar>
		<div class="promoter_content">
			<img v-if="lang=='en-US'" src="../../../static/images/promoter_english.gif" class="promoter_content_img" />
			<img v-if="lang=='th-THA'" src="../../../static/images/promoter_tha.gif" class="promoter_content_img" />
			<div class="promoter_content_video">
				<img src="../../../static/images/promoter_imgtwo.jpg" width="291px"/>
				<!--<video height="100%" width="100%" controls preload name="media">
					<source src="https://vdept.bdstatic.com/687567566a4945357051564b4a515153/4a55546b6352536c/76cde4308499011f69236ad35953d7946cc6a98a63759d52e91cca07ab95e5ab1fc9c3e77b5cdc6518a245c361da7c1120b76bd362ce616fe73c42cfa4f13c6a.mp4?auth_key=1589280186-0-0-78a840335de7363704ed28b2f1d76250" type="video/mp4">
				</video>-->
			</div>
		</div>
		<div class="promoter_content_end">
			{{$t('personalCenter.BecomePromotertopbottomtip')}}
		</div>
	</div>
</template>

<script>
	import Tabbar from '@/components/Tabbar.vue'
	import { cookieGet, cookieSet, cookieRemove, getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
	import { orderService, ocdev_api, personalCenter } from '@/common/api'
	import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'
	export default {
		name: 'discountGoods',
		filters: {
			Calculat(amount) {
				return CalculatAmount(amount)
			}
		},
		components: {},
		computed: {},
		data() {
			return {
				lang:''
			}
		},
		methods: {
		},
		created() {
			this.lang = cookieGet("language")
		},
	}
</script>

<style scoped>
	.promoter_content {
		width: 100%;
		height: auto;
		margin-bottom: -4px;
		position: relative;
	}
	
	.promoter_content_img {
		width: 100%;
		height: auto;
	}
	
	.promoter_content_end {
		text-align: center;
		background: #F0C021;
		color: #FFFFFF;
		font-size: 14px;
		padding: 10px 0;
	}
	
	.promoter_content_video {
		width: 291px;
		height: 105px;
		position: absolute;
		left: 50%;
		top: 37%;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
	}
</style>