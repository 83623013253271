<template>
	<div class="flash anchor">
		<img :src="img" />
		<div class="show">
			<slot></slot>
		</div>
		<div class="fx-list countdown" :class="{ ismore: more }">
			<div class="text" :style="{ color: textColor, backgroundColor: textBk }">{{ hour.toString().padStart(2, '0') }}</div>
			<span class="split" :style="{ color: splitColor }">:</span>
			<div class="text" :style="{ color: textColor, backgroundColor: textBk }">{{ min.toString().padStart(2, '0') }}</div>
			<span class="split" :style="{ color: splitColor }">:</span>
			<div class="text" :style="{ color: textColor, backgroundColor: textBk }">{{ sec.toString().padStart(2, '0') }}</div>
		</div>
		<div class="more" v-if="more" :style="{ color: splitColor }" @click="toFlash">More ></div>
	</div>
</template>

<script>
	import dayjs from 'dayjs';
	import customParseFormat from 'dayjs/plugin/customParseFormat';
	import utc from 'dayjs/plugin/utc';
	import duration from 'dayjs/plugin/duration';
	dayjs.extend(customParseFormat);
	dayjs.extend(utc);
	dayjs.extend(duration);

	export default {
		props: {
			meta: {
				type: Object,
				require: true,
			},
			more: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				hour: 0,
				min: 0,
				sec: 0,
				time: this.meta.ui.activity.countdownTime,
				img: this.meta.ui.activity.backgroundPictureUrl,
				textColor: this.meta.ui.activity.countdownColor.textColor,
				textBk: this.meta.ui.activity.countdownColor.backgroundColor,
				splitColor: this.meta.ui.activity.countdownColor.frameColor,
				handle: null,
			};
		},
		mounted() {
			this.time2cd();
		},
		methods: {
			time2cd() {
				// 活动开始时间(TH)转UTC,由于时区固定直接硬转
				const UTC_Start = dayjs.utc(this.meta.ui.activity.startTime, 'YYYY-MM-DD HH-mm:ss').subtract(7, 'hour');
				// 当前时间转UTC
				const UTC_Current = dayjs.utc();
				// UTC时间相减
				const duration = dayjs.duration(UTC_Current.diff(UTC_Start));
				this.time -= duration.as('seconds');

				// 小于0秒直接结束
				if (this.time < 0) {
					return;
				}

				this.hour = Math.floor(this.time / 3600) ;
				this.time %= 3600;
				this.min = Math.floor(this.time / 60) ;
				this.time %= 60;
				this.sec = Math.floor(this.time);

				this.handle = setTimeout(this.timeoutFn, 1000);
			},
			timeoutFn() {
				this.handle = setTimeout(this.timeoutFn, 1000);
				if (this.sec === 0) {
					if (this.min === 0) {
						if (this.hour === 0) {
							clearTimeout(this.handle);
							return;
						}
						this.hour--;
						this.min = 60;
					}
					this.min--;
					this.sec = 60;
				}
				this.sec--;
			},
			toFlash() {
				this.$router.push({
					name: 'flash',
					params: {
						meta: this.meta,
					},
					props: true,
				});
			},
		},
	};
</script>

<style scoped>
	.flash {
		border-radius: 6px;
		overflow: hidden;
	}
	.show {
		position: absolute;
		border-radius: 6px;
		left: 12px;
		right: 12px;
		top: 26.5%;
		bottom: 30px;
	}
	.countdown {
		position: absolute;
		top: 6%;
		right: 2em;
		/* color: #F78E1E; */
	}
	.countdown.ismore {
		right: 4em;
	}
	.more {
		position: absolute;
		top: 6%;
		right: 1.2em;
		height: 1.6em;
		line-height: 1.6em;
		font-size: 12px;
	}

	.text {
		width: 1.2em;
		height: 1.2em;
		line-height: 1.2em;
		text-align: center;
		padding: 0.2em;
		border-radius: 6px;
		font-size: 12px;
	}
	.split {
		margin: 0 0.2em;
		font-size: 10px;
	}
	img {
		display: block;
		width: 100%;
		height: auto;
	}
</style>
