<template>
	<div v-if="!is404" style="background-color:white;overflow:hidden;min-height:100vh">
		<!-- 顶栏在app里面要隐藏 -->
		<van-nav-bar v-if="!isApp" fixed :title="blog.nameText"  @click-left="$router.go(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<!-- 上面navbar是absolute,为防止html的margin导致背景覆盖不完全,这里将一个没有margin的元素置于其前面作为保险 -->
		<div></div>
		<div class="h5Context" :style="{ marginTop: !isApp ? '46px' : 0 }" ref="h5Container"></div>
		<!-- 原·商品渲染模板,现已整合到h5中 -->
		<!-- <div class="p2 mt2">
			<template v-for="(item, index) in productInfoList">
				<div class="fx my6 productItem" :key="index" @click="toDetail(item.id)">
					<img :src="item.publishPictureUrl" style="flex-shrink:0;width:100px;height:100px;" />
					<div class="fy ml3">
						<span class="limited">{{ item.nameText }}</span>
						<span class="mt3">
							<span style="color:#F15E25;">฿{{ item.priceChannel }}</span>
							<del v-if="item.priceOnlineSrp > item.priceChannel" class="ml1" style="font-size:12px;color:#959595;">
								฿{{ item.priceOnlineSrp }}
							</del>
						</span>
					</div>
				</div>
			</template>
		</div> -->
	</div>
	<div v-else class="fx-box" style="height:100vh;">
		<!-- 顶栏在app里面要隐藏 -->
		<van-nav-bar v-if="!isApp" fixed :title="blog.nameText"  @click-left="$router.go(-1)">
			<template #left>
				<img style="width:30px;height:30px" src="../../../static/images/left.png" />
			</template>
		</van-nav-bar>
		<h3>Not Found</h3>
	</div>
</template>

<script>
	import { Dialog, Notify } from 'vant';
	export default {
		data() {
			return {
				blog: {},
				// 事件监听,由于是注册到全局的,为防止报错,这个事件只会在一个很短的周期内存在
				schemeCheckerSignal: null,
				is404: false,
				isIOS: false,
				isAndroid: false,
				// 常量,url基地址
				urlBase: process.env.VUE_APP_NODE_ENV === 'prod' ? 'https://h5.rabbitselection.com/blog' : 'https://h5-test.rabbitselection.com/blog',
				titleOrg: '',
			};
		},
		created() {
			this.init();
			this.titleOrg = document.title;
		},
		computed: {
			// h5Content() {
			// 	if (this.blog.h5Page) {
			// 		return this.blog.h5Page.content;
			// 	}
			// 	return '';
			// },
			productInfoList() {
				if (this.blog.productInfoList) {
					return this.blog.productInfoList;
				}
				return [];
			},
			isApp() {
				let isApp = false;

				if (this.$route.query.entry instanceof Array) {
					isApp = this.$route.query.entry.includes('app');
				} else {
					isApp = this.$route.query.entry === 'app';
				}

				isApp = isApp || this.$route.query.platform === 'app';

				return isApp;
			},
		},
		methods: {
			async init() {
				await this.$axios
					.get('/v1/blog/activity/' + this.$route.query.id)
					.then((res) => {
						this.blog = res.data;
						this.renderH5(this.blog.h5Page.content, this.$refs.h5Container);
					})
					.catch((e) => {
						this.is404 = true;
						console.log(e);
					});

				if (this.is404) {
					return;
				}

				// 加载完成时进行自检,若是移动端访问且用户没有拒绝过跳转,则显示跳转提示对话框
				const ua = window.navigator.userAgent.toLowerCase();
				const isAndroid = ua.indexOf('android') > -1 || ua.indexOf('adr') > -1; //android终端
				const isIOS = /mac os x/.test(ua); //ios终端

				if (this.isApp) {
					// 已经位于app里面,不要再进行跳转提示,同时设置title为APP
					this.changeTitle(this.blog.nameText);
					return;
				}

				this.isIOS = isIOS;
				this.isAndroid = isAndroid;

				// 为方便测试,暂时将这里关闭
				// 考虑到用户有可能在手机上正常浏览页面,如果每次进入blog都弹出询问框不太友好,这里设置为一次浏览只弹框一次
				// if (sessionStorage.getItem('EventFlag_OpenApp') === 't') {
				// 	return;
				// }

				sessionStorage.setItem('EventFlag_OpenApp', 't');
				const remoteURL = encodeURIComponent(`${this.urlBase}?id=${this.$route.query.id}&platform=app`);

				if (isIOS) {
					// ios由于有先行提示,可以直接assign链接
					// Dialog.alert({
					// 	title: 'Open app' + '.' + location.search,
					// 	message: 'Do you want to open the app and continue browsing?' + this.$route.query.entry + this.$route.query.platform,
					// 	confirmButtonText: 'Confirm',
					// }).then(() => {
					// this.registSchemeChecker();
					location.assign(`flrabbitselection://fanslink.vip?jumpType=url&param1=${remoteURL}`);
					console.log(`flrabbitselection://fanslink.vip?jumpType=url&param1=${remoteURL}`);
					// });
				}

				if (isAndroid) {
					// android是直接拉起,故需要先询问
					Dialog.confirm({
						title: 'Open app',
						message: 'Do you want to open the app and continue browsing?',
						confirmButtonText: 'Confirm',
						cancelButtonText: 'Cancel',
					})
						.then(() => {
							// 判断不太准确,暂时取消
							// this.registSchemeChecker();
							location.assign(`flrabbitselection://fanslink.vip?jumpType=url&param1=${remoteURL}`);
							console.log(`flrabbitselection://fanslink.vip?jumpType=url&param1=${remoteURL}`);
						})
						.catch(() => {});
				}
			},
			toDetail(productItemId) {
				if (this.isApp) {
					// Dialog.alert({
					// 	title: 'Open app',
					// 	message: `flrabbitselection://fanslink.vip?jumpType=PRODUCT&param1=${productItemId}`,
					// 	confirmButtonText: 'Confirm',
					// });
					location.assign(`flrabbitselection://fanslink.vip?jumpType=PRODUCT&param1=${productItemId}`);
				} else {
					this.$router.push({
						path: '/details',
						query: {
							productItemId,
						},
					});
				}
			},
			// 注册一个检查事件以检查用户是否安装了对应的app,如果检查未通过,就说明用户没装,要弹出提示进行说明
			registSchemeChecker() {
				const eventListener = (event) => {
					// 由于指定了once,这里不用清监听器
					clearTimeout(t);
				};
				const t = setTimeout(() => {
					// 2s之后唤起未成功,视为没有app(准确性暂时未知)
					document.removeEventListener('visibilitychange', eventListener);
					Dialog.alert({
						title: 'Not found',
						message: 'The app is not installed',
					});
				}, 2000);

				document.addEventListener('visibilitychange', eventListener, {
					once: true,
				});
			},
			// h5渲染,主要是将带商品信息的img替换为预设好的商品结构
			renderH5(htmlStr, rootEl) {
				// 检查可能存在的h5元素,由于接下来需要挂载,故存在的情况需移除之
				const existH5Container = document.getElementById('h5Container');
				if (existH5Container) {
					existH5Container.remove();
				}

				const renderTemplate = `
					<div class="fx p2 mt2 productItem" :key="index">
						<img src="{publishPictureUrl}" style="width:80px !important;height:80px !important;" />
						<div class="fy ml3 text-left">
							<span>{nameText}</span>
							<span class="mt3">
								<span style="color:#F15E25;">฿{priceChannel}</span>
								{solt_PriceOrigin}
							</span>
						</div>
					</div>`;
				const delPriceTemplate = `<del class="ml1" style="font-size:12px;color:#959595;">฿{priceOnlineSrp}</del>`;
				const container = document.createElement('div');
				container.id = 'h5Container';
				container.className = 'h5Context';

				container.innerHTML = htmlStr;

				const imgElements = container.getElementsByTagName('img');

				for (let i = 0; i < imgElements.length; i++) {
					const pre = imgElements[i].alt.substring(0, 4);

					if (pre === 'prd_') {
						const productId = imgElements[i].alt.substring(4);
						const renderData = this.productInfoList.find((v) => v.id === productId);

						if (renderData === undefined) {
							console.error('unexpected value: got undefined while find renderData');
							imgElements[i].remove();
							continue;
						}

						// 针对原本的if需求,这里将代码嵌入进renderData的新增字段中
						renderData.solt_PriceOrigin =
							renderData.priceOnlineSrp > renderData.priceChannel
								? delPriceTemplate.replace(/{([^{}]+)}/g, (match, $1) => {
										return renderData[$1];
								  })
								: '';
						const renderDom = renderTemplate.replace(/{([^{}]+)}/g, (match, $1) => {
							return renderData[$1];
						});

						const replaceNode = document.createElement('div');
						replaceNode.innerHTML = renderDom;
						// 节点需加入点击事件,否则没有点击效果
						replaceNode.onclick = function() {
							const id = renderData.id;
							this.toDetail(id);
						}.bind(this);
						imgElements[i].replaceWith(replaceNode);
					}
				}

				rootEl.append(container);
			},
			changeTitle(title) {
				try {
					document.title = title;

					let i = document.createElement('iframe');
					i.style.display = 'none';
					i.src = '/static/images/layoutfirst.png';
					i.onload = () => {
						setTimeout(() => {
							i.remove();
						}, 10);
					};
				} catch (e) {
					Notify('error: ' + e);
				}
				document.body.appendChild(i);
			},
		},
	};
</script>

<style lang="scss">
	.productItem {
		&:first-child {
			margin-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.limited {
		// max-width: calc(100vw - 100px);
		// overflow: hidden;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// line-clamp: 2;
		height: 48px;
		line-height: 1.5;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		-webkit-line-clamp: 2;
	}
	.h5Context {
		max-width: 100%;
		overflow: hidden;
		img {
			max-width: 100%;
			height: auto;
			display: block;
		}
		p {
			margin: 0;
		}
	}
</style>
