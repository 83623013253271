import Vue from 'vue'
import Vuex from 'vuex'
import { cookieGet } from '@/common/cookie'
import { productService,commission_api } from '@/common/api'
import axios from 'axios'
Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    state: {
      // 存储token
      token: cookieGet('token') ? cookieGet('token') : '',
      userInfo: cookieGet('userInfo') ? cookieGet('userInfo') : '',
      isShowH5: cookieGet('isShowH5'),
      topicsList: null,
      topicDetail: null
    },
    mutations: {
      SET_TOPICS_LIST: (state, { list }) => {
        state.topicsList = list;
      },
      SET_TOPIC_DETAIL: (state, { detail }) => {
        state.topicDetail = detail;
      }
    },
    actions: {
      FETCH_TOPICS_LIST: ({ commit, state }, { cookies }) => {
        return state.topicsList
          ? Promise.resolve()
          : fetchTopics({ cookies }).then(res =>
              commit("SET_TOPICS_LIST", { list: res.data.data })
            );
      },
      FETCH_TOPIC_DETAIL: ({ commit }, { id,code,cookies }) => {
      	if(code){
      		return axios({
						method: 'get',
						url: commission_api + '/v1/commission/shareCode/query',
						params: {
							code:code,
						}
					})
					.then(res => {
						res.data.data.code=code
						commit("SET_TOPIC_DETAIL", { detail: res.data.data })
					})
      	}
        return productService.getProductItemInfo({productitemid: id}).then(res =>
          commit("SET_TOPIC_DETAIL", { detail: res.data })
        );
      }
    },
    modules: {
    }
  })

}
