<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Root_Cart_Navi')"
    >
      <template #right>
        <div v-if="cartIsNull == false || cartIsNullLogin == false" class="mr2">
          <div v-if="isLogin == false" @click="changeDelete">
            {{naviRightText}}
          </div>
          <div v-if="isLogin == true" @click="changeDeleteLogin">
            {{naviRightText}}
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div v-if="isLogin == false" style="padding-bottom:50px;">
      <div v-if="cartIsNull == true" style="padding-top:150px;text-align:center">
        <!-- <div style="width: 57px; height: 59px; margin-right: 20px;border: 1px solid #E2E2E2;border-radius: 50%;">
          <img src="../../../static/images/md-person2.svg" style="width: 100%; height: 100%;border-radius: 50%;" />
        </div> -->
        <div style="font-size: 14px;">
          <span size="small" >{{$t('Cart_Empty')}}</span>
        </div>
        <div style="padding-top:10px;">
          <van-button  color="#F78E1E" round type="info" size="small" @click="goShop()">{{$t('Cart_Go')}}</van-button>
        </div>
      </div>

      <div v-if="cartIsNull == false" style="padding-top:50px;padding-bottom:50px;">
        <div style="padding:10px 10px;"  v-for ="(item,index) in tempShopCartInfo.group" :key="index">

          <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom: 1px solid #dddddd;">
            <div style="width:30px;padding:7.5px 0 0 15px;float:left;display: inline;">
              <img style="width:100%;height:100%" :src="item.merchant.image" />
            </div>
            <div style="width:70%;padding-left:10px;line-height:50px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
              {{item.merchant.name}}
            </div>
          </div>

          <div v-for ="(product,pIndex) in item.products" :key="pIndex">
            <div style="height:15px;background-color: #ffffff;"></div>
            <van-card
              style=""
              :desc="product.itemname"
              :title="product.name"
            >

              <template #thumb>
                <div style="float:left;height:88px">
                  <van-checkbox v-if="showComplete == true" @click="goodsCheck(product.shoppingcartitemid,product.chooseVue)" style="padding-top: 34px;"  v-model="product.chooseVue" checked-color="#F78E1E"></van-checkbox>
                  <van-checkbox v-if="showComplete == false" @click="goodsCheckDel(product.shoppingcartitemid,product.deleteVue)" style="padding-top: 34px;"  v-model="product.deleteVue" checked-color="#F78E1E"></van-checkbox>
                </div>
                <img @click="productDetail(product.id)" width="88px" style="float: right;" :src="product.image" />
              </template>
              <template #price>
                <div style="color:#F78E1E;font-size:14px;">
                  ฿{{product.price | Calculat}}
                </div>
              </template>
              <template #num>
                <div >
                  <van-stepper @plus="goodsPlus(product.shoppingcartitemid)" @minus="goodsMinus(product.shoppingcartitemid)" @overlimit="goodsLimit()" :max="product.total" v-model="product.count" disable-input />
                </div>
              </template>
            </van-card>

          </div>

          <div style="height:10px;background-color: #ffffff;"></div>
          <div style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;"></div>
          
        </div>
      </div>

      <div v-if="cartIsNull == false">

        <van-submit-bar v-if="showComplete == true" :price="tempShopCartInfo.chooseprice == 0 ? 0 : 100*tempShopCartInfo.chooseprice"  style="margin-bottom: 50px;"
          currency="฿"
          :label="$t('Order_Total_TotalTitle')"
          :button-text="tempShopCartInfo.choosecount == 0 ? $t('Cart_Settlement') : $t('Cart_Settlement') + '(' + tempShopCartInfo.choosecount + ')'"
          :disabled="tempShopCartInfo.choosecount == 0 ? true : false"
          @submit="submitOrder">
          <van-checkbox @click="goodsCheckAll()" checked-color="#F78E1E" v-model="allChecked">{{$t('Good_Select_All')}}</van-checkbox>
        </van-submit-bar>
      </div>
      
      <van-cell style="margin-bottom: 50px;max-width: 540px;
    left: initial !important;position: fixed;
    bottom: 0;z-index: 100;
    width: 100%;user-select: none;" v-if="showComplete == false" >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-checkbox @click="goodsDelCheckAll()" checked-color="#F78E1E" v-model="allDeleteChecked">{{$t('Good_Select_All')}}</van-checkbox>
        </template>
        <template #right-icon>
          <van-button plain hairline @click="deleteDialog()" type="primary" size="mini" color="#F78E1E">{{$t('Common_Delete')}}</van-button>
        </template>
      </van-cell>
    </div>

    <div v-if="isLogin == true" style="padding-bottom:50px;">
      <div v-if="cartIsNullLogin == true" style="padding-top:150px;text-align:center">
        <!-- <div style="width: 57px; height: 59px; margin-right: 20px;border: 1px solid #E2E2E2;border-radius: 50%;">
          <img src="../../../static/images/md-person2.svg" style="width: 100%; height: 100%;border-radius: 50%;" />
        </div> -->
        <div style="font-size: 14px;">
          <span size="small" >{{$t('Cart_Empty')}}</span>
        </div>
        <div style="padding-top:10px;">
          <van-button  color="#F78E1E" round type="info" size="small" @click="goShop()">{{$t('Cart_Go')}}</van-button>
        </div>
      </div>

      <div v-if="cartIsNullLogin == false" style="padding-top:50px;padding-bottom:50px;">
        <div style="padding:10px 10px;"  v-for ="(item,index) in tempShopCartInfoLogin.group" :key="index">

          <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom: 1px solid #dddddd;">
            <div style="width:30px;padding:7.5px 0 0 15px;float:left;display: inline;">
              <img style="width:100%;height:100%" :src="item.merchant.image" />
            </div>
            <div style="width:70%;padding-left:10px;line-height:50px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
              {{item.merchant.name}}
            </div>
          </div>

          <div v-for ="(product,pIndex) in item.products" :key="pIndex">
            <div style="height:15px;background-color: #ffffff;"></div>
            <van-card
              style=""
              :desc="product.itemname"
              :title="product.name"
            >

              <template #thumb>
                <div style="float:left;height:88px">
                  <van-checkbox v-if="showCompleteLogin == true" @click="goodsCheckLogin(product.shoppingcartitemid,product.chooseVue)" style="padding-top: 34px;"  v-model="product.chooseVue" checked-color="#F78E1E"></van-checkbox>
                  <van-checkbox v-if="showCompleteLogin == false" @click="goodsCheckDelLogin(product.shoppingcartitemid,product.deleteVue)" style="padding-top: 34px;"  v-model="product.deleteVue" checked-color="#F78E1E"></van-checkbox>
                </div>
                <img @click="productDetail(product.id)" width="88px" style="float: right;" :src="product.image" />
              </template>
              <template #price>
                <div style="color:#F78E1E;font-size:14px;">
                  ฿{{product.price | Calculat}}
                </div>
              </template>
              <template #num>
                <div >
                  <van-stepper @plus="goodsPlusLogin(product.shoppingcartitemid)" @minus="goodsMinusLogin(product.shoppingcartitemid)" @overlimit="goodsLimit()" :max="product.total" v-model="product.count" disable-input />
                </div>
              </template>
            </van-card>

          </div>

          <div style="height:10px;background-color: #ffffff;"></div>
          <div style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;"></div>
          
        </div>
      </div>

      <div v-if="cartIsNullLogin == false">
        <van-submit-bar v-if="showCompleteLogin == true" :price="tempShopCartInfoLogin.chooseprice == 0 ? 0 : 100*tempShopCartInfoLogin.chooseprice"  style="margin-bottom: 50px;"
          currency="฿"
          :label="$t('Order_Total_TotalTitle')"
          :button-text="tempShopCartInfoLogin.choosecount == 0 ? $t('Cart_Settlement') : $t('Cart_Settlement') + '(' + tempShopCartInfoLogin.choosecount + ')'"
          :disabled="tempShopCartInfoLogin.choosecount == 0 ? true : false"
          @submit="submitOrder">
          <van-checkbox @click="goodsCheckAllLogin()" checked-color="#F78E1E" v-model="allCheckedLogin">{{$t('Good_Select_All')}}</van-checkbox>
        </van-submit-bar>
      </div>
      
      
      <van-cell style="margin-bottom: 50px;max-width: 540px;
    left: initial !important;position: fixed;
    bottom: 0;z-index: 100;
    width: 100%;user-select: none;" v-if="showCompleteLogin == false" >
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <van-checkbox @click="goodsDelCheckAllLogin()" checked-color="#F78E1E" v-model="allDeleteCheckedLogin">{{$t('Good_Select_All')}}</van-checkbox>
        </template>
        <template #right-icon>
          <van-button plain hairline @click="deleteDialogLogin()" type="primary" size="mini" color="#F78E1E">{{$t('Common_Delete')}}</van-button>
        </template>
      </van-cell>

    </div>

    <Tabbar v-if="isLogin == false" :count="tempShopCartInfo.totalcount" />
    <Tabbar v-if="isLogin == true" :count="tempShopCartInfoLogin.totalcount" />
  </div>
</template>

<script>

import Tabbar from '@/components/Tabbar.vue'
import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService,shopcartService,preorderService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'

export default {
  name: 'cart',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
    Tabbar
  },
  computed: {
  },
  data () {
    return {
      allChecked: false,
      tempShopCartInfo: {
        chooseprice: 0,
        choosecount: 0
      },
      cartIsNull: true,
      isLogin: false,
      tempCartId:'',
      naviRightText:this.$t('Common_Delete'),
      showComplete: true,
      deleteList:[],
      deleteAllList: [],
      allDeleteChecked: false,

      allCheckedLogin: false,
      tempShopCartInfoLogin: {},
      cartIsNullLogin: true,
      showCompleteLogin: true,
      deleteListLogin:[],
      deleteAllListLogin: [],
      allDeleteCheckedLogin: false,

      chosenAddressId: '',
      originList:[],
      preorderInfo: {},
      loading: false,
      finished: false,
      page:0,
      size:10,
      maxPage: 1
    }
  },
  methods: {
    productDetail(productItemId){
      // console.log(productItemId)
			this.$router.push({path: '/details',query: { productItemId: productItemId }})
		},
    goodsCheckDel(id,type){
      if(type == true){
        this.deleteList.push(id)
      }else{
        this.deleteList = this.deleteList.filter(a => a != id)
      }

      if(this.deleteList.sort().toString() == this.deleteAllList.sort().toString()){
        this.allDeleteChecked = true
      }else{
        this.allDeleteChecked = false
      }
    },
    goodsCheckDelLogin(id,type){
      console.log(id)
      if(type == true){
        this.deleteListLogin.push(id)
      }else{
        this.deleteListLogin = this.deleteListLogin.filter(a => a != id)
      }

      if(this.deleteListLogin.sort().toString() == this.deleteAllListLogin.sort().toString()){
        this.allDeleteCheckedLogin = true
      }else{
        this.allDeleteCheckedLogin = false
      }
    },
    goodsDelCheckAll(){
      this.tempShopCartInfo.group.forEach( item => {
        item.products.forEach( p => {
          if(this.allDeleteChecked == true){
            this.$set(p, 'deleteVue', true)
            this.deleteList = this.deleteAllList
          }else{
            this.$set(p, 'deleteVue', false)
            this.deleteList = []
          }
        })
      })
    },
    goodsDelCheckAllLogin(){
      this.tempShopCartInfoLogin.group.forEach( item => {
        item.products.forEach( p => {
          if(this.allDeleteCheckedLogin == true){
            this.$set(p, 'deleteVue', true)
            this.deleteListLogin = this.deleteAllListLogin
          }else{
            this.$set(p, 'deleteVue', false)
            this.deleteListLogin = []
          }
        })
      })
    },
    deleteDialog(){
      this.$Dialog.confirm({
        message: this.$t('Good_Delete_Item'),
        confirmButtonText: this.$t('Common_Confirm'),
        confirmButtonColor: '#F78E1E',
        cancelButtonText: this.$t('Common_Cancel')
      })
      .then(() => {
        if(this.allDeleteChecked == true){
          shopcartService.shoppingcarttmpRemoveAll({tmpid: this.tempCartId}).then(res => {
            if(res.code == '0'){
              this.showComplete = true
              this.naviRightText = this.$t('Common_Delete')
              this.commonTempCartCode(res)
            }
          }).catch(e => {
        
          })
        }else{
          shopcartService.shoppingcarttmpRemoveList({tmpid: this.tempCartId,shoppingcartitemidlist:this.deleteList.sort().toString()}).then(res => {
            if(res.code == '0'){
              this.showComplete = true
              this.naviRightText = this.$t('Common_Delete')
              this.commonTempCartCode(res)
            }
          }).catch(e => {
        
          })
        }
      })
      .catch(() => {
        // on cancel
      });
    },
    deleteDialogLogin(){
      this.$Dialog.confirm({
        message: this.$t('Good_Delete_Item'),
        confirmButtonText: this.$t('Common_Confirm'),
        confirmButtonColor: '#F78E1E',
        cancelButtonText: this.$t('Common_Cancel')
      })
      .then(() => {
        if(this.allDeleteCheckedLogin == true){
          shopcartService.shoppingcartRemoveAll({}).then(res => {
            if(res.code == '0'){
              this.showCompleteLogin = true
              this.naviRightText = this.$t('Common_Delete')
              this.commonCartCode(res)
            }
          }).catch(e => {
        
          })
        }else{
          shopcartService.shoppingcartRemoveList({shoppingcartitemidlist:this.deleteListLogin.sort().toString()}).then(res => {
            if(res.code == '0'){
              this.showCompleteLogin = true
              this.naviRightText = this.$t('Common_Delete')
              this.commonCartCode(res)
            }
          }).catch(e => {
        
          })
        }
      })
      .catch(() => {
        // on cancel
      });
    },
    changeDelete(){
      if(this.naviRightText == this.$t('Common_Delete')){
        this.showComplete = false
        this.naviRightText = this.$t('Common_Complete')
      }else{
        this.showComplete = true
        this.naviRightText = this.$t('Common_Delete')
      }
    },
    changeDeleteLogin(){
      if(this.naviRightText == this.$t('Common_Delete')){
        this.showCompleteLogin = false
        this.naviRightText = this.$t('Common_Complete')
      }else{
        this.showCompleteLogin = true
        this.naviRightText = this.$t('Common_Delete')
      }
    },
    goodsLimit(){
      this.$Toast.fail(this.$t('Good_Maximum'));
    },
    goodsPlus(id){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcarttmpCountplus1({shoppingcartitemid:id,tmpid:this.tempCartId}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonTempCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsPlusLogin(id){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcartCountplus1({shoppingcartitemid:id}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsMinus(id){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcarttmpCountminus1({shoppingcartitemid:id,tmpid:this.tempCartId}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonTempCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsMinusLogin(id){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcartCountminus1({shoppingcartitemid:id}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsCheck(id,type){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcarttmpChoose({shoppingcartitemid:id,choose:type,tmpid:this.tempCartId}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonTempCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsCheckLogin(id,type){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
      shopcartService.shoppingcartChoose({shoppingcartitemid:id,choose:type}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsCheckAll(){
      const load = this.$Toast.loading({
        forbidClick: true,
      });
    
      shopcartService.shoppingcarttmpChooseAll({choose:this.allChecked,tmpid:this.tempCartId}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonTempCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    goodsCheckAllLogin(){
      const load = this.$Toast.loading({
        forbidClick: true,
      });

      shopcartService.shoppingcartChooseAll({choose:this.allCheckedLogin}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonCartCode(res)
        }
      }).catch(e => {
        load.clear()
      })
    },
    submitOrder(){
      if(this.isLogin){
        //从购物车创建预订单
        preorderService.create({}).then(preOrderRes => {
          if(preOrderRes.code == '0'){
            removeBigCache("preorderInfo") //清除旧的预订单
            setBigCache("preorderInfo",JSON.stringify(preOrderRes.data)) //新的预订单放缓存
            this.$router.push({path: '/order'})
          }
        }).catch(e => {
      
        })
      }else{
        this.$router.push({path: '/login', query:{ submitOrder: 'y'}})
      }
    },
    goShop(){
      this.$router.push({path: '/'})
    },
    
    commonTempCartCode(res){
      if(res.data.group.length > 0){
        res.data.group.forEach( item => {
          item.products.forEach( p => {
            if(p.choose == 'Y'){
              p['chooseVue'] = true
            }else if(p.choose == 'N'){
              p['chooseVue'] = false
            } 
          })
        })

        console.log(res.data)
        this.cartIsNull = false
      }else{
        this.cartIsNull = true
      }

      if(res.data.totalcount == res.data.choosecount){
        this.allChecked = true
      }else{
        this.allChecked = false
      }

      if(res.data.list.length > 0){
        this.deleteAllList = []
        res.data.list.forEach( item => {              
          this.deleteAllList.push(item.shoppingcartitemid)
        })
      }
      this.tempShopCartInfo = res.data
    },

    commonCartCode(res){
      if(res.data.group.length > 0){
        res.data.group.forEach( item => {
          item.products.forEach( p => {
            if(p.choose == 'Y'){
              p['chooseVue'] = true
            }else if(p.choose == 'N'){
              p['chooseVue'] = false
            } 
          })
        })

        console.log(res.data)
        this.cartIsNullLogin = false
      }else{
        this.cartIsNullLogin = true
      }

      if(res.data.totalcount == res.data.choosecount){
        this.allCheckedLogin = true
      }else{
        this.allCheckedLogin = false
      }

      if(res.data.list.length > 0){
        this.deleteAllListLogin = []
        res.data.list.forEach( item => {              
          this.deleteAllListLogin.push(item.shoppingcartitemid)
        })
      }
      this.tempShopCartInfoLogin = res.data
    }
  },

  
  created () {
    // cookieSet("cartTempId","tempid_1234567")
    let cartTempId = cookieGet("cartTempId")
    this.tempCartId = cartTempId
    let isLoginCheck = cookieGet('token')

    const load = this.$Toast.loading({
      forbidClick: true,
    });
    console.log(cartTempId)

    if(isLoginCheck){

      //已经登录
      this.isLogin = true
      shopcartService.shoppingcartQuery({}).then(res => {
        load.clear()
        if(res.code == '0'){
          this.commonCartCode(res)
        }
      }).catch(e => {
    
      })
    }else{
      this.isLogin = false
      //未登录
      if(cartTempId){
        //去查询
        shopcartService.shoppingcarttmpQuery({tmpid:cartTempId}).then(res => {
          load.clear()
          if(res.code == '0'){
            this.commonTempCartCode(res)
          }
        }).catch(e => {
      
        })
      } else{
        load.clear()
        this.cartIsNull = true
      }
    }
  },
}
</script>

<style scoped>

.van-field__control{
  width:90%;
  padding-left:10px;
}
.van-card{
  margin-top:0px !important;
  background-color: transparent !important;
  padding: 0;
}
.van-card__header{
  background-color: #FFFFFF !important;
}
.van-card__content{
  padding-right: 15px;
}
.van-card__thumb{
  padding-left: 12px;
}
.van-card__price-currency{
  font-size: 16px;
  color: #F78E1E;
}
.van-card__price-integer{
  color: #F78E1E;
}
.van-card__price{
  color: #F78E1E;
}
.van-card__num{
  font-size: 16px;
}
.van-submit-bar {
  max-width: 540px;
  left: initial !important;
}

.van-cell:not(:last-child)::after {
  border-bottom:0px
}
.van-submit-bar__button{
  width: 140px;
	background: #F78E1E !important;
}
.van-card__thumb {
  width: 120px;
}

::v-deep .van-submit-bar__price{
  color: #F78E1E;
}
</style>