<template>
  <div>
    <van-nav-bar fixed
      :title="$t('Order_Detail')"
      @click-left="$router.back(-1)">
      <template #left>
        <img style="width:30px;height:30px" src="../../../static/images/left.png" />
      </template>
    </van-nav-bar>
    
    <div v-if="orderInfo">
      <div style="padding-top:46px;height:70px;background:#F78E1E;">
        <div style="height:70px;line-height:70px;padding-left:30px;float:left;">
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'WP'"> {{$t('Mine_Order_Payment')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'WR'"> {{$t('Mine_Order_Shipping')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'FINISH'"> {{$t('Mine_Order_Complete')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'CANCEL'"> {{$t('Mine_Order_Cancel')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'REFUNDING'"> {{$t('Mine_Order_REFUNDING')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'REFUNDED'"> {{$t('Mine_Order_REFUNDED')}}</span>
        </div>
        <div style="height:70px;line-height:70px;padding-right:30px;float:right">

          <img src="../../../static/images/Order_Buy@3x.png" v-if="orderInfo.status == 'WP'" style="width:50px;padding-top:10px;" />
          <img src="../../../static/images/Order_Ship@3x.png" v-if="orderInfo.status == 'WR'" style="width:50px;padding-top:10px;" />
          <img src="../../../static/images/Order_Finish@3x.png" v-if="orderInfo.status == 'FINISH'" style="width:50px;padding-top:10px;" />
          <img src="../../../static/images/canceled@3x.png" v-if="orderInfo.status == 'CANCEL'" style="width:50px;padding-top:10px;" />
          <img src="../../../static/images/canceled@3x.png" v-if="orderInfo.status == 'REFUNDING'" style="width:50px;padding-top:10px;" />
          <img src="../../../static/images/canceled@3x.png" v-if="orderInfo.status == 'REFUNDED'" style="width:50px;padding-top:10px;" />

          <!-- <span v-if="orderInfo.status == 'WP'"> {{$t('Mine_Order_Payment')}}</span> -->
          <!-- <span style="color:#FFFFFF" v-if="orderInfo.status == 'WR'"> {{$t('Mine_Order_Shipping')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'FINISH'"> {{$t('Mine_Order_Complete')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'CANCEL'"> {{$t('Mine_Order_Cancel')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'REFUNDING'"> {{$t('Mine_Order_REFUNDING')}}</span>
          <span style="color:#FFFFFF" v-if="orderInfo.status == 'REFUNDED'"> {{$t('Mine_Order_REFUNDED')}}</span> -->
        </div>
      </div>

      <div v-if="orderInfo.address">
        <van-cell  size="large" >
          <!-- 使用 title 插槽来自定义标题 -->
          <template #title>
            <van-icon name="location-o" /> {{ orderInfo.address.contactName}}
          </template>
          <template #default>
            Tel: {{ orderInfo.address.contactMobile}}
          </template>
        </van-cell>

        <van-cell  size="large" style="font-size:13px;">
          {{ orderInfo.address.street }} ,{{ orderInfo.address.city }} ,{{ orderInfo.address.province }} 
        </van-cell>
      </div>

      <div style="padding-top:10px"  v-for ="(item,index) in orderInfo.group" :key="index">

        <div style="height:50px;background-color: #ffffff;border-top-left-radius:10px;border-top-right-radius:10px;border-bottom: 1px solid #dddddd;">
          <div style="width:30px;padding:7.5px 0 0 15px;float:left;display: inline;">
            <img style="width:100%;height:100%" :src="item.merchant.image" />
          </div>
          <div style="width:70%;padding-left:10px;line-height:50px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
            {{item.merchant.name}}
          </div>
        </div>

        <div v-for ="(product,pIndex) in item.products" :key="pIndex">
          <div style="height:15px;background-color: #ffffff;"></div>
          <van-card
            :num="product.count"
            currency="฿"
            :price="product.price | Calculat"
            :desc="product.productItemName"
            :title="product.product.name"
            :thumb="product.image"
          >
          </van-card>
        </div>

        <div style="height:10px;background-color: #ffffff;"></div>
        <div style="height:10px;background-color: #ffffff;border-bottom-left-radius:10px;border-bottom-right-radius:10px;"></div>
        
      </div>


      <van-cell style="margin-top:10px;">
        <template #default>
          <span style="float:right" > {{$t('Order_Total_Amount_1')}} <span style="color: #F78E1E">{{orderInfo.productCount}}</span> {{$t('Order_Total_Amount_2')}} <span style="color: #F78E1E">฿{{orderInfo.amount | Calculat}}</span></span>
        </template>
      </van-cell>

      <van-cell  style="font-size:13px;margin-top:10px;" >
        {{$t('Order_Number')}} {{orderInfo.traceNo}}
      </van-cell>

      <van-cell  style="font-size:13px;" >
        {{$t('Order_Time_Day')}} {{orderInfo.createTime}}
      </van-cell>

      <van-cell :title="$t('Order_Payment_Method')" >
        <template #default>
          <span v-if="orderInfo.payType == 'CASH'"> {{$t('Order_Pay_At_The_Store')}}</span>
          <span v-if="orderInfo.payType == 'ONLINE'"> {{$t('Order_Pay_Online')}}</span>
          <span v-if="orderInfo.payType == 'COD'"> {{$t('Order_Pay_Pay_After_GetGood')}}</span>
        </template>
      </van-cell>

      <van-cell :title="$t('Order_Ship_Method')" >
        <template #default>
          <span v-if="orderInfo.shipType == 'SHOP'"> {{$t('Order_Pickup_At_Store')}}</span>
          <span v-if="orderInfo.shipType == 'LOGISTICS'"> {{$t('Order_Fast_Delivery')}}</span>
        </template>
      </van-cell>

      <van-cell-group :border="false" style="margin-top:10px;">
        <van-cell v-for ="(price,priceIndex) in orderInfo.pricelist" :key="priceIndex">
          <!-- 使用 title 插槽来定义标题 -->
          <template slot="title">
            {{price.name}}:
          </template>
          <!-- 使用 default 插槽来定义内容 -->
          <template slot="default">
            <span  style="font-size:14px;">฿{{price.amount | Calculat}}</span>
          </template>
        </van-cell>

        <van-cell >
          <template #default>
            <span style="float:right" > {{$t('Order_Real_Payment')}} <span style="color: #F78E1E">฿{{orderInfo.amount | Calculat}}</span></span>
          </template>
        </van-cell> 
      </van-cell-group>
    </div>
  </div>
</template>

<script>

import { cookieGet,cookieSet,cookieRemove,getBigCache, setBigCache, removeBigCache } from '@/common/cookie'
import { orderService } from '@/common/api'
import { ToThousandsNoZero, CalculatAmount } from '@/common/calculation'

export default {
  name: 'myOrderDetails',
  filters: {
    Calculat(amount){
      return CalculatAmount(amount)
    }
  },
  components: {
  },
  computed: {
  },
  data () {
    return {
      preorderInfo:{},
      remark:'',
      orderId:'',
      orderInfo:{}
    }
  },
  created () {
  },
  mounted(){
    this.orderId = this.$route.query.orderId
    if(this.orderId){
      this.getDetail()
    }else{
      this.$Toast.fail("Order id is not empty!")
    }
  },
  methods: {
    getDetail(){
      //创建订单
      orderService.getDetail({orderid: this.orderId}).then(res => {
        if(res.code == '0'){
          console.log(res)
          this.orderInfo = res.data
        }
      }).catch(e => {
      })
    },
    chooseAddress(){
      this.$router.push({path: '/addressList'})
    }
  }  
}
</script>

<style scoped>
.van-card{
  margin-top:0px !important;
  background-color: transparent !important;
  padding: 0;
}
.van-card__header{
  background-color: #FFFFFF !important;
}
.van-card__content{
  padding-right: 15px;
}
.van-card__thumb{
  padding-left: 12px;
}
.van-card__price-currency{
  font-size: 16px;
  color: #F78E1E;
}
.van-card__price-integer{
  color: #F78E1E;
}
.van-card__price{
  color: #F78E1E;
}
.van-card__num{
  font-size: 16px;
}
.van-submit-bar {
  max-width: 540px;
  left: initial !important;
}

.van-cell:not(:last-child)::after {
  border-bottom:0px
}

.van-submit-bar__button{
  width: 140px;
}
.van-card__title{
  overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
</style>
