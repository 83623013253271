<template>
	<div v-if="mMeta" ref="root">
		<!-- FLASH_SALE_RECTANGLE -->
		<flash-wrapper :meta="mMeta">
			<horz-scroll-wrapper>
				<template #default="{wrapper}">
					<product-ui-flash class="f-noshrink" :products="mMeta.jump.productItemList" :wrapper="wrapper"></product-ui-flash>
				</template>
			</horz-scroll-wrapper>
		</flash-wrapper>
		<div class="mt2">
			<product-ui-line :product="item" v-for="item of catProductList" :key="item.id"></product-ui-line>
		</div>
	</div>
	<div class="fx-box" v-else>
		<van-loading type="spinner" />
	</div>
</template>

<script>
	import flashWrapper from '@/components/flashWrapper.vue';
	import horzScrollWrapper from '@/components/horzScrollWrapper.vue';
	import productUiFlash from '@/components/product-ui-flash';
	import productUiLine from '@/components/product-ui-line';

	export default {
		components: {
			horzScrollWrapper,
			flashWrapper,
			productUiFlash,
			productUiLine,
		},
		props: ['meta'],
		data() {
			return {
				mMeta: null,
				margin: null,
				catProductList: [],
			};
		},
		async beforeMount() {
			if (this.meta) {
				this.mMeta = this.meta;
			} else {
				await this.$axios.get('/v4/home/list').then((res) => {
					this.mMeta = res.list[2].data.find((v) => v.jump.jumpType === 'FLASH_SALE_CATALOG_PRODUCT_ITEM');
				});
			}

			const res = await this.$axios.get('/v1/catalog/queryproductitem', {
				params: {
					beginRow: 0,
					rowSize: 100,
					catalogId: this.mMeta.jump.param1,
				},
			});

			this.catProductList = res.list;
		},
	};
</script>

<style></style>
