const t = {}

t.loading = '加载中...'

t.brand = {}
t.brand.lg = 'Rabbit H5商城'
t.brand.mini = 'Rabbit H5商城'

t.add = '新增'
t.delete = '删除'
t.deleteBatch = '删除'
t.update = '修改'
t.query = '查询'
t.export = '导出'
t.handle = '操作'
t.confirm = '确定'
t.cancel = '取消'
t.logout = '退出'

/**共用**/
t.public={}
t.public.set = '设置'
t.public.edit = '编辑'
t.public.add = '添加'
t.public.delete = '删除'
t.public.update = '更新'
t.public.modify = '修改'
t.public.success = '成功'
t.public.fail = '失败'
t.public.error = '错误'
t.public.sort = '排序'
t.public.tips = '提示'
t.public.operation = '操作'
t.public.yes = '是'
t.public.no = '否'
t.public.submit = '提交'
t.public.reset = '重置'
t.public.confirm = '确认'
t.public.cancel = '取消'
t.public.back = '返回'
t.public.title = '标题'
t.public.content = '内容'
t.public.picture = '图片'
t.public.search = '搜索'
t.public.query = '查询'
t.public.output = '导出'
t.public.icon = '图标'
t.public.selectedIcon = '选中图标'
t.public.askDelete = '此操作将被永久删除。是否继续?'
t.public.chinese = '中文'
t.public.english = '英文'
t.public.khmer = '柬文'
t.public.createTime = '创建时间'
t.public.finishTime = '完成时间'
t.public.status = '状态'
t.public.valid = '有效'
t.public.invalid = '无效'
t.public.iSee = '我知道了'
t.public.close = '关闭'
t.public.remark = '备注'
t.public.value = '值'
t.public.baseInformation = '基本信息'
t.public.all = '所有'
t.public.name = '名称'
t.public.numberSort = '请输入数值越大越排前'
t.public.advert = '广告'
t.public.platform = '平台'
t.public.noData = '暂无数据'
t.public.operName = '操作人'
t.public.createDate = '创建时间'
t.public.updateDate = '更新时间'
t.public.price = '价格'
t.public.resetSearchKeyword = '重置搜索词库'
t.public.pleMobile = '请输入手机号'
t.public.pleCode = '请输入验证码'
/**支付页面**/
t.payOrder={}
t.payOrder.barName = '支付订单'
t.payOrder.payableBalance= '应付金额'
t.payOrder.balance= '余额'
t.payOrder.paymentMethod= '选择付款方式'
t.payOrder.creditCard='信用卡'
t.payOrder.creditDdbitCard='信用卡/借记卡'
t.payOrder.mobileMode='推荐手机银行（免费）'
t.payOrder.payNow='去支付'
/**landingPage**/
t.landingPage={}

export default t

